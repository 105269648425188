.bank-holiday__wrapper .title .title__inner {
  @apply flex items-center flex-col;
}

.bank-holiday__wrapper .title .title__icon {
  width: 3.9375rem;
  @apply m-0 mr-4 mb-4;
}

.theme-id .bank-holiday__wrapper .title .title__icon > svg {
  @apply fill-orange;
}

.theme-ed .bank-holiday__wrapper .title .title__icon > svg {
  @apply fill-green;
}

.bank-holiday__wrapper .title h2 {
  font-size: 1.5rem;
  line-height: 1.1;
  margin: 0;
  text-align: center;
}

@screen sm {
  .bank-holiday__wrapper .title h2 {
    font-size: 1.75rem;
    line-height: 1.625rem;
  }
}

@screen md {
  .bank-holiday__wrapper .title .title__inner {
    flex-direction: row;
  }

  .title .title__icon {
    @apply mb-0;
  }
}

@screen lg {
  .bank-holiday__wrapper .title h2 {
    text-align: left;
    font-size: 2.125rem;
    line-height: 3.5rem;
  }
}

.bank-holiday-table {
  @apply overflow-hidden rounded-5px mb-12;
}

/* TABLE HEADER */
.bank-holiday-table .table__header {
  @apply h-20 bg-blackBG text-center flex items-center justify-center text-12px font-normal text-white;
}

.bank-holiday-table .table__header .header__wrapper {
  @apply w-full px-15px mx-auto;
}

.bank-holiday-table .table__header .header__wrapper .header__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.bank-holiday-table .table__header .header__wrapper .header__inner .header__column {
  flex: 0 0 50%;
  max-width: 50%;
  @apply relative w-full px-15px;
}

.bank-holiday-table .table__header .header__wrapper .header__inner .header__column strong {
  @apply block text-14px text-white font-bold;
}

.bank-holiday-table .table__header .header__wrapper .header__inner .header__column strong {
  @apply text-18px;
}
/*END TABLE HEADER */

.bank-holiday-table .table__head {
  @apply h-20 bg-blackBG text-center flex items-center justify-center text-12px font-normal text-white;
}

.bank-holiday-table .table__head .head__wrapper {
  @apply w-full px-15px mx-auto;
}

.bank-holiday-table .table__head .head__wrapper .head__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.bank-holiday-table .table__head .head__wrapper .head__inner .head__column {
  flex: 0 0 33.3%;
  max-width: 33.3%;
  @apply relative w-full px-15px;
}

.bank-holiday-table .table__head .head__wrapper .head__inner .head__column strong {
  @apply block text-14px text-white font-bold;
}

.bank-holiday-table .table__body {
  @apply text-center bg-concrete py-10px;
}

.bank-holiday-table .table__body .body__wrapper {
  @apply w-full px-15px mx-auto;
}

.bank-holiday-table .table__body .body__wrapper .body__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main {
  @apply flex-full max-w-full relative w-full px-0;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table {
  border-spacing: 0;
  @apply w-full border-collapse;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr {
  @apply h-20;
}

.bank-holiday-table
  .table__body
  .body__wrapper
  .body__inner
  .body__main
  table
  tbody
  tr:not(:last-child) {
  border-bottom: 1px solid #d3d3d7;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
  @apply text-center text-10px w-1/5;
}

.bank-holiday-table
  .table__body
  .body__wrapper
  .body__inner
  .body__main
  table
  tbody
  tr
  td:not(:last-child) {
  border-right: 1px solid #d3d3d7;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td strong {
  @apply block font-extrabold text-10px;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
  @apply text-10px block leading-none mb-1;
}

@screen md {
  .bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
    @apply text-12px;
  }

  .bank-holiday-table
    .table__body
    .body__wrapper
    .body__inner
    .body__main
    table
    tbody
    tr
    td
    strong {
    @apply text-12px;
  }

  .bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
    @apply text-12px;
  }

  .bank-holiday-table .table__body .body__wrapper .body__inner .body__main {
    @apply px-4;
  }
}

.bank-holiday-table .table__head .head__wrapper .head__inner .head__column strong {
  @apply text-18px;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
  @apply text-14px;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td strong {
  @apply text-14px;
}

.bank-holiday-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
  @apply text-14px;
}
