.cta-btn {
  transition: all 0.2s linear;
  transition-property: background, background-color, color, border-color;
  border-radius: 20px;
  @apply inline-flex text-16px leading-5 h-10 items-center justify-center text-black-lighter border-none py-0 px-5;
}

.cta-btn--disabled {
  @apply pointer-events-none bg-grey-lightestx4 opacity-50;
}

.cta-btn--plain {
  @apply bg-transparent border-1 border-solid border-black text-black;
}

.cta-btn--plain--white {
  @apply bg-transparent border-1 border-solid border-white text-white;
}

.view-brochure__btn {
  @apply bg-grey-button text-black w-200px;
}

.cta-btn--small {
  @apply py-2 px-4 h-auto;
}

.cta-btn--id {
  @apply bg-orange inline-flex text-16px leading-5 h-40px items-center justify-center rounded-button text-black-lighter border-none py-0 px-20px transition-colors duration-200 ease-linear whitespace-no-wrap;
}

.cta-btn--id:hover {
  @apply bg-orange-dark;
}

.cta-btn--ed {
  @apply bg-green inline-flex text-16px leading-5 h-40px items-center justify-center rounded-button text-black-lighter border-none py-0 px-20px transition-colors duration-200 ease-linear whitespace-no-wrap;
}

.cta-btn--ed:hover {
  @apply bg-green-dark;
}

.cta-btn--transparent--id {
  @apply bg-transparent inline-flex text-16px leading-5 h-40px items-center justify-center rounded-button text-orange border-1 border-solid border-orange py-0 px-20px transition-colors duration-200 ease-linear whitespace-no-wrap;
}

.cta-btn--transparent--ed {
  @apply bg-transparent inline-flex text-16px leading-5 h-40px items-center justify-center rounded-button text-green border-1 border-solid border-green py-0 px-20px transition-colors duration-200 ease-linear whitespace-no-wrap;
}

.cta-btn--transparent--id:hover,
.cta-btn--transparent--ed:hover {
  @apply text-white border-white;
}

.cta-btn--transparent--option {
  @apply transition-colors duration-200 mr-10px bg-transparent border-1 border-solid border-black-lighter inline-flex text-16px leading-5 h-40px rounded-full items-center justify-center text-black-lighter px-20px whitespace-no-wrap;
}

.cta-btn--transparent--option--active {
  @apply transition-colors duration-200 mr-10px border-1 border-solid border-transparent text-white bg-black-lighter inline-flex text-16px leading-5 h-40px rounded-full items-center justify-center px-20px whitespace-no-wrap;
}

.cta-btn--transparent--option--disabled {
  @apply transition-colors duration-200 mr-10px pointer-events-none bg-grey-lightestx4 opacity-50 border-1 border-solid border-black-lighter inline-flex text-16px leading-5 h-40px rounded-full items-center justify-center text-black-lighter px-20px whitespace-no-wrap;
}

.cta-btn--transparent--option:hover {
  @apply text-white bg-black-lighter;
}

.theme-id .btn-bg__checked {
  background: #e4e4e4
    url('https://res.cloudinary.com/manutantraders/image/upload/v1632239026/Icons/Rebrand_tick_ID.svg')
    no-repeat 90% center;
}

.theme-ed .btn-bg__checked {
  background: #e4e4e4
    url('https://res.cloudinary.com/manutantraders/image/upload/v1632236361/Icons/Rebrand_tick_ED.svg')
    no-repeat 90% center;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .theme-id .cta-btn--plain:hover,
  .theme-id .cta-btn--plain--white:hover {
    background-color: transparent !important;
    border-color: #efa847;
    color: #efa847;
  }

  .theme-ed .cta-btn--plain:hover,
  .theme-ed .cta-btn--plain--white:hover {
    background-color: transparent !important;
    border-color: #c7d303;
    color: #c7d303;
  }
}
