.home-reasons {
  padding: 1.6875rem 0 1.9375rem;
}

.home-reasons .home-reasons__inner.nav-footer {
  @apply flex flex-col items-center;
}

.home-reasons .home-reasons__inner .home-reasons__head {
  margin-bottom: 1.625rem;
}

.home-reasons .home-reasons__inner .home-reasons__head a {
  @apply inline-block border-none font-normal no-underline bg-transparent text-black-lighter;
}

.home-reasons .home-reasons__inner .home-reasons__head a:hover {
  @apply underline;
}

.home-reasons .home-reasons__inner .home-reasons__head a h2 {
  flex: 1 1;
  line-height: 1.17;
  @apply m-0;
}

.home-reasons .home-reasons__inner .home-reasons__body .home-reasons__features {
  max-width: 42.5rem;
}

.home-reasons .home-reasons__inner .home-reasons__body .home-reasons__features .widgets-features {
  margin: 0 0 -2.375rem;
  @apply flex flex-wrap;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA {
  grid-template-columns: 1fr;
  display: -ms-grid;
  -ms-grid-row-align: center;
  display: grid;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA
  .widget__main {
  margin-bottom: 2.375rem;
  @apply py-0 px-0 flex flex-full max-w-full;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA
  .widget__main:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA
  .widget__main:nth-child(2) {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA
  .widget__main:nth-child(3) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features.cfQA
  .widget__main:nth-child(4) {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main {
  flex: 1 1;
  order: 4;
  margin-bottom: 2.375rem;
  @apply py-0 px-0 max-w-full flex-full;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner {
  @apply flex flex-wrap;
}

.theme-id
  .home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__image {
  background: rgba(239, 168, 71, 0.49);
  color: #e49426;
}

.theme-ed
  .home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__image {
  background: #e6ee8e;
  color: #a2ae32;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__image {
  flex: 0 0 2.5rem;
  border-radius: 50%;
  max-width: 2.5rem;
  margin: 0.5rem 1rem 0 0;
  padding: 0.3125rem 0.625rem;
  @apply flex items-center justify-center font-bold h-10 text-20px leading-snug;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__image
  img {
  @apply inline-block;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__content {
  flex: 1 1;
  letter-spacing: -0.01em;
  padding: 0 0.625rem 0 0;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__content
  a {
  @apply inline-block border-none font-normal no-underline bg-transparent text-black-lighter;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__content
  a:hover {
  @apply underline;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__content
  a
  p {
  margin-bottom: 0.125rem;
  font-size: 1.125rem;
}

.home-reasons
  .home-reasons__inner
  .home-reasons__body
  .home-reasons__features
  .widgets-features
  .widget__main
  .widget__inner
  .widget__content
  p {
  @apply text-16px leading-5;
}

@screen sm {
  .home-reasons .home-reasons__inner .home-reasons__head {
    @apply mb-5;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features
    .widget__main
    .widget__inner
    .widget__content {
    padding: 0;
  }
}

@screen md {
  .home-reasons {
    padding: 1.6875rem 0 4.125rem;
  }

  .home-reasons .home-reasons__inner.nav-footer {
    align-items: normal;
  }

  .home-reasons .home-reasons__inner .home-reasons__head {
    margin-bottom: 2.375rem;
  }

  .home-reasons .home-reasons__inner .home-reasons__body .home-reasons__features .widgets-features {
    margin: 0 -0.625rem -2.5rem;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA {
    grid-template-columns: 1fr 1fr;
    margin: 0 -0.625rem -2.5rem;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main {
    margin-bottom: 2.625rem;
    @apply py-0 px-2.5;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(3) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features
    .widget__main {
    margin-bottom: 2.625rem;
    @apply py-0 px-2.5 max-w-1/2 flex-1/2;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features
    .widget__main
    .widget__inner
    .widget__image {
    margin: 0 1.25rem 0 0;
    max-width: 4.0625rem;
    flex: 0 0 4.0625rem;
    height: 4.0625rem;
    font-size: 2rem;
    line-height: 1.3;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features
    .widget__main
    .widget__inner
    .widget__content {
    padding: 0.4375rem 0 0;
  }
}

@screen lg {
  .home-reasons {
    padding: 2.5rem 0 3.8125rem;
  }

  .home-reasons .home-reasons__inner .home-reasons__head a h2 {
    margin: 0 0.625rem 0 0;
  }
}

@screen xl {
  .home-reasons {
    padding: 2.5rem 0 7.5rem;
  }

  .home-reasons .home-reasons__inner .home-reasons__head {
    @apply mb-12;
  }

  .home-reasons .home-reasons__inner .home-reasons__head a h2 {
    flex: none;
  }

  .home-reasons .home-reasons__inner .home-reasons__body .home-reasons__features {
    max-width: 73.5rem;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features
    .widget__main {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .home-reasons
    .home-reasons__inner
    .home-reasons__body
    .home-reasons__features
    .widgets-features.cfQA
    .widget__main:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }
}

@screen xxl {
  .home-reasons .home-reasons__inner .home-reasons__body .home-reasons__features {
    max-width: 84.625rem;
  }
}

/* Reasons to Shop */
.reasons-to-shop__section {
  padding: 44px 0;
  background: #eeeeee;
}
.reasons-to-shop__section-heading {
  font-weight: 800;
  font-size: 40px;
  line-height: 47px;
  color: #242424;
  margin-bottom: 40px;
}
@media only screen and (max-width: 768px) {
  .reasons-to-shop__section-heading {
    font-size: 30px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .reasons-to-shop__section-heading {
    margin-bottom: 24px;
  }
}
.reasons-to-shop__reasons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
  grid-gap: 24px;
}
@media only screen and (max-width: 768px) {
  .reasons-to-shop__reasons {
    grid-gap: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .reasons-to-shop__reasons {
    grid-gap: 32px;
  }
}
.reasons-to-shop__reason {
  background: #fff;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.reasons-to-shop__reason-img {
  margin-left: 0;
  width: 86px;
  height: 56px;
  margin-bottom: 24px;
  object-fit: contain;
  object-position: left;
}
.reasons-to-shop__reason-heading {
  font-weight: 900;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #242424;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1859px) {
  .reasons-to-shop__reason-heading {
    min-height: 52px;
  }
}
@media only screen and (max-width: 768px) {
  .reasons-to-shop__reason-heading {
    min-height: auto;
  }
}
@media only screen and (max-width: 725px) {
  .reasons-to-shop__reason-heading {
    min-height: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .reasons-to-shop__reason-heading {
    min-height: auto;
  }
}
.reasons-to-shop__reason-list {
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
  display: none;
}
.reasons-to-shop__reason-list li {
  display: flex;
  align-items: flex-start;
}
.reasons-to-shop__reason-list li:not(:last-child) {
  margin-bottom: 14px;
}
.reasons-to-shop__reason-list li svg {
  min-height: 10px;
  min-width: 13px;
  height: 10px;
  width: 13px;
  margin-right: 11px;
  margin-top: 9px;
}
.reasons-to-shop__reason-list li p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #242424;
}
.reasons-to-shop__reason-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #242424;
  margin-bottom: 24px;
  display: block;
}
.reasons-to-shop__reason-cta {
  font-size: 14px;
  margin-top: auto;
  align-self: flex-start;
  padding: 10px 45px;
}
@media only screen and (min-width: 1580px) {
  .reasons-to-shop__reason {
    padding: 24px 38px;
  }
}
@media only screen and (min-width: 768px) {
  .reasons-to-shop__reason-img {
    width: auto;
    height: auto;
    margin: 0 20px;
    margin-bottom: 24px;
    display: block;
  }
  .reasons-to-shop__reason-heading {
    font-size: 22px;
    line-height: 26px;
  }
  .reasons-to-shop__reason-list {
    display: block;
  }
  .reasons-to-shop__reason-text {
    display: none;
  }
  .reasons-to-shop__reason-cta {
    width: 100%;
  }
}
@media only screen and (min-width: 575px) {
  .reasons-to-shop__reason-text {
    margin-bottom: 16px;
  }
}
