.research__header {
  @apply w-full bg-black-light;
}

.research__header .header__wrapper {
  @apply w-full py-20px px-0;
}

.research__header .header__wrapper .header__main {
  @apply h-full block relative my-0 mx-auto;
}

.research__header .header__wrapper .header__main .header__content {
  @apply flex items-center justify-between flex-col h-full;
}

.research__header .header__wrapper .header__main .header__content p {
  @apply text-white text-24px font-bold mt-0 mb-15px mx-0;
}

.research__header .header__wrapper .header__main .header__content a {
  @apply block border-none bg-transparent no-underline font-bold;
}

.research__header .header__wrapper .header__main .header__content a:hover {
  @apply no-underline;
}

.research__header .header__wrapper .header__main .header__content a img {
  @apply block h-10 w-40;
}

.research__body {
  @apply w-full;
}

.research__body .body__wrapper {
  @apply h-full block relative my-0 mx-auto w-full;
}

.research__body .body__wrapper .articles li {
  width: calc(100% / 1);
  height: 270px;
  border: 10px solid #fff;
  @apply relative block my-10px mx-0 float-left;
}

.research__body .body__wrapper .articles--featured {
  @apply w-full relative h-auto;
}

.research__body .body__wrapper .articles--featured li:nth-child(1) {
  width: 100%;
  height: 300px;
  @apply relative top-0 left-0;
}

.research__body .body__wrapper .articles--featured li:nth-child(2) {
  height: 250px;
  @apply relative right-0 top-0 w-full;
}

.research__body .body__wrapper .articles--featured li:nth-child(3) {
  height: 250px;
  @apply relative right-0 top-1/2 w-full;
}

.research__body .body__wrapper .articles .article__piece--alt {
  @apply block w-full h-full absolute top-0 left-0 bg-no-repeat bg-center bg-cover;
}

.research__body .body__wrapper .articles .article__piece {
  @apply block w-full h-full absolute top-0 left-0 bg-no-repeat bg-center;
  background-size: 100% 100%;
}

.research__body .body__wrapper .articles .article__piece .article__content,
.research__body .body__wrapper .articles .article__piece--alt .article__content {
  opacity: 0.8;
  z-index: 2;
  @apply bg-black absolute bottom-0 left-0 w-full;
}

.research__body .body__wrapper .articles .article__piece .article__content .article__title,
.research__body .body__wrapper .articles .article__piece--alt .article__content .article__title {
  width: 95%;
  @apply text-20px m-10px uppercase text-white font-bold;
}

.research__body .body__wrapper .articles .article__piece .article__content .article__year,
.research__body .body__wrapper .articles .article__piece--alt .article__content .article__year {
  width: 95%;
  color: #ffc324;
  @apply text-16px mt-0 mx-10px mb-10px font-bold;
}

.research__body .body__wrapper .articles .article__piece .article__content .article__desc,
.research__body .body__wrapper .articles .article__piece--alt .article__content .article__desc {
  @apply text-14px leading-14px mt-0 mx-10px mb-0 text-white max-h-0 transition-all duration-300 ease-in overflow-hidden;
}

.research__body .body__wrapper .articles .article__piece:hover .article__content .article__desc,
.research__body
  .body__wrapper
  .articles
  .article__piece--alt:hover
  .article__content
  .article__desc {
  max-height: 150px;
  @apply mb-10px;
}

.research__footer {
  @apply w-full bg-blackBG clear-both h-14 overflow-hidden;
}

.research__footer .footer__wrapper {
  @apply h-full block relative my-0 mx-auto w-full;
}

.research__footer .footer__wrapper p {
  line-height: 55px;
  width: 90%;
  margin: 0 5%;
  @apply text-white text-center;
}

.research__footer .footer__wrapper p a {
  @apply underline;
}

@screen xs {
  .research__body .body__wrapper .articles li:nth-child(3n + 1) {
    @apply w-full;
  }

  .research__body .body__wrapper .articles li {
    width: calc(100% / 2);
  }

  .research__body .body__wrapper .articles--featured li:nth-child(2) {
    width: 50%;
  }

  .research__body .body__wrapper .articles--featured li:nth-child(3) {
    width: 50%;
  }
}

@screen md {
  .research__body .body__wrapper .articles li:nth-child(3n + 1) {
    width: calc(100% / 2);
  }

  .research__header .header__wrapper .header__main {
    @apply mx-45px;
  }

  .research__header .header__wrapper .header__main .header__content {
    height: 52px;
    @apply flex-row;
  }

  .research__header .header__wrapper .header__main .header__content p {
    @apply my-0 mx-0;
  }

  .research__body .body__wrapper .articles--featured {
    height: 400px;
  }

  .research__body .body__wrapper .articles--featured li:nth-child(1) {
    width: 55%;
    height: 95%;
    @apply absolute top-0 left-0;
  }

  .research__body .body__wrapper .articles--featured li:nth-child(2) {
    width: 44%;
    height: 45.5%;
    @apply absolute right-0 top-0;
  }

  .research__body .body__wrapper .articles--featured li:nth-child(3) {
    width: 44%;
    height: 45%;
    @apply absolute right-0 top-1/2;
  }
}

@screen lg {
  .research__body .body__wrapper .articles li:nth-child(3n + 1) {
    width: calc(100% / 3);
  }

  .research__body .body__wrapper {
    width: 990px;
  }

  .research__header .header__wrapper .header__main {
    width: 990px;
    @apply mx-auto;
  }

  .research__body .body__wrapper .articles li {
    width: calc(100% / 3);
  }

  .research__body .body__wrapper .articles--featured li:nth-child(1) {
    width: 55%;
  }

  .research__footer .footer__wrapper {
    width: 990px;
  }
}
