.mood-backdrop {
  transition: opacity 0.15s linear;
  @apply fixed top-0 left-0 w-screen h-screen bg-black opacity-0 pointer-events-none;
}

.mood-backdrop.active {
  z-index: 1040;
  @apply opacity-50;
}

.mood-modal {
  transition: opacity 0.15s linear;
  outline: 0;
  z-index: 1050;
  @apply pr-15px overflow-x-hidden overflow-y-auto fixed top-0 left-0 w-full h-full;
}

.mood-modal .mood-modal__container {
  max-width: 1000px;
  -webkit-transform: none;
  margin: 0.5rem;
  pointer-events: none;
  display: -ms-flexbox;
  -ms-flex-align: center;
  min-height: calc(100% - 1rem);
  @apply flex items-center transform-none w-auto relative;
}

.mood-modal .mood-modal__container .modal-content {
  background: #141414;
  color: #fff;
  border: #141414;
  border-radius: 0;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  border-radius: 1rem;
  box-shadow: -0.0625rem 0 0.625rem 0 rgb(0 0 0 / 7%), 0.3125rem 1.25rem 2.5rem 0 rgb(0 0 0 / 4%);
  outline: 0;
  @apply text-white rounded-none relative flex flex-col w-full pointer-events-auto bg-clip-padding border-1 border-solid py-12 px-4;
}

.mood-modal .mood-modal__container .modal-content .content-header {
  display: -ms-flexbox;
  -ms-flex-align: start;
  -ms-flex-pack: justify;
  @apply py-0 flex items-start justify-between px-4;
}

.mood-modal .mood-modal__container .modal-content .content-header h5 {
  font-family: 'Syne', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.02rem;
  @apply mb-4 font-bold text-white uppercase text-20px;
}

.mood-modal .mood-modal__container .modal-content .content-header button {
  color: #5a5b75;
  text-shadow: none;
  top: 30px;
  right: 30px;
  @apply cursor-pointer bg-transparent border-0 text-22px leading-none font-normal opacity-50 absolute;
}

.mood-modal .mood-modal__container .modal-content .content-header button span {
  @apply leading-none text-5xl text-white;
}

.mood-modal .mood-modal__container .modal-content .content-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  @apply relative py-0 px-5;
}

.mood-modal .mood-modal__container .modal-content .content-body p {
  font-family: 'Proxima Nova W15', sans-serif;
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply text-16px text-white font-normal mb-5;
}

.mood-modal .mood-modal__container .modal-content .content-body p a {
  @apply underline;
}

.mood-navbar {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  z-index: 1030;
  @apply fixed top-0 left-0 w-full px-0 py-0 flex flex-wrap items-center justify-between;
}

.mood-navbar .mood-navbar__container {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  @apply flex flex-wrap items-center justify-between px-0 mt-6 relative w-full mx-auto;
}

.mood-navbar .mood-navbar__container .navbar-brand {
  line-height: inherit;
  @apply whitespace-no-wrap text-18px py-0 inline-block ml-4 mr-15px pr-0;
}

.mood-navbar .mood-navbar__container .navbar-story {
  -ms-flex-align: center;
  display: -ms-flexbox;
  @apply flex items-center ml-0;
}

.mood-navbar .mood-navbar__container .navbar-story .navbar-story__toggle {
  @apply cursor-pointer inline mr-6;
}

.mood-navbar .mood-navbar__container .navbar-story .navbar-story__toggle span {
  font-family: 'Syne', sans-serif;
  line-height: 1.8;
  @apply hidden text-white uppercase align-middle;
}

.mood-navbar .mood-navbar__container .navbar-story .navbar-story__toggle svg {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  @apply text-white fill-current text-24px align-middle inline font-black ml-4;
}

.mood-navbar .mood-navbar__container .navbar-links {
  right: 0;
  top: 75px;
  @apply absolute hidden;
}

.mood-navbar .mood-navbar__container .navbar-links a {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0;
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  fill: #ffffff;
  @apply block relative bg-transparent text-white rounded-full border-white border-1 border-solid text-center no-underline text-16px mr-2 mb-4;
}

.mood-navbar .mood-navbar__container .navbar-links a:hover {
  fill: #141414;
  @apply bg-white text-transparent border-transparent;
}

.mood-navbar .mood-navbar__container .navbar-links a.facebook::before {
  font-family: 'Syne', sans-serif;
  content: 'Share on Facebook';
  right: 55px;
  width: 200px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.2s linear;
  @apply opacity-0 text-right absolute text-white;
}

.mood-navbar .mood-navbar__container .navbar-links a.twitter::before {
  font-family: 'Syne', sans-serif;
  content: 'Share on Twitter';
  right: 55px;
  width: 200px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.2s linear;
  @apply opacity-0 text-right absolute text-white;
}

.mood-navbar .mood-navbar__container .navbar-links a.copy::before {
  font-family: 'Syne', sans-serif;
  content: 'Copy Link';
  right: 55px;
  width: 200px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.2s linear;
  @apply opacity-0 text-right absolute text-white;
}

.mood-navbar .mood-navbar__container .navbar-links a:hover:before {
  @apply opacity-100;
}

.mood-navbar .mood-navbar__container .navbar-links a svg {
  width: 2.75rem;
  height: 2.75rem;
  @apply p-10px;
}

.mood-intro {
  background-image: url('/moodlighting/img/header/electrical-direct-in-the-mood-lighting-header-mobile.jpg');
  @apply flex items-center bg-black-dark bg-cover bg-center overflow-hidden relative pt-12 pb-0 min-h-screen;
}

.mood-intro .mood-intro__container,
.mood-stats .mood-stats__container {
  max-width: 90%;
  @apply pt-6 pb-2 px-4 w-full mx-auto;
}

.mood-intro .mood-intro__container .intro-heading {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap -mx-4 items-center py-4 mb-0;
}

.mood-intro .mood-intro__container .intro-heading .heading-container {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply text-center mx-auto justify-center w-full relative px-15px;
}

.mood-intro .mood-intro__container .intro-heading .heading-container img {
  @apply mx-auto;
}

.mood-intro .mood-intro__container .intro-heading .heading-container span {
  @apply uppercase text-white text-26px leading-relaxed font-normal;
}

.mood-intro .mood-intro__container .intro-heading .heading-container h1 {
  letter-spacing: -0.02rem;
  font-size: 2.2rem;
  font-family: 'Syne', sans-serif;
  line-height: 1.2;
  @apply mb-4 font-semibold text-white;
}

.mood-intro .mood-intro__container p {
  font-size: 1.2rem;
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply font-normal mb-12 text-white;
}

.mood-intro .mood-intro__container p strong {
  font-size: 0.8rem;
  opacity: 0.5;
  line-height: 1.8;
  letter-spacing: -0.02rem;
  font-family: 'Syne', sans-serif;
  @apply uppercase text-white;
}

.mood-intro .mood-intro__container p strong svg {
  @apply mr-4;
}

.mood-intro .mood-intro__container .intro-scroll {
  right: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  @apply absolute;
}

.mood-intro .mood-intro__container .intro-scroll a {
  @apply no-underline;
}

.mood-intro .mood-intro__container .intro-scroll a svg {
  @apply text-white text-36px font-black;
}

.mood-intro .mood-intro__container .intro-feature {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 95%;
  @apply mx-auto mb-12 w-full hidden absolute;
}

.mood-intro .mood-intro__container .intro-feature .feature-title {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply text-left w-full px-15px relative;
}

.mood-intro .mood-intro__container .intro-feature .feature-items {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  @apply flex items-center w-full px-15px relative;
}

.mood-intro .mood-intro__container .intro-feature .feature-items .item-color {
  @apply rounded-full h-15px w-15px inline-block overflow-hidden mr-4;
}

.mood-intro .mood-intro__container .intro-feature .feature-items p {
  font-family: 'Syne', sans-serif;
  @apply text-16px;
}

.mood-intro .mood-intro__container .intro-feature .feature-items .item-color.GOT {
  background-color: #cdad7c;
}

.mood-intro .mood-intro__container .intro-feature .feature-items .item-color.pw {
  background-color: #6d758f;
}

.mood-intro .mood-intro__container .intro-feature .feature-items .item-color.ag {
  background-color: #d02d06;
}

.mood-intro .mood-intro__container .intro-feature .feature-items .item-color.days {
  background-color: #8b4fb6;
}

.mood-stats {
  background-color: #96c0da;
}

.mood-stats .mood-stats__container .mood-stats__wrapper {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap mx-0 justify-center pt-4 pb-0;
}

.mood-stats .mood-stats__container .mood-stats__wrapper .stats-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply px-0 text-center mb-4 w-full relative;
}

.mood-stats .mood-stats__container .mood-stats__wrapper .stats-item.launch {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply px-15px text-center mb-0 w-full relative;
}

.mood-stats .mood-stats__container .mood-stats__wrapper .stats-item.launch h4 {
  font-size: 1.2rem;
  letter-spacing: -0.02rem;
  line-height: 1.2;
  @apply uppercase font-semibold text-black;
}

.mood-stats .mood-stats__container .mood-stats__wrapper .stats-item h3 {
  font-size: calc(1.525rem + 3.3vw);
  letter-spacing: -0.02rem;
  font-family: 'Syne', sans-serif;
  @apply leading-none;
}

.mood-stats .mood-stats__container .mood-stats__wrapper .stats-item p {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply text-lg mb-0;
}

.mood-text {
  background-color: #141414;
  @apply overflow-hidden pt-4 relative;
}

.mood-text .blue-bubble {
  top: 150px;
  left: -600px;
  @apply absolute;
}

.mood-text .red-bubble {
  top: -150px;
  right: -600px;
  @apply absolute;
}

.mood-text .mood-text__container {
  max-width: 90%;
  @apply w-full mx-auto px-15px py-4;
}

.mood-text .mood-text__container .mood-text__wrapper {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap -mx-15px items-center py-4;
}

.mood-text .mood-text__container .mood-text__wrapper .mood-text__main {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply mx-auto w-full px-15px text-left relative;
}

.mood-text .mood-text__container .mood-text__wrapper .mood-text__main h2 {
  color: #989898;
  font-size: 1.5rem;
  letter-spacing: -0.05rem;
  font-family: 'Syne', sans-serif;
  @apply mb-6 uppercase leading-none relative font-semibold;
}

.mood-text .mood-text__container .mood-text__wrapper .mood-text__main p {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply text-16px mb-5 text-white;
}

.mood-text .mood-select {
  background-color: #1c1c1c;
  @apply w-full;
}

.mood-text .mood-select .mood-select__container {
  max-width: 90%;
  @apply pt-4 pb-4 w-full mx-auto px-15px;
}

.mood-text .mood-select .mood-select__container .mood-select__wrapper {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap -mx-15px items-center py-4;
}

.mood-text .mood-select .mood-select__container .mood-select__wrapper .select-desc__wrapper {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply text-center mx-auto justify-center relative w-full px-15px;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-desc__wrapper
  .desc__main {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply text-white mb-6 font-normal text-16px;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-desc__wrapper
  .desc__select {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  font-family: 'Syne', sans-serif;
  @apply text-white mb-0 font-bold uppercase text-16px;
}

.mood-text .mood-select .mood-select__container .mood-select__wrapper .select-scene__wrapper {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply justify-center mx-auto text-center relative w-full px-15px;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 1.5rem;
  text-align: center;
  white-space: wrap;
  z-index: 2;
  padding: 0;
  margin: 0;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn {
  height: 15px;
  width: 0.75rem;
  @apply inline-flex border-none bg-transparent cursor-pointer relative mt-0 ml-0 mr-0 mb-15px p-0;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(1) {
  background-color: #8b4fb6;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(2) {
  background-color: #a8966f;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(3) {
  background-color: #394b77;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(4) {
  background-color: #525c64;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(5) {
  background-color: #d02d06;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(6) {
  background-color: #81866e;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(7) {
  background-color: #946946;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(8) {
  background-color: #6fa9d5;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(9) {
  background-color: #858797;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(10) {
  background-color: #e6ac94;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(11) {
  background-color: #8d6f5a;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(12) {
  background-color: #8c6c45;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(13) {
  background-color: #908384;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(14) {
  background-color: #dc9a6e;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(15) {
  background-color: #c2a382;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(16) {
  background-color: #acac94;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(17) {
  background-color: #926c64;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(18) {
  background-color: #906872;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(19) {
  background-color: #cdad7c;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(20) {
  background-color: #96a0ab;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(21) {
  background-color: #bd9668;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(22) {
  background-color: #a7806c;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(23) {
  background-color: #84846c;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(24) {
  background-color: #ceadac;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(25) {
  background-color: #848d91;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(26) {
  background-color: #9f928c;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(27) {
  background-color: #56392d;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(28) {
  background-color: #a96431;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(29) {
  background-color: #6d758f;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(30) {
  background-color: #77592d;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(31) {
  background-color: #649c79;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(32) {
  background-color: #8c7298;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(33) {
  background-color: #73867c;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(34) {
  background-color: #dca76f;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(35) {
  background-color: #7f8e9c;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(36) {
  background-color: #96c0da;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(37) {
  background-color: #91998a;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(38) {
  background-color: #af915b;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(39) {
  background-color: #6f839d;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(40) {
  background-color: #78859a;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(41) {
  background-color: #a1898d;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(42) {
  background-color: #949484;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(43) {
  background-color: #382814;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(44) {
  background-color: #ac8f50;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(45) {
  background-color: #39354c;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(46) {
  background-color: #ce2a27;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(47) {
  background-color: #8c7c5b;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(48) {
  background-color: #9d5d50;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(49) {
  background-color: #417c7b;
}
.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn:nth-child(50) {
  background-color: #844f49;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .select-scene__navigation
  .scene-nav__btn.active::after {
  position: absolute;
  content: '';
  background-color: transparent !important;
  border: none;
  top: 25px;
  left: 0;
  height: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-bottom: 7px solid #fff !important;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  img.bed {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  mix-blend-mode: multiply;
  margin-top: 10px;
  min-width: 200%;
  width: 200%;
  @apply z-1 absolute overflow-hidden;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .carousel
  button {
  z-index: 2;
  top: 50%;
  display: -ms-inline-flexbox;
  -ms-flex-pack: center;
  width: 2.75rem;
  height: 2.75rem;
  transition: all 0.3s ease-in-out;
  line-height: 2.5rem;
  @apply text-center text-24px border-none bg-transparent text-white p-0 m-0 opacity-50 inline-flex justify-center absolute font-extrabold;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .carousel
  button.prev-btn {
  left: 0;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .carousel
  button.next-btn {
  right: 0;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .carousel
  .carousel__slide {
  height: 100%;
  width: 200%;
  margin: auto;
  padding-top: 50px;
  min-width: 200%;
}

.mood-text
  .mood-select
  .mood-select__container
  .mood-select__wrapper
  .select-scene__wrapper
  .carousel
  .carousel__slide
  img {
  @apply px-0;
}

.mood-scene {
  background-color: #141414;
  @apply overflow-hidden pt-4 pb-4 relative;
}

.mood-scene .blue-bubble {
  top: -150px;
  right: -600px;
  @apply absolute;
}

.mood-scene .mood-scene__container {
  @apply w-full mx-auto pt-5 pb-12 px-15px;
}

.mood-scene .mood-scene__container .scene-header {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply py-4 flex flex-wrap -mx-15px;
}

.mood-scene .mood-scene__container .scene-header .header__wrapper {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply mx-auto justify-center text-center relative w-full px-15px;
}

.mood-scene .mood-scene__container .scene-header .header__wrapper h2 {
  font-size: 1.7rem;
  letter-spacing: -0.05rem;
  line-height: 1.1;
  font-family: 'Syne', sans-serif;
  @apply text-white uppercase relative mb-6;
}

.mood-scene .mood-scene__container .scene-main {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap mx-4;
}

.mood-scene .mood-scene__container .scene-main .scene__item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  background-color: #1c1c1c;
  min-height: 500px;
  @apply text-left relative w-full justify-center mr-0 ml-0 p-6;
}

.mood-scene .mood-scene__container .scene-main .scene__item.right {
  @apply mt-12;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-title__img {
  @apply float-left mr-4 mb-0;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-title__header {
  letter-spacing: -0.02rem;
  font-size: 1.625rem;
  margin-bottom: 0.75rem;
  line-height: 1.2;
  font-family: 'Syne', sans-serif;
  @apply uppercase text-white font-semibold;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-title__desc {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  @apply text-16px text-white mb-6;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-main__wrapper {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply mx-0 flex flex-wrap justify-center items-center;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-main__wrapper .scene__wrapper {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply text-left mx-auto relative pl-0 pr-0 justify-center w-full;
}

.mood-scene
  .mood-scene__container
  .scene-main
  .scene__item
  .item-main__wrapper
  .scene__wrapper
  img {
  @apply w-full;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-main__wrapper .scene__wrapper h4 {
  bottom: 10%;
  left: 10%;
  width: 70%;
  font-size: 0.8rem;
  letter-spacing: -0.02rem;
  font-family: 'Syne', sans-serif;
  @apply absolute text-white font-extrabold uppercase;
}

.mood-scene
  .mood-scene__container
  .scene-main
  .scene__item
  .item-main__wrapper
  .scene__wrapper
  h4
  span {
  font-family: 'Proxima Nova W15', sans-serif;
  @apply font-normal;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-others__wrapper {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap -mx-15px mt-6 items-center;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-others__wrapper .others__main {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply text-left mx-auto justify-center px-15px w-full relative;
}

.mood-scene .mood-scene__container .scene-main .scene__item .item-others__wrapper .others__main p {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  font-family: 'Syne', sans-serif;
  @apply uppercase mb-0 text-white text-16px font-semibold opacity-50;
}

.mood-prefooter {
  background-color: #141414;
}

.mood-prefooter .mood-prefooter__container {
  max-width: 90%;
  @apply w-full mx-auto px-6 py-12;
}

.mood-prefooter .mood-prefooter__container .prefooter__main {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  @apply flex flex-wrap -mx-15px justify-center;
}

.mood-prefooter .mood-prefooter__container .prefooter__main .prefooter__wrapper {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @apply mb-0 text-left relative w-full px-15px;
}

.mood-prefooter .mood-prefooter__container .prefooter__main .prefooter__wrapper p {
  letter-spacing: -0.02rem;
  line-height: 1.6;
  font-size: 1rem;
  @apply text-white mb-0 font-medium;
}

.mood-prefooter .mood-prefooter__container .prefooter__main .prefooter__wrapper p a {
  border-bottom: 1px solid #fff;
  transition: all 0.2s linear;
  @apply text-white font-medium cursor-pointer;
}

@screen sm {
  .mood-modal .mood-modal__container {
    min-height: calc(100% - 3.5rem);
    margin: 1.75rem auto;
  }

  .mood-modal .mood-modal__container .modal-content {
    box-shadow: -0.0625rem 0 0.625rem 0 rgb(0 0 0 / 7%), 0.3125rem 1.25rem 2.5rem 0 rgb(0 0 0 / 4%);
  }

  .mood-navbar .mood-navbar__container .navbar-story .navbar-story__toggle span {
    @apply inline;
  }

  .mood-intro {
    background-image: url('/moodlighting/img/header/electrical-direct-in-the-mood-lighting-header.jpg');
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container h1 {
    font-size: 3.2rem;
  }

  .mood-intro .mood-intro__container p {
    font-size: 1.4rem;
  }

  .mood-intro .mood-intro__container p strong {
    font-size: 1rem;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mood-scene .mood-scene__container .scene-header .header__wrapper h2 {
    font-size: 2rem;
  }

  .mood-scene .mood-scene__container .scene-main .scene__item .item-main__wrapper .scene__wrapper {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    @apply pr-4;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item.launch h4 {
    font-size: 1rem;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .scene__main
    .scene__main-inner {
    margin: 0px -30px 0px 0px;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .select-scene__navigation
    .scene-nav__btn {
    height: 35px;
    @apply mb-0;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .select-scene__navigation
    .scene-nav__btn.active::after {
    top: 50px;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    img.bed {
    min-width: 100%;
    width: 100%;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .carousel__slide {
    width: 100%;
    padding-top: 60px;
    min-width: 100%;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .carousel__slide
    img {
    @apply px-50px;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .select-scene__navigation {
    white-space: nowrap;
  }
}

@screen md {
  .mood-modal .mood-modal__container .modal-content {
    @apply py-20 px-12;
  }

  .mood-modal .mood-modal__container .modal-content .content-header h5 {
    @apply text-32px;
  }

  .mood-modal .mood-modal__container .modal-content .content-body p {
    @apply text-20px;
  }

  .mood-navbar {
    @apply px-6 py-6;
  }

  .mood-navbar .mood-navbar__container {
    @apply mt-0;
  }

  .mood-navbar .mood-navbar__container .navbar-story .navbar-story__toggle {
    @apply mr-0;
  }

  .mood-intro .mood-intro__container,
  .mood-stats .mood-stats__container {
    @apply px-24;
  }

  .mood-text .mood-text__container {
    @apply pt-6 pb-12;
  }

  .mood-text .mood-select .mood-select__container {
    @apply pt-6 pb-12;
  }

  .mood-intro .mood-intro__container .intro-heading {
    @apply mb-24;
  }

  .mood-intro .mood-intro__container .intro-scroll {
    right: 5%;
    left: auto;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .mood-intro .mood-intro__container .intro-feature {
    @apply flex flex-wrap;
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container h1 {
    font-size: 3rem;
    @apply mb-12;
  }

  .mood-intro .mood-intro__container p strong {
    font-size: 1.2rem;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    @apply mb-0;
  }

  .mood-text {
    @apply pt-20;
  }

  .mood-text .mood-text__container .mood-text__wrapper .mood-text__main {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mood-text .mood-text__container .mood-text__wrapper .mood-text__main h2 {
    font-size: 1.8rem;
  }

  .mood-text .mood-text__container .mood-text__wrapper .mood-text__main p {
    font-size: 1.1rem;
  }

  .mood-scene {
    @apply pt-20;
  }

  .mood-scene .mood-scene__container .scene-main {
    @apply mx-0;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item.launch h4 {
    font-size: 1.4rem;
  }

  .mood-prefooter .mood-prefooter__container {
    @apply px-12;
  }

  .mood-prefooter .mood-prefooter__container .prefooter__main .prefooter__wrapper p {
    font-size: 1.2rem;
  }

  .mood-text .mood-select .mood-select__container .mood-select__wrapper .select-desc__wrapper {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-desc__wrapper
    .desc__main {
    font-size: 1.1rem;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .scene__main
    .scene__main-inner {
    margin: 0px -80px 0px 0px;
  }
}

@screen lg {
  .mood-navbar {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    @apply justify-start;
  }

  .mood-navbar .mood-navbar__container .navbar-brand {
    @apply mr-6 pr-2;
  }

  .mood-navbar .mood-navbar__container .navbar-story {
    @apply ml-auto;
  }

  .mood-navbar .mood-navbar__container .navbar-links {
    @apply block;
  }

  .mood-intro {
    @apply pt-20;
  }

  .mood-intro .mood-intro__container {
    max-width: 780px;
    @apply py-4;
  }

  .mood-stats .mood-stats__container {
    max-width: 960px;
    @apply py-4;
  }

  .mood-text .mood-text__container,
  .mood-text .mood-select .mood-select__container {
    max-width: 960px;
  }

  .mood-text .mood-select .mood-select__container {
    @apply pt-12;
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper {
    @apply pb-6;
  }

  .mood-text {
    @apply pt-6;
  }

  .mood-scene {
    @apply pb-12;
  }

  .mood-scene .mood-scene__container {
    @apply pt-12 pb-12;
  }

  .mood-scene .mood-scene__container .scene-header .header__wrapper {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mood-prefooter .mood-prefooter__container {
    max-width: 960px;
  }

  .mood-prefooter .mood-prefooter__container .prefooter__main .prefooter__wrapper {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .carousel__slide {
    padding-top: 100px;
  }
}

@screen xl {
  .mood-navbar .mood-navbar__container {
    @apply px-4;
  }

  .mood-intro {
    @apply pb-12;
  }

  .mood-intro .mood-intro__container {
    max-width: 920px;
  }

  .mood-stats .mood-stats__container,
  .mood-text .mood-text__container,
  .mood-prefooter .mood-prefooter__container,
  .mood-text .mood-select .mood-select__container {
    max-width: 1100px;
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container h1 {
    font-size: 4.2rem;
  }

  .mood-intro .mood-intro__container p {
    font-size: 1.6rem;
  }

  .mood-intro .mood-intro__container .intro-feature {
    max-width: 75%;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item h3 {
    font-size: 4rem;
  }

  .mood-scene .mood-scene__container {
    @apply px-30px;
  }

  .mood-scene .mood-scene__container .scene-header .header__wrapper {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .mood-scene .mood-scene__container .scene-main .scene__item {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    @apply ml-auto mr-4 p-12;
  }

  .mood-scene .mood-scene__container .scene-main .scene__item.right {
    @apply mr-auto ml-4 mt-0;
  }

  .mood-stats .mood-stats__container .mood-stats__wrapper .stats-item.launch h4 {
    font-size: 1.8rem;
  }

  .mood-text
    .mood-select
    .mood-select__container
    .mood-select__wrapper
    .select-scene__wrapper
    .carousel
    .scene__main
    .scene__main-inner {
    margin: 0px -200px 0px 0px;
  }
}

@screen xxl {
  .mood-intro .mood-intro__container {
    max-width: 1420px;
  }

  .mood-stats .mood-stats__container,
  .mood-text .mood-text__container,
  .mood-prefooter .mood-prefooter__container,
  .mood-text .mood-select .mood-select__container {
    max-width: 1600px;
  }

  .mood-intro .mood-intro__container .intro-heading .heading-container {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
