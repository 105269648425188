.theme-id .mm_conv_banner {
  min-height: 597px;
  @apply my-20px flex justify-center items-center;
}

.theme-ed .mm_conv_banner {
  min-height: 630px;
  @apply my-20px flex justify-center items-center;
}

.theme-id .mm_conv_banner #mmContentReferrerStage1,
.theme-ed .mm_conv_banner #mmContentReferrerStage1 {
  max-width: 100%;
  height: 100%;
  @apply mx-auto;
}

.theme-ed #mmContentReferrerStage1 {
  max-width: 960px;
  @apply mx-auto;
}

/* .theme-id .mm_conv_banner .mmWrapping,
.theme-ed .mm_conv_banner .mmWrapping {
  height: 785px !important;
} */

@screen xs {
  .theme-id .mm_conv_banner #mmContentReferrerStage1,
  .theme-ed .mm_conv_banner #mmContentReferrerStage1 {
    max-width: 420px;
  }
}

/* @screen md {
  .theme-id .mm_conv_banner #mmContentReferrerStage1,
  .theme-ed .mm_conv_banner #mmContentReferrerStage1 {
    max-width: 680px;
  }
} */

@screen lg {
  .theme-id .mm_conv_banner #mmContentReferrerStage1,
  .theme-ed .mm_conv_banner #mmContentReferrerStage1 {
    max-width: 100%;
  }

  /* .theme-id .mm_conv_banner .mmWrapping,
  .theme-ed .mm_conv_banner .mmWrapping {
    height: 630px !important;
  } */
}
