.m--main-heading {
  letter-spacing: -0.64px;
  margin-bottom: 15px;
  @apply text-white text-24px font-bold;
}

@screen md {
  .m--main-heading {
    @apply text-30px;
  }
}

@screen lg {
  .m--main-heading {
    font-size: 43px;
  }
}

.m--main-heading--level-one-two {
  @apply text-black-lighter;
}

.m--main-summary {
  display: block;
  @apply text-14px text-white font-normal max-w-full;
}

.m--main-summary a {
  @apply underline;
}

.m--main-summary--level-one-two {
  @apply text-black-lighter;
}

.m--main-summary--level-three a {
  @apply text-white
}

@screen xs {
  .m--main-summary {
    max-width: 90%;
  }
}

@screen lg {
  .m--main-summary {
    max-width: 55%;
  }
}

.m--main-summary-contracted {
  position: relative;
  height: 40px;
  overflow-y: hidden;
}
.m--main-summary-contracted::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -moz-box-shadow: inset 0 -10px 10px 0px #222222;
  -webkit-box-shadow: inset 0 -10px 10px 0px #222222;
  box-shadow: inset 0 -10px 10px 0px #222222;
  z-index: 1;
}

.m--main-summary-contracted--level-one-two::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -moz-box-shadow: inset 0 -10px 10px 0px #FFFFFF;
  -webkit-box-shadow: inset 0 -10px 10px 0px #FFFFFF;
  box-shadow: inset 0 -10px 10px 0px #FFFFFF;
  z-index: 1;
}

.m--main-summary-expanded {
  @apply h-auto overflow-y-hidden;
}

.m--main-summary-btn {
  background: none;
  @apply text-14px font-bold text-grey-mono cursor-pointer block mt-10px p-0;
}

.m--main-summary-btn--level-three {
  @apply text-white underline
}