.acc__img img {
  @apply w-full h-full;
}

.acc__img--card {
  width: 55px;
  height: 33px;
}

.acc__img--rounded {
  min-width: 76px;
  min-height: 76px;
  max-width: 76px;
  max-height: 76px;
  border: 1.5px solid #E6E7EB;
  @apply rounded-full;
}

.acc__img--rounded img {
  background-color: #D8D8D8;
  border-radius: 100px;
}

.acc__img--small {
  min-width: 52px;
  min-height: 52px;
  max-width: 52px;
  max-height: 52px;
}

.acc__img--xs {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.acc__img--layer {
  position: relative;
  overflow: hidden;
}

.acc__img--layer:not(:last-child) {
  margin-right: -10px;
}

.acc__img--leftover {
  background-color: #ffffff;
  border-radius: 100px;
}

@screen lg {
  .acc__img--small {
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
  }

  .acc__img--layer:not(:last-child) {
    margin-right: -20px;
  }
}