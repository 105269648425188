.brand-section__banner {
  background: #333;
  height: 80px;
  @apply text-center w-full mb-20px text-white border-none;
}

.brand-section__banner .banner--inner,
.brands-atoz .banner--inner {
  min-height: 80px;
  @apply bg-no-repeat bg-right my-0 mx-auto;
}

.brands-atoz .banner--inner--loader {
  width: 55vw;
}

.brand-section__banner .banner--inner h1 {
  font-size: 32px;
  height: 80px;
  line-height: 80px;
  @apply text-white m-0 uppercase;
}

.theme-id .brand-section__banner .banner--inner h1 strong {
  @apply text-orange font-extrabold;
}

.theme-ed .brand-section__banner .banner--inner h1 strong {
  @apply text-green font-extrabold;
}

.brands-atoz {
  display: flex;
}

.brands-atoz .banner--inner .brands-letters {
  margin: 30px 5%;
  padding: 5px 0;
  border-top: solid 1px #aaa;
  border-bottom: solid 1px #aaa;
  @apply text-20px font-bold float-left;
}

.brands-atoz .banner--inner .brands-letters--loader {
  border-top: none;
  border-bottom: none;
  @apply w-full;
}

.brands-atoz .banner--inner .brands-letters a {
  padding: 7.1px 14.2px;
  color: #333;
  @apply float-left bg-transparent no-underline;
}

.brands-atoz .banner--inner .brands-letters a:hover {
  @apply underline text-blue-link bg-concrete;
}

.brand-contents__wrapper.all-brands {
  @apply mx-auto mt-20px mb-0 w-full;
}

.brand-contents__wrapper.all-brands ul {
  @apply w-full mb-4 mt-0;
}

.brand-contents__wrapper.all-brands ul li {
  width: 35%;
  margin: 0 4.5% 20px;
  background: #eee;
  padding: 3%;
  max-height: 110px;
  @apply float-left text-center transition-colors duration-1000 ease-in-out box-content rounded-md;
}

.brand-contents__wrapper.all-brands ul li.loader {
  padding: 0;
  background: none;
  width: unset;
  margin: 0 6% 20px;
}

.brand-contents__wrapper.all-brands ul li.loader:hover {
  background: none;
}

.brand-contents__wrapper.all-brands ul li:hover a {
  @apply underline;
}

.brand-contents__wrapper.all-brands ul li:hover a h5 {
  @apply font-bold;
}

.brand-contents__wrapper.all-brands ul li.letterHead.loader {
  border-bottom: none;
}

.brand-contents__wrapper.all-brands ul li.letterHead {
  width: 82%;
  min-height: initial;
  padding: 0 1%;
  border-bottom: solid 1px #aaa;
  margin: 20px 6%;
  @apply text-30px font-bold text-left bg-white;
}

.brand-contents__wrapper.all-brands ul li.letterHead:hover a {
  @apply bg-none no-underline;
}

.brand-contents__wrapper.all-brands ul li img {
  @apply inline-block align-middle;
}

.brand-contents__wrapper.all-brands ul li h5 {
  margin: 10px 0 0;
  font: 400 120% 'Proxima Nova W15', Arial;
}

@screen sm {
  .brand-contents__wrapper.all-brands ul li {
    max-height: inherit;
    min-height: 135px;
  }

  .brand-contents__wrapper.all-brands ul li.letterHead {
    @apply min-h-full;
  }
}

@screen md {
  .brand-contents__wrapper.all-brands {
    @apply px-30px;
  }

  .brand-contents__wrapper.all-brands ul li {
    width: 12%;
    margin: 0 1% 20px;
    background: #eee;
    padding: 3%;
    max-height: 110px;
    @apply float-left text-center transition-colors duration-1000 ease-in-out box-content;
  }

  .brand-contents__wrapper.all-brands ul li.letterHead {
    width: 90%;
    padding: 0 1%;
    border-bottom: solid 1px #aaa;
    margin: 0 1% 20px;
    @apply text-30px font-bold text-left bg-white mt-30px;
  }

  .brand-contents__wrapper.all-brands ul li.loader {
    margin: 0 2% 20px;
  }
}

@screen lg {
  .brands-atoz .banner--inner .brands-letters {
    margin: 30px 0;
  }
}

@screen xl {
  .brand-contents__wrapper.all-brands {
    width: 1200px;
  }
}
