.home-discover {
  padding: 2.875rem 0 3.125rem;
}

.home-discover .home-discover__inner .home-discover__head {
  margin-bottom: 2.125rem;
}

.home-discover .home-discover__inner .home-discover__head .home-discover__head-inner {
  -webkit-box-pack: justify;
  @apply flex items-center justify-between;
}

.home-discover .home-discover__inner .home-discover__head .home-discover__head-inner h2 {
  margin: 0;
  flex: 1 1;
  line-height: 1.17;
}

.home-discover
  .home-discover__inner
  .home-discover__head
  .home-discover__head-inner
  .home-discover__head-actions {
  margin-top: 0.1875rem;
}

.home-discover .home-discover__inner .home-discover__body .carousel__slide {
  flex: 1;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .carousel__slide:not(:first-child):not(:last-child) {
  @apply py-0 px-3;
}

.home-discover .home-discover__inner .home-discover__body .carousel__slide:first-child {
  @apply pr-3;
}

.home-discover .home-discover__inner .home-discover__body .carousel__slide:last-child {
  @apply pl-3;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card {
  min-width: 240px;
  @apply h-full;
}

.home-discover .home-discover__inner .home-discover__body .carousel button {
  @apply block;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner {
  background: #eee;
  @apply h-full rounded-lg overflow-hidden relative border-1 border-solid border-concrete flex flex-col transition-colors duration-200 ease-linear;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--link {
  z-index: 3;
  @apply text-blackBG absolute top-0 left-0 w-full h-full;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--image {
  height: 112px;
  @apply relative overflow-hidden;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--image
  .card__image--inner {
  background-position: 50% 50%;
  @apply absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--content {
  flex: auto;
  padding: 1.125rem 0.9375rem 1.1875rem 1.25rem;
  @apply flex flex-col transition-colors duration-500;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--content
  .card__content--inner {
  flex: auto;
  @apply mb-3.5;
}

.home-discover
  .home-discover__inner
  .home-discover__body
  .home-discover__slide
  .home-discover__card
  .home-discover__card--inner
  .home-discover__card--content
  .card__content--inner
  p {
  @apply font-bold text-18px leading-6;
}

@screen sm {
  .home-discover .home-discover__inner .home-discover__head {
    @apply mb-7/50;
  }
}

@screen md {
  .home-discover {
    padding: 4.1875rem 0 3.6875rem;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content
    .card__content--inner {
    @apply mb-3;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content
    .card__content--inner
    p {
    @apply text-20px leading-26px;
  }
}

@screen lg {
  .home-discover .home-discover__inner .home-discover__head .home-discover__head-inner h2 {
    margin: 0 0.625rem 0 0;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content
    .card__content--inner {
    @apply mb-3.5;
  }

  .home-discover .home-discover__inner .home-discover__body .carousel button {
    @apply hidden;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card {
    min-width: 100%;
  }
}

@screen xl {
  .home-discover {
    padding: 5.5rem 0 7.5rem;
  }

  .home-discover .home-discover__inner .home-discover__head {
    margin-bottom: 2.625rem;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content {
    padding: 1.125rem 1.25rem 1.1875rem;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content
    .card__content--inner {
    @apply mb-4.5;
  }

  .home-discover
    .home-discover__inner
    .home-discover__body
    .home-discover__slide
    .home-discover__card
    .home-discover__card--inner
    .home-discover__card--content
    .card__content--inner
    p {
    @apply text-18px leading-6;
  }
}

@screen xxl {
  .home-discover .home-discover__inner .home-discover__head {
    margin-bottom: 3.3125rem;
  }
}

@media (hover: hover), (-ms-high-contrast: none) {
  .theme-id .home-discover__card .home-discover__card--inner:hover .home-discover__card--content {
    @apply text-orange;
  }

  .theme-id .home-discover__card .home-discover__card--inner:hover .cta-btn--plain {
    @apply text-orange border-orange;
  }

  .theme-ed .home-discover__card .home-discover__card--inner:hover .home-discover__card--content {
    @apply text-green;
  }

  .theme-ed .home-discover__card .home-discover__card--inner:hover .cta-btn--plain {
    @apply text-green border-green;
  }
}

/* ED Homepage Promo CRO Implementation */
.cv-promo__section {
  padding-bottom: 50px;
  padding-top: 50px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto repeat(2, 320px);
  grid-gap: 16px;
}
.cv-promo__section > div,
.cv-promo__section > a {
  border-radius: 8px;
  overflow: hidden;
}
.cv-promo__section .cv-promo__btn,
.cv-promo__section .cv-promo__btn:link,
.cv-promo__section .cv-promo__btn:visited {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: inherit;
  padding: 10px 40px;
  border: 1px solid currentColor;
  border-radius: 100px;
  text-align: center;
  transition: all 0.3s;
}
@media only screen and (max-width: 768px) {
  .cv-promo__section .cv-promo__btn,
  .cv-promo__section .cv-promo__btn:link,
  .cv-promo__section .cv-promo__btn:visited {
    padding: 10px 25px;
  }
}

.hot-deals__cables {
  grid-column: 1/-1;
  padding: 24px;
  background: url(https://img-cdn.manutantraders.com/image/upload/w_1000,c_scale/v1691068295/electrical/campaigns/Summer%20Sale/homepage/Hot-deals-on-Cables-_-Management.jpg)
    no-repeat;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

@media only screen and (min-width: 381px) {
  .hot-deals__cables {
    height: 180px;
  }
}

@media only screen and (min-width: 426px) {
  .hot-deals__cables {
    height: 240px;
  }
}

@media only screen and (min-width: 561px) {
  .hot-deals__cables {
    height: 320px;
  }
}

@media only screen and (min-width: 769px) {
  .hot-deals__cables {
    height: 420px;
  }
}

@media only screen and (min-width: 1201px) {
  .hot-deals__cables {
    background: url(https://img-cdn.manutantraders.com/image/upload/v1691061984/electrical/campaigns/Summer%20Sale/homepage/cables/Hot-deals-on-Cables-_-Management-924x188.jpg)
      no-repeat;
    height: 188px;
    background-size: cover;
    background-position: center center;
  }
}

@media only screen and (min-width: 1340px) {
  .hot-deals__cables {
    background: url(https://img-cdn.manutantraders.com/image/upload/v1691061984/electrical/campaigns/Summer%20Sale/homepage/cables/Hot-deals-on-Cables-_-Management-1340x188.jpg)
      no-repeat;
    height: 188px;
    background-size: cover;
    background-position: center center;
  }
}

@media only screen and (min-width: 1820px) {
  .hot-deals__cables {
    background: url(https://img-cdn.manutantraders.com/image/upload/v1691061983/electrical/campaigns/Summer%20Sale/homepage/cables/Hot-deals-on-Cables-_-Management-1820x188.jpg)
      no-repeat;
    height: 188px;
    width: 100%;
  }
}

.hot-deals__consumer-units {
  padding: 24px;
  background: url(https://img-cdn.manutantraders.com/image/upload/w_1000,c_scale/v1691048865/electrical/campaigns/Summer%20Sale/homepage/Hot-deals-on-Consumer-Units.jpg)
    no-repeat;
  background-size: cover;
  background-position: center center;
}
.hot-deals__heating-and-ventilation {
  padding: 24px;
  background: url(https://img-cdn.manutantraders.com/image/upload/w_1000,c_scale/v1691048866/electrical/campaigns/Summer%20Sale/homepage/Hot-deals-on-Heating-_-Ventilation.jpg)
    no-repeat;
  background-size: cover;
  background-position: center center;
}
.hot-deals__lighting {
  padding: 24px;
  background: url(https://img-cdn.manutantraders.com/image/upload/w_1000,c_scale/v1691048865/electrical/campaigns/Summer%20Sale/homepage/Hot-deals-on-Lighting.jpg)
    no-repeat;
  background-size: cover;
  background-position: center center;
}
.hot-deals__sockets-and-switches {
  padding: 24px;
  background: url(https://img-cdn.manutantraders.com/image/upload/w_1000,c_scale/v1691048866/electrical/campaigns/Summer%20Sale/homepage/Hot-deals-on-Sockets-_-Switches.jpg)
    no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (min-width: 1400px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(2, 380px);
  }
}

@media only screen and (max-width: 760px) {
  .hot-deals__cables {
    padding: 50px 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(4, 420px);
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(4, 320px);
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 560px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(4, 240px);
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 425px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(4, 180px);
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 380px) {
  .cv-promo__section {
    grid-template-rows: auto repeat(4, 160px);
    grid-template-columns: 1fr;
  }
}
