.m--brands-section {
  @apply flex flex-wrap items-center mb-60px;
}

.m--brands-section-heading {
  color: #222222;
  @apply block w-full text-center mb-20px text-14px font-bold uppercase;
}

.m--brands-section-img-container {
  flex: 1 0 0%;
  @apply flex items-center justify-center mr-20px;
}

.m--brands-section-img {
  max-width: 100px;
  @apply w-full;
}

@screen lg {
  .m--brands-section-heading {
    @apply text-18px;
  }
}