.home-blog {
  padding: 3.5rem 0 2.5rem;
}

.home-blog .home-blog__inner .home-blog__head {
  margin-bottom: 1.8125rem;
}

.home-blog .home-blog__inner .home-blog__head h2 {
  margin-bottom: 0.6875rem;
}

.home-blog .home-blog__inner .home-blog__head .home-blog__head-inner {
  -webkit-box-pack: justify;
  @apply flex items-center justify-between;
}

.home-blog .home-blog__inner .home-blog__head .home-blog__head-inner p {
  /* flex: 0 0 28.125rem; */
  @apply max-w-none m-0 text-16px leading-5;
}

.home-blog .home-blog__inner .home-blog__head .home-blog__head-inner .home-blog__head-actions {
  margin-top: 0.1875rem;
}

.home-blog .home-blog__inner .home-blog__body .carousel {
  @apply mt-0 mb-6;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card {
  @apply h-full;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner {
  transition: border-color .2s linear;
  @apply h-full rounded-lg bg-grey-lightestx4 overflow-hidden relative border-1 border-solid border-concrete flex flex-col;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link {
  z-index: 3;
  @apply text-blackBG no-underline absolute top-0 left-0 w-full h-full;
}

.theme-id .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content {
  @apply text-orange;
}

.theme-ed .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content {
  @apply text-green;
}

.theme-id .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content .blog__content-inner span {
  @apply text-orange;
}

.theme-ed .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content .blog__content-inner span {
  @apply text-green;
}

.theme-id .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content .blog__content-actions a {
  @apply text-orange border-orange;
}

.theme-ed .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__link:hover ~ .blog__content .blog__content-actions a {
  @apply text-green border-green;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__image {
  height: 7.5rem;
  @apply relative overflow-hidden;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__image::after {
  content: "";
  background: linear-gradient(0deg,rgba(27,27,27,.65) 0,rgba(27,27,27,0));
  @apply block absolute top-0 left-0 w-full h-full;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__image .blog__image-inner {
  background-position: 50% 50%;
  @apply absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content {
  padding: 0 1.5625rem 1.25rem 1.125rem;
  transition: color .4s;
  flex: auto;
  margin-top: -2.1875rem;
  z-index: 2;
  @apply flex flex-col;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner {
  flex: auto;
  margin-bottom: 1.0625rem;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner span {
  transition: color .4s;
  @apply block mb-9 text-white;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner h3 {
  @apply leading-5 mb-2.5;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  @apply overflow-hidden;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-actions a {
  padding: 0.5rem 1.25rem 0.4375rem;
  border-width: 1.5px;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:not(:first-child):not(:last-child) {
  padding: 0 0.625rem;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:first-child {
  padding-right: 0.625rem;;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:last-child {
  padding-left: 0.625rem;
}

.home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide {
  flex: 1;
  min-width: 320px;
}

@screen sm {
  .home-blog .home-blog__inner .home-blog__head .home-blog__head-inner p {
    margin: 0 0.625rem 0 0;
    max-width: 26.25rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:not(:first-child):not(:last-child) {
    padding: 0 0.75rem;
  }
  
  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:first-child {
    padding-right: 0.75rem;;
  }
  
  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide:last-child {
    padding-left: 0.75rem;
  }
}

@screen md {
  .home-blog {
    padding: 4.5625rem 0 3.3125rem;
  }

  .home-blog .home-blog__inner .home-blog__head {
    margin-bottom: 2.4375rem;
  }

  .home-blog .home-blog__inner .home-blog__head h2 {
    @apply mb-4.5;
  }

  .home-blog .home-blog__inner .home-blog__head .home-blog__head-inner p {
    max-width: 28.125rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__image {
    height: 10.75rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content {
    padding: 1.625rem 1.5625rem 1.5625rem 1.5rem;
    margin-top: 0;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner {
    margin-bottom: 1.25rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner span {
    margin-bottom: 1.1875rem;
    @apply text-black-lighter;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner h3 {
    margin-bottom: 0.4375rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide {
    min-width: 410px;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner h3 {
    line-height: 1.4;
  }
  
  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__image::after {
    @apply hidden;
  }
}

@screen lg {
  .home-blog {
    padding: 4.6875rem 0 3.6875rem;
  }

  .home-blog .home-blog__inner .home-blog__head .home-blog__head-inner p {
    max-width: 38.75rem;
    flex: 0 0 38.75rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content .blog__content-inner {
    margin-bottom: 2.25rem;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel button {
    @apply hidden;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide {
    @apply min-w-0;
  }
}

@screen xl {
  .home-blog {
    padding: 8rem 0 7.375rem;
  }

  .home-blog .home-blog__inner .home-blog__head h2 {
    @apply mb-5;
  }

  .home-blog .home-blog__inner .home-blog__body .carousel .carousel__slide .blog-card__inner .blog__content {
    padding: 1.875rem 2.375rem 1.875rem 1.75rem;  
  }
}