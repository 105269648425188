@media (max-width: 767px) {
  /* .catalogueContainer.thanksPage {
    height: 354px;
  }

  .catalogueContainer .contactContent.thanksContent {
    padding: 30px 15px;
  }  */

    /* alice carousel */
  .alice-carousel__next-btn-wrapper .alice-carousel__next-btn-item, .alice-carousel__prev-btn-wrapper .alice-carousel__prev-btn-item {
    background: #c7d303;
  }

  .alice-carousel__next-btn span[data-area]:after {
    content: url(https://www.electricaldirect.co.uk/assets/svg/ico-arrow-right.svg);
  }

  .alice-carousel__prev-btn span[data-area]:after {
    content: url(https://www.electricaldirect.co.uk/assets/svg/ico-arrow-left.svg);
  }

  .alice-carousel__stage li {
    width: 277px !important;
  }

  .alice-carousel__wrapper {
    padding-left: 20px !important;
  }

  .alice-carousel__stage li:nth-child(2) {
    margin-left: 20px;
  }

  .alice-carousel .alice-carousel__prev-btn {
    left: 10px;
  }
}

/* .catalogueContent input[type='radio'] {
  display: none;
}

.catalogueContent input[type='radio'] + label:hover {
  cursor: pointer;
}

.catalogueContent input[type='radio'] + label {
  padding: 24px 87px;
}

.catalogueContent input[type='radio']:checked + label.id {
  background: #e6e6e6 url(https://res.cloudinary.com/manutantraders/image/upload/v1632239026/Icons/Rebrand_tick_ID.svg) no-repeat 90% center;
  border: 2px solid #efa847
}

.catalogueContent input[type='radio']:checked + label.ed {
  background: #e6e6e6 url(https://res.cloudinary.com/manutantraders/image/upload/v1632236361/Icons/Rebrand_tick_ED.svg) no-repeat 90% center;
  border: 2px solid #c7d303
}

input[type='checkbox']:checked ~ label {
  background: black;
  color: white;
}

input#brochure-submit-3:not(:placeholder-shown) ~ label,
input#brochure-submit-4:not(:placeholder-shown) ~ label {
  transform: none;
}

.theme-ed input[type='checkbox']:checked + label.cta-btn--plain:hover {
  background-color: black !important;
  border-color: black;
  color: #fff;
}  */

/* header */
/* .tc-hero {
  position: relative;
  background: linear-gradient(90deg,#222323 50%,rgba(31,31,31,0) 60%);
}
@media (min-width: 0em) {
  .tc-hero {
    padding: 30px 0;
    background: linear-gradient(rgba(31,31,31,.4),#232323);
  }
}
@media (min-width: 36em) {
  .tc-hero {
    padding: 30px 0;
    background: linear-gradient(90deg,#222323 45%,rgba(31,31,31,0) 80%);
  }
}
@media (min-width: 48em) {
  .tc-hero {
    background: linear-gradient(90deg,#222323 52%,rgba(31,31,31,0) 80%);
  }
}
@media (min-width: 90em) {
  .tc-hero {
    padding: 80px 0;
  }
}
@media (min-width: 100em) {
  .tc-hero {
    padding: 60px 0 120px;
  }
}
.tc-hero .tc-hero__img__container {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  z-index: -1;
}
@media (min-width: 0em) {
  .tc-hero .tc-hero__img__container {
    width: 100%;
  }
}
@media (min-width: 48em) {
  .tc-hero .tc-hero__img__container {
    width: 60%;
  }
}
@media (min-width: 90em) {
  .tc-hero .tc-hero__img__container {
    width: 50%;
  }
}
@media (min-width: 100em) {
  .tc-hero .tc-hero__img__container {
    width: 55%;
  }
}
.tc-hero .tc-hero__img__background {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left;
  object-position: left;
  width: 100%;
}
.tc-hero__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tc-hero__content {
  color: #fff;
  max-width: 50%;
  flex: 0 0 50%;
  padding: 50px 0 0;
  z-index: 2;
}
@media (min-width: 0em) {
  .tc-hero__content {
    padding: 0;
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 48em) {
  .tc-hero__content {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 100em) {
  .tc-hero__content {
    max-width: 45%;
    flex: 0 0 45%;
    margin-top: 3.125rem;
  }
}
.free-catalogue .tc-hero__content {
  margin: 0!important;
}
@media (min-width: 74.5em) {
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem!important;
  }
}
.tc-hero__content .tc-hero__subheading {
  line-height: 1.16;
  display: block;
  font-weight: 700;
  margin: 0 0 0.8125rem;
}
.tc-hero__content p {
  margin: 1.5rem 0;
  line-height: 1.35;
}
@media (min-width: 0em) {
  .tc-hero__content p {
    max-width: 100%;
  }
}
@media (min-width: 62em) {
  .tc-hero__content p {
    max-width: 75%;
  }
}
.free-catalogue .tc-hero__content p {
  max-width: 85%!important;
} */

/* Needed to target placeholder of react-loqate input */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D3D3D3 !important;
  opacity: 1; /* Firefox */
}

/* Fix manual address fields preventing click on react loqate drop down list bug */
[data-testid="react-loqate-list"] {
  z-index: 1;
}













