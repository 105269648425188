.m--lister {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.m--lister-page {
  background: #f3f3f3;
}
.m--lister-page--level1,
.m--lister-page--level2 {
  background: #fff;
}

.m--lister-item {
  transition: all 0.75s ease;
  position: relative;
}
@media (min-width: 0em) {
  .m--lister-item {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}
@media (min-width: 36em) {
  .m--lister-item {
    margin-bottom: 50px;
    border-bottom: none;
    width: 49%;
    padding: 0;
  }
}
@media (min-width: 74.5em) {
  .m--lister-item {
    width: 31.25%;
  }
  .m--lister-item:not(:nth-of-type(3n)) {
    margin-right: 3%;
  }
}

.m--lister-item--grid-view {
  display: block;
}
@media (max-width: 36em) {
  .m--lister-item--grid-view {
    width: 100%;
  }
}
.m--lister-item--grid-view .m--lister-item-footer {
  display: flex;
  justify-content: space-between;
}

.m--lister-item--list-view {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
@media (min-width: 0em) {
  .m--lister-item--list-view .m--lister-item-header {
    height: 300px;
    width: 300px;
  }
}
@media (min-width: 131.25em) {
  .m--lister-item--list-view .m--lister-item-header {
    height: 400px;
    width: 400px;
  }
}

.m--lister-item-header {
  position: relative;
  display: flex;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
}
@media (min-width: 0em) {
  .m--lister-item-header {
    margin: 0 10px;
  }
}
@media (min-width: 36em) {
  .m--lister-item-header {
    margin: unset;
  }
}
.m--lister-item-header--recommended {
  border: 3px solid;
}
.theme-id .m--lister-item-header--recommended {
  border-color: #efa847;
}
.theme-ed .m--lister-item-header--recommended {
  border-color: #c7d303;
}
.m--lister-item-header--recommended .upper-badge-container {
  left: -3px;
  top: -3px;
}

.m--lister-item-footer {
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .m--lister-item-footer {
    /* IE10+ CSS styles go here */
    margin: 0 10px;
  }
}
@media (max-width: 36em) {
  .m--lister-item-footer {
    margin-bottom: 25px;
  }
}
@media (max-width: 61em) {
  .m--lister-item-footer {
    padding: 0 10px;
  }
}

.upper-badge-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}

.bottom-badge-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bottom-badge-container .lister-item__badge__tooltip {
  position: relative;
  background: #f3f3f3;
  border-radius: 20px;
  padding: 15px;
  margin: 0 5px 1rem 0;
  border: 1px solid #d3d3d3;
}
.bottom-badge-container .lister-item__badge__tooltip__arrow {
  width: 0;
  height: 0;
  position: absolute;
  left: 55%;
  transform: translateX(-55%);
  bottom: -10px;
}
.bottom-badge-container .lister-item__badge__tooltip__arrow::after,
.bottom-badge-container .lister-item__badge__tooltip__arrow::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  border-top: 1px solid #d3d3d3;
}
.bottom-badge-container .lister-item__badge__tooltip__arrow::before {
  left: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
.bottom-badge-container .lister-item__badge__tooltip__arrow::after {
  left: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f3f3f3;
}
.bottom-badge-container .lister-item__badge__tooltip .price-breaks {
  margin: 0;
  flex-direction: column;
  max-height: 156px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@screen md {
  .bottom-badge-container .lister-item__badge__tooltip .price-breaks {
    @apply max-h-none;
  }
}
@media (max-width: 64em) {
  .bottom-badge-container .lister-item__badge__tooltip .price-breaks {
    display: none;
  }
}
.bottom-badge-container .lister-item__badge__tooltip .price-breaks__price-break {
  margin: 0 0 15px 0 !important;
}
.bottom-badge-container .lister-item__badge__tooltip .price-breaks__price-break:last-child {
  margin: 0 !important;
}
.bottom-badge-container
  .lister-item__badge__tooltip
  .price-breaks__price-break
  .price-break__header {
  padding: 5px;
}
.bottom-badge-container
  .lister-item__badge__tooltip
  .price-breaks__price-break
  .price-break__footer {
  padding: 4px 10px;
}

.bottom-badge-container .lister-item__badge__tooltip .price-breaks::-webkit-scrollbar {
  display: none;
}

.lister-item__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  margin: 0.5rem 0.75rem;
}
@media (min-width: 0em) {
  .lister-item__badge {
    width: 155px !important;
  }
}
@media (min-width: 74.5em) {
  .lister-item__badge {
    width: 150px !important;
  }
}
@media (min-width: 100em) {
  .lister-item__badge {
    width: 155px !important;
  }
}
.lister-item__badge:only-child {
  width: fit-content;
}
.lister-item__badge--oos {
  background: #cc4646;
  color: #fff;
  padding: 10px 30px;
}
.lister-item__badge--bulk {
  background: #f3f3f3;
  position: relative;
  cursor: pointer;
}
.lister-item__badge--bulk .pound-symbol {
  height: 20px;
  width: auto;
  margin-right: 10px;
}

.lister-item__expected-delivery {
  margin: 8px 12px;
  font-weight: 900;
}

.made-to-measure-badge {
  width: 100%;
  background: #000;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0.75rem 1rem;
  border-radius: 0px 0px 10px 10px;
}

.m--lister-item-brand-img {
  width: 50px;
  position: absolute;
  top: 10px;
  right: 15px;
  opacity: 0.9;
  z-index: 10;
}

.m--lister-item-img {
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  background: white;
}
@media (min-width: 0em) {
  .m--lister-item-img {
    padding: 2rem;
  }
}
@media (min-width: 48em) {
  .m--lister-item-img {
    padding: 4rem;
  }
}
.m--lister-item-img img {
  display: block;
}
@media (min-width: 0em) {
  .m--lister-item-img img {
    width: 200px;
    margin: 0 auto;
  }
}
@media (min-width: 48em) {
  .m--lister-item-img img {
    width: 100%;
  }
}

.m--lister-item-details {
  width: 100%;
}

.m--lister-item-review {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 0.75rem 0;
}

.m--lister-item-review-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.m--lister-item-stars-container {
  font-size: inherit;
  color: #ffedbd;
}

.m--lister-item-stars {
  color: #ffc324;
}

.m--lister-item-name {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
}
.m--lister-item-name:hover {
  color: #222222;
}

.m--lister-item-sku {
  margin-bottom: 10px;
  font-size: 16px;
  color: #9b9b9b;
  font-weight: bold;
}
@media (min-width: 0em) {
  .m--lister-item-sku {
    display: none;
  }
}
@media (min-width: 48em) {
  .m--lister-item-sku {
    display: block;
  }
}

.m--lister-item-footer--list-view {
  flex: 2;
  transition: all 1.25s ease;
}
@media (min-width: 0em) {
  .m--lister-item-footer--list-view {
    margin-left: 1.5rem;
  }
}
@media (min-width: 64em) {
  .m--lister-item-footer--list-view {
    margin-left: 3rem;
  }
}

.lister-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  color: #000;
  border: none;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 100;
  flex-grow: 0;
  padding: 1rem;
  height: 100%;
}
.lister-item-btn--disabled {
  background-color: #e4e4e4 !important;
  color: #919191 !important;
  cursor: auto !important;
}

.m--lister-item-price-breaks {
  display: flex;
  margin-bottom: 15px;
}

.m--lister-item-price-atb-container {
  width: 100%;
}

.m--lister-item-price-break {
  display: flex;
  align-items: baseline;
  margin-top: 0.5rem;
}
@media (min-width: 0em) {
  .m--lister-item-price-break {
    margin-bottom: 1.5rem;
  }
}
@media all and (min-width: 0em) and (-ms-high-contrast: none),
  (min-width: 0em) and (-ms-high-contrast: active) {
  .m--lister-item-price-break {
    /* IE10+ CSS styles go here */
    margin: 0.5rem 0;
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  .m--lister-item-price-break {
    margin-bottom: 1rem;
    /* IE10+ CSS styles go here */
    margin: 1rem 0;
  }
}
.m--lister-item-price-break.m--lister-item-price-initial .m--lister-item-price {
  font-size: 24px;
  font-weight: 700;
}

.m--lister-item-price {
  color: #000;
  font-size: 12px;
  margin-right: 5px;
}

.m--lister-item-unit {
  color: #222222;
  font-size: 20px;
  font-weight: 700;
  margin-right: 5px;
}

.m--lister-item-vat {
  font-size: 16px;
  color: #9b9b9b;
}

.m--lister-item-atb {
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
}
@media (min-width: 0em) {
  .m--lister-item-atb {
    padding: 0 10px;
  }
}
@media (min-width: 62em) {
  .m--lister-item-atb {
    padding: 0;
  }
}
.m--lister-item-atb .lister-item-atb-btn {
  padding: 1rem !important;
  margin: 0 0.5rem !important;
  text-transform: capitalize !important;
}
@media (min-width: 0em) {
  .m--lister-item-atb .lister-item-atb-btn {
    flex: 1;
  }
}
@media (min-width: 36em) {
  .m--lister-item-atb .lister-item-atb-btn {
    flex: none;
  }
}

.m--lister-item-bottom-border {
  height: 1px;
  width: 100%;
  background: #d3d3d3;
}
@media (min-width: 0em) {
  .m--lister-item-bottom-border {
    display: block;
  }
}
@media (min-width: 36em) {
  .m--lister-item-bottom-border {
    display: none;
  }
}

.m--lister-item-atb-qty {
  display: flex;
  align-items: center;
  border: none;
  text-align: center;
  width: 54px;
  height: 100%;
  border-radius: 3px;
  margin-right: 10px;
  -moz-appearance: textfield;
  font-size: 14px;
  font-weight: 900;
}
.m--lister-item-atb-qty::-webkit-inner-spin-button,
.m--lister-item-atb-qty::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.made-to-measure-btn {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: 1px solid #000;
  text-align: center;
}
@media (min-width: 0em) {
  .made-to-measure-btn {
    flex: 1;
  }
}
@media (min-width: 36em) {
  .made-to-measure-btn {
    flex: none;
  }
}

.m--lister-item-oos {
  display: flex;
  align-self: center;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: #666;
}

@media (max-width: 61em) {
  .m--lister-item-details {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
}

@media (min-width: 0em) and (max-width: 73.5em) and (min-width: 36em) {
  .m--lister-item:not(:nth-of-type(2n)) {
    margin-right: 2%;
  }
}

@media (min-width: 62em) {
  .m--lister-item-rec-banner-badge {
    top: 32.5px;
    bottom: auto;
  }
  .m--lister-item-img {
    margin: 0 auto;
  }
}

/* Add to Basket Hierarchy CRO Exp */
.lister-item .lister-item__header {
  position: relative;
}

.lister-item .lister-item__bulk-badge {
  width: calc(100% - 1.5rem) !important;
}

.stockInfoText {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #747975;
  margin-left: auto;
  align-self: center;
}

.stockInfo--low {
  position: absolute;
  bottom: 16px;
  left: 0.75rem;
  width: calc(100% - 1.5rem) !important;
  z-index: 2;
  background: #f3f3f3 !important;
  padding: 10px 20px !important;
}

.bottom-badge-container {
  position: relative;
  z-index: 3;
}

.bottom-badge-container + .stockInfo--low {
  bottom: 64px !important;
}

.bottom-badge-container + .stockInfo--low.cv-bottom-0 {
  bottom: 0.75rem !important;
}

.lister-item__footer .stock-level {
  display: none !important;
}

.lister-item__footer:not(.m--lister-item-footer--list-view) .m--lister-item-atb {
  position: relative;
  padding: 0 !important;
}

.lister-item__footer:not(.m--lister-item-footer--list-view) .m--lister-item-atb input {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -32px;
  transform: translateY(-100%);
}

.lister-item__footer:not(.m--lister-item-footer--list-view) .m--lister-item-atb > div {
  width: 100% !important;
  margin-left: 0 !important;
}

.lister-item__footer:not(.m--lister-item-footer--list-view) .m--lister-item-atb > div button {
  width: 100%;
  text-align: center;
}

.m--lister-item-footer--list-view .lister-item-details__review {
  max-width: 560px;
}

.m--lister-item-footer--list-view .m--lister-item-atb > div {
  min-width: 212px;
  padding-left: 10px;
}

/* Collapsible Facet Filters */
.cro-acc > div:last-of-type {
  display: none;
}
.cro-acc.cro-open > div:last-of-type {
  display: block;
}
h1.facet-section-heading {
  cursor: pointer;
  width: calc(100% + 20px);
  margin-left: -20px;
  padding-left: 20px;
  position: relative;
}
h1.facet-section-heading:after {
  content: '';
  border-style: solid;
  border-width: 6px 8px 6px 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  right: 20px;
  transform: rotate(-90deg);
  top: 7px;
  transition: all 0.5s ease;
}
.cro-open h1.facet-section-heading:after {
  transform: rotate(90deg);
}
.facet-section-header {
  overflow: hidden;
}
.facet-section-header + div > div:nth-of-type(2) {
  display: none !important;
}

/* Bulk Buy Upsell */
.lister-item__bulk-buy {
  width: 200px;
  border-radius: 20px;
  top: -160px;
  border: 1px solid #d3d3d3;
  z-index: 5;
  padding: 12px;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: end;
  background: white;
}

.lister-item__bulk-buy__tooltip__arrow {
  width: 0;
  height: 0;
  position: absolute;
  left: 55%;
  transform: translateX(-55%);
  bottom: -10px;
}
.lister-item__bulk-buy__tooltip__arrow::after,
.lister-item__bulk-buy__tooltip__arrow::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  border-top: 1px solid #d3d3d3;
}
.lister-item__bulk-buy__tooltip__arrow::before {
  left: 55px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
.lister-item__bulk-buy__tooltip__arrow::after {
  left: 55px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}

/* Multiple Tiles on Mobile */

@media (max-width: 767px) {
  .cv-4-16__item .stockInfo--low {
    border-radius: 4px !important;
    width: calc(100% - 14px) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: inherit;
    padding: 5px 10px !important;
    align-items: center !important;
    bottom: 7px !important;
  }
  .cv-4-16__item .stockInfo--low > span > p {
    font-size: 14px !important;
  }
  .cv-4-16__item .bottom-badge-container + .stockInfo--low {
    bottom: 45px !important;
  }
  .cv-4-16__item .lister-item__header {
    background: #ffffff;
    border-radius: 6px !important;
    height: auto !important;
    min-height: 200px;
    overflow: visible !important;
  }
  .cv-4-16__item .lister-item__header__img > a > button > div {
    max-width: 120px;
    max-height: 120px;
  }
  .cv-4-16__item .lister-item__header .lister-item__bulk-badge {
    margin-bottom: 7px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: calc(100% - 14px) !important;
  }
  .cv-4-16__item .lister-item__header .lister-item__bulk-badge > button {
    border-radius: 4px !important;
    padding: 5px 10px !important;
    justify-content: center;
  }
  .cv-4-16__item .lister-item__header .lister-item__bulk-badge > button > img {
    margin-right: 4px !important;
  }
  .cv-4-16__item .lister-item__header .lister-item__badge__tooltip {
    border-radius: 12px !important;
    max-width: 160px;
    min-width: 160px;
    transform: translateX(10px);
  }
  .cv-4-16__item .lister-item__header .lister-item__badge__tooltip .price-breaks.w-40 {
    width: 100% !important;
  }
  .cv-4-16__item .lister-item__header .lister-item__badge__tooltip .price-breaks.w-40 button > div {
    border-radius: 6px !important;
    border: 0 !important;
    background: #fff;
  }
  .cv-4-16__item .lister-item__header .lister-item__badge__tooltip__arrow {
    left: 10px !important;
  }
  .cv-4-16__item .lister-item__header > div.absolute.top-6.right-6.z-1 {
    width: 40px !important;
    height: 40px !important;
    top: 8px !important;
    right: 8px !important;
  }
  @media only screen and (max-width: 360px) {
    .cv-4-16__item .lister-item__header > div.absolute.top-6.right-6.z-1 {
      width: 32px !important;
      height: 32px !important;
    }
  }
  .cv-4-16__item .lister-item__header.border-4 {
    border: 2px solid #efa847 !important;
  }
  .cv-4-16__item .lister-item__header.border-4 .lister-item__header__img > a > button > div {
    margin-top: 26px;
  }
  .cv-4-16__item .lister-item__header.border-4 > div.absolute.top-6.right-6.z-1 {
    top: 35px !important;
  }
  .cv-4-16__item .product-badge--recommended {
    width: 100%;
    border-radius: 0px !important;
    justify-content: center;
  }
  .cv-4-16__item .product-badge--recommended > span.font-bold {
    font-size: 12px;
  }
  .cv-4-16__item .product-badge--recommended > .relative.h-5.w-5.mr-2 {
    max-width: 15px;
    max-height: 15px;
  }
  .cv-4-16__item .cv-hide {
    display: none !important;
  }
  .cv-4-16__item .m--lister-item-atb {
    margin-top: 75px !important;
  }
  .cv-4-16__item .m--lister-item-atb input.lister-item-atb__qty {
    top: -16px !important;
    width: 100% !important;
    border-radius: 6px !important;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
  }
  .cv-4-16__item .lister-item__price-uom-vat-container .lister-item-price,
  .cv-4-16__item .lister-item__price-uom-vat-container .lister-item-unit,
  .cv-4-16__item .lister-item__price-uom-vat-container .lister-item-discount {
    line-height: 1;
    margin-bottom: 8px;
  }
  .cv-4-16__item .lister-item__price-uom-vat-container .lister-item-unit {
    transform: translateY(-1.5px);
  }
  .cv-4-16__item .lister-item__price-uom-vat-container .lister-item-vat {
    margin-right: 4px;
    line-height: 1;
    margin-bottom: 8px;
  }
  .cv-4-16__newProduct .lister-item__header .lister-item__header__img > a > button > div {
    margin-top: 26px;
  }
  .cv-4-16__newProduct .lister-item__header > div.absolute.top-6.right-6.z-1 {
    top: 35px !important;
  }
  .cv-4-16__newProductBadge {
    width: 100% !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cv-4-16__newProductBadge > div {
    width: 100% !important;
    border-radius: 0px !important;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
  }
  .cv-4-16__stockInfo {
    margin: 16px 0;
  }
  .cv-4-16__discounted .lister-item__price-uom-vat-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .cv-4-16__discounted .lister-item-price,
  .cv-4-16__discounted .lister-item-unit,
  .cv-4-16__discounted .lister-item-discount {
    color: #c84646 !important;
  }
  .cv-4-16__discounted .lister-item-unit {
    transform: translateY(-1.5px);
  }
  .cv-4-16__discounted .lister-item-vat {
    margin-right: 4px;
    line-height: 1;
    margin-bottom: 8px;
  }
  .cv-4-16__productTitle button {
    display: none;
  }
  .cv-4-16__productTitle-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .cv-4-16__recBadge__parent {
    width: 100%;
    border-radius: 0 !important;
  }
  .cv-4-16__recBadge__parent > div.font-bold.px-3.py-1.rounded-br-xl {
    display: none !important;
  }
  .cv-4-16__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .cv-4-16__grid .lister-item {
    width: 100% !important;
  }
  .stockInfoText {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 360px) {
    .cv-4-16__grid {
      grid-column-gap: 16px;
    }
  }

  /*# sourceMappingURL=variation.css.map */
}
