.product-card {
  width: 165px;
  @apply h-full;
}

.product-card .product-card__inner {
  @apply relative transition-colors duration-200;
}

.theme-id .product-card .product-card__inner:hover {
  @apply text-orange;
}

.theme-ed .product-card .product-card__inner:hover {
  @apply text-green;
}

.theme-id .product-card .product-card__inner:hover .product-card__image {
  @apply border-orange;
}

.theme-ed .product-card .product-card__inner:hover .product-card__image {
  @apply border-green;
}

.product-card .product-card__inner .product-card__link {
  z-index: 3;
  @apply absolute top-0 left-0 w-full h-full text-blackBG no-underline;
}

.product-card .product-card__inner .product-card__image {
  padding: 1.5625rem;
  border: 1px solid #eee;
  border-radius: 8px;
  @apply overflow-hidden mb-5 transition-colors duration-200;
}

.product-card .product-card__inner .product-card__content .content__inner {
  @apply mb-2.5;
}

.product-card .product-card__inner .product-card__content .content__inner p {
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  @apply text-16px leading-5 overflow-hidden;
}

.product-card .product-card__inner .product-card__content .content__price {
  line-height: 1.36;
  @apply text-14px;
}

.product-card .product-card__inner .product-card__content .content__price strong {
  line-height: 1.4;
  @apply font-bold inline-block text-20px;
}

@supports (-webkit-line-clamp: 2) {
  .product-card .product-card__inner .product-card__content .content__inner p {
    white-space: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    @apply text-16px leading-5 overflow-hidden;
  }
}