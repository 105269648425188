.head-btn {
  @apply transition-all duration-200 text-white text-12px inline-flex relative bg-transparent font-bold leading-6 items-center outline-none border-none rounded-full;
}

.head-btn:hover {
  @apply bg-grey-primary;
}

.head-btn--id:hover .utl-svg {
  @apply fill-orange;
}

.head-btn--ed:hover .utl-svg {
  @apply fill-green;
}

.head-btn:focus {
  @apply outline-none;
}

.head-btn--id:hover {
  @apply text-orange;
}

.head-btn--ed:hover {
  @apply text-green;
}

.utl-span {
  @apply p-2 h-9 w-auto inline-flex relative items-center justify-center;
}

.utl-svg {
  @apply h-5 w-5 inline-block align-middle overflow-visible fill-current;
}

.header-banner {
  height: 2.5625rem;
  background: #353535;
  @apply w-full;
}

.header-banner .header-banner__inner {
  @apply max-w-full h-full relative my-0 mx-auto;
}

.header-banner .header-banner__inner .viewport-banner {
  @apply overflow-hidden w-full h-full;
}

.header-banner .header-banner__inner .viewport-banner .banner__container {
  @apply flex select-none w-full h-full items-center;
  -webkit-tap-highlight-color: transparent;
}

.header-banner .header-banner__inner .viewport-banner .banner__container .banner__slide {
  @apply relative;
}

.header-banner .header-banner__inner .button--prev {
  left: -6.5%;
  top: 0.8125rem;
  @apply text-white absolute border-none bg-transparent;
}

.header-banner .header-banner__inner .button--prev svg {
  width: 0.78125rem;
  height: 0.78125rem;
  @apply inline-block align-middle overflow-visible fill-current;
}

.header-banner .header-banner__inner .button--next {
  right: -6.5%;
  top: 0.8125rem;
  @apply text-white absolute border-none bg-transparent;
}

.header-banner .header-banner__inner .button--next svg {
  width: 0.78125rem;
  height: 0.78125rem;
  @apply inline-block align-middle overflow-visible fill-current;
}

.header-banner
  .header-banner__inner
  .viewport-banner
  .banner__container
  .banner__slide
  .content__link {
  min-width: 100vw;
  @apply border-none bg-transparent no-underline font-bold text-white flex justify-center items-center text-12px leading-5;
}

.header-banner
  .header-banner__inner
  .viewport-banner
  .banner__container
  .banner__slide
  .content__link:hover {
  @apply underline;
}

.header-banner
  .header-banner__inner
  .viewport-banner
  .banner__container
  .banner__slide
  .content__link
  svg {
  display: block;
  margin: 0 0.4375rem 0 0;
}

.header-banner
  .header-banner__inner
  .viewport-banner
  .banner__container
  .banner__slide
  .content__link
  span {
  @apply font-normal;
}

@screen md {
  .utl-svg {
    @apply h-6 w-6;
  }

  .header-banner {
    height: 3.125rem;
  }

  .header-banner .header-banner__inner {
    max-width: 30.9375rem;
  }

  .header-banner
    .header-banner__inner
    .viewport-banner
    .banner__container
    .banner__slide
    .content__link {
    min-width: 30.9375rem;
    @apply text-16px leading-6;
  }
}

@screen xl {
  .header-banner {
    padding: 0 3.1875rem;
  }

  .header-banner .header-banner__inner {
    max-width: 76.875rem;
  }

  .header-banner .header-banner__inner .viewport-banner .banner__container {
    @apply justify-between;
  }

  .header-banner
    .header-banner__inner
    .viewport-banner
    .banner__container
    .banner__slide
    .content__link {
    @apply min-w-full;
  }

  .header-banner
    .header-banner__inner
    .viewport-banner
    .banner__container
    .banner__slide
    .content__link
    svg {
    margin: 0 0.9375rem 0 0;
  }
}
