@media (min-width: 1401px) {
  .section-tradeaccount {
    background-image: url(https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/v1633689835/ironmongery/pages/trade-account/intro-banner.jpg);
  }
}

@media (min-width: 901px) and (max-width: 1400px) {
  .section-tradeaccount {
    background-image: url(https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/v1633689835/ironmongery/pages/trade-account/intro-banner-tablet.jpg);
  }

  .headerContainer-parent {
    padding: 10px 0 3px;
  }
}

@media (min-width: 1025px) {
  .headerContainer-parent {
    margin-left: -8%;
  }

  .headerContainer-child {
    width: 1200px;
    margin: 0 auto;
  }
}

.semi-circle {
  border-radius: 50%;
}

.veriff-description,
.veriff-label,
.veriff-text {
  display: none;
}

.veriff-submit {
  font-size: 0;
  @apply bg-white block font-bold py-2 rounded-full border shadow-md text-sm w-1/2 cursor-pointer mx-auto;
}
