.nav-menu {
  /* For IE */
  transform: translateX(-50rem);
  @apply w-full h-screen transition-all duration-200 transform fixed top-0 bottom-0 left-0 right-auto bg-black-light z-50 overflow-x-hidden overflow-y-auto text-white translate-x-menuTranslate;
}

.nav-menu.opened {
  /* For IE */
  transform: translateX(0);
  @apply translate-x-0 block;
}

.nav-menu--brand {
  @apply w-full;
}

.nav-menu--brand .menu--brands {
  padding: 1.5625rem 0;
  border-bottom: 1px solid #393b3b;
}

.nav-menu--alt {
  transform: translateX(50rem);
  @apply w-full py-9 px-5 h-screen transition-all duration-200 transform fixed top-0 bottom-0 left-auto right-0 bg-black-light z-50 overflow-x-hidden overflow-y-auto text-white translate-x-menuTranslate--alt;
}

.nav-menu--alt.opened {
  /* For IE */
  transform: translateX(0);
  @apply translate-x-0 block;
}

.menu--head {
  @apply pt-5 px-5 pb-0 flex flex-shrink-0 content-start items-center relative z-1;
}

.head__main {
  @apply flex items-center justify-start;
}

.head__btn {
  @apply rounded-lg mt-1 w-auto transition-all text-white text-16px font-bold leading-6 relative bottom-searchHeader inline-flex items-center content-center h-10 bg-transparent outline-none;
}

.head__btn:hover {
  @apply bg-grey-primary rounded-full;
}

.head__btn:focus {
  @apply outline-none;
}

.head__btn--span {
  @apply w-auto py-0 px-2 inline-flex content-center items-center h-6;
}

.head__btn--svg {
  @apply w-20px h-20px m-0 mr-0 align-middle inline-block overflow-visible fill-current;
}

.head--logo {
  @apply ml-7 flex items-center;
}

.head--logo a {
  @apply text-white inline-block border-none bg-transparent no-underline font-normal;
}

.head--logo a:hover {
  @apply no-underline;
}

.head--logo__img {
  @apply w-24 h-6;
}

.head__btn--back {
  top: 5rem;
  left: 1.25rem;
  line-height: 1.375rem;
  background-color: #353636;
  @apply text-16px font-bold h-8 text-white rounded-full pr-3.5 outline-none border-none absolute inline-flex items-center justify-center;
}

.head__btn--back:hover {
  background-color: #353636;
}

.head__btn--back svg {
  height: 0.78125rem;
  width: 0.78125rem;
  line-height: 1.375rem;
  margin: 0.1875rem 0.3125rem 0 0.75rem;
  @apply inline-block align-middle overflow-visible fill-current;
}

.theme-id .navFooter {
  @apply block flex-col justify-between flex-grow mb-0 h-auto py-15px border-t-1 border-black-order-sum-b border-solid;
}

.theme-ed .navFooter {
  @apply block flex-col justify-between flex-grow mb-0 h-auto py-15px border-t-1 border-black-order-sum-b border-solid;
}

.menu-container__nav {
  transform: translateX(0);
  transition: transform 0.1s 0s;
  @apply pt-5 px-5 pb-0;
}

.menu-container__nav--hidden {
  transform: translateX(-15%);
  @apply invisible h-0 pt-0 pr-0 pl-0 pb-0 overflow-hidden;
}

.menu-container__nav--products {
  transform: translateX(0);
  transition: transform 0.1s 0s;
  margin-top: 81px;
  @apply py-0 pl-5 pr-0;
}

.menu-container__nav--products--hidden {
  transform: translateX(-15%);
  @apply invisible h-0 p-0 overflow-hidden;
}

.menu-container__nav--brands {
  transform: translateX(0);
  transition: transform 0.1s 0s;
  margin-top: 81px;
  @apply py-0 pl-5 pr-0;
}

.menu-container__nav--brands--hidden {
  transform: translateX(-15%);
  @apply invisible h-0 p-0 overflow-hidden;
}

.menu--bottom {
  @apply pt-5 px-5 pb-0 transition transform duration-100;
}

.menu--bottom--hidden {
  transform: translateX(-15%);
  @apply invisible h-0 p-0 overflow-hidden;
}

.bottom-nav__btn {
  @apply text-14px h-30px bg-black-pitch text-white px-2;
}

.bottom-nav__btn:hover {
  @apply bg-black-light-pitch text-white;
}

.search-box__container {
  @apply fixed top-0 left-0 right-0 z-50 pt-6/25 px-5 pb-0 bg-black-light h-full overflow-x-hidden overflow-y-auto w-auto min-h-full max-h-full;
}

.search-box__wrapper {
  max-width: 93.75rem;
  @apply m-auto flex flex-wrap;
}

.nav-menu--stage2 .menu-container__nav--products {
  border-right: 1px solid #393b3b;
  transform: translateX(-100%);
}

.nav-menu--stage3 .menu-container__nav--products .nav--products-stage2 {
  border-right: 1px solid #393b3b;
  transform: translateX(-100%);
}

.search-box__results {
  @apply w-full mt-7/50;
}

.search-field__input {
  @apply block py-2 pr-10 pl-20px appearance-none w-full h-10 leading-6 text-16px my-0 mx-auto bg-white rounded-3xl outline-none text-black-lighter;
}

.search-field__input:focus {
  @apply outline-none;
}

.search-field__input::-ms-clear {
  @apply hidden w-0 h-0;
}

.search-field__input::-ms-reveal {
  @apply hidden w-0 h-0;
}

.search-field__input::-webkit-search-decoration,
.search-field__input::-webkit-search-cancel-button,
.search-field__input::-webkit-search-results-button,
.search-field__input::-webkit-search-results-decoration {
  @apply hidden;
}

.search-box__container .search-box__keywords {
  @apply relative block pb-0 mb-0 text-white break-words;
}

.search-box__container .search-box__keywords::before {
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  top: 50%;
  left: -2.5rem;
  pointer-events: none;
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-50%);
  background-image: url('data:image/svg+xml,%3Csvg width="16" height="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cg transform="translate(.5)" stroke="%234d4e4e" fill="none"%3E%3Ccircle cx="5.5" cy="5.5" r="4.75" /%3E%3Crect transform="rotate(-45 11.357 11.457)" x="11.107" y="8.207" width="1" height="6.5" rx=".5" /%3E%3C/g%3E%3C/svg%3E');
}

.theme-id .search-box__container .search-box__keywords.selected::before {
  background-image: url('data:image/svg+xml,%3Csvg width="16" height="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cg transform="translate(.5)" stroke="%23EFA847" fill="none"%3E%3Ccircle cx="5.5" cy="5.5" r="4.75" /%3E%3Crect transform="rotate(-45 11.357 11.457)" x="11.107" y="8.207" width="1" height="6.5" rx=".5" /%3E%3C/g%3E%3C/svg%3E');
}

.theme-ed .search-box__container .search-box__keywords.selected::before {
  background-image: url('data:image/svg+xml,%3Csvg width="16" height="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cg transform="translate(.5)" stroke="%23C7D303" fill="none"%3E%3Ccircle cx="5.5" cy="5.5" r="4.75" /%3E%3Crect transform="rotate(-45 11.357 11.457)" x="11.107" y="8.207" width="1" height="6.5" rx=".5" /%3E%3C/g%3E%3C/svg%3E');
}

.search-box__container .suggestions__anchor {
  width: 7.0625rem;
  @apply no-underline flex flex-col relative text-black-lighter mr-5;
}

.search-box__container .suggestions__anchor:hover .suggestions__name,
.search-box__container .suggestions__anchor:hover .suggestions__price {
  @apply underline;
}

.search-box__container .suggestions__anchor .suggestions__img {
  width: 7.25rem;
  height: 7.25rem;
  @apply flex justify-center items-center mb-3.5 bg-white rounded-lg;
}

.search-box__container .suggestions__anchor .suggestions__name {
  @apply text-white mb-1;
}

.search-box__container .suggestions__anchor .suggestions__name p {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  @apply font-bold m-0 overflow-hidden;
}

.search-box__container .suggestions__anchor .suggestions__price {
  @apply inline-block text-white;
}

.search-box__container .suggestions__anchor .suggestions__price p {
  margin-right: 0.125rem;
  @apply font-bold;
}

.search-box__container .suggestions__anchor .suggestions__price p span {
  color: #4a4a4a;
  @apply text-14px;
}

.basket-aside {
  @apply flex flex-col;
}

.basket-aside .basket-container {
  @apply relative flex flex-col;
}

.basket-aside .basket-container .basket__loader {
  z-index: 99;
  background-color: rgba(34, 35, 35, 0.9);
  @apply top-0 right-0 left-0 bottom-0 absolute justify-center items-center flex;
}

.theme-id #ReactHeaderQty .header-basket__qty {
  height: 1.375rem;
  width: 1.375rem;
  bottom: -0.25rem;
  right: -0.75rem;
  @apply bg-orange rounded-full block text-center text-12px leading-20px font-bold absolute text-black-lighter;
}

.theme-id #ReactHeaderQty .header-basket__loader {
  height: 1.375rem;
  width: 1.375rem;
  @apply bg-orange rounded-full block text-center text-12px leading-20px font-bold absolute text-black-lighter;
}

.theme-ed #ReactHeaderQty .header-basket__qty {
  height: 1.375rem;
  width: 1.375rem;
  bottom: -0.25rem;
  right: -0.75rem;
  @apply bg-green rounded-full block text-center text-12px leading-20px font-bold absolute text-black-lighter;
}

.theme-ed #ReactHeaderQty .header-basket__loader {
  height: 1.375rem;
  width: 1.375rem;
  @apply bg-green rounded-full block text-center text-12px leading-20px font-bold absolute text-black-lighter;
}

.basket-aside .basket-container .basket__empty {
  color: #393b3b;
  @apply flex-col flex items-center justify-center text-center pt-50;
}

.basket-aside .basket-container .basket__empty svg {
  color: #393b3b;
  fill: #393b3b;
  width: 5.3125rem;
  height: 5.3125rem;
  @apply inline-block align-middle overflow-visible;
}

.basket-aside .basket-container .basket__empty p {
  @apply mt-5 font-bold text-24px leading-7;
}

.basket-aside .basket__heading {
  @apply text-white flex items-center;
}

.basket-aside .basket__heading svg {
  width: 1.375rem;
  height: 1.3125rem;
  @apply inline-block align-middle overflow-visible fill-current mr-15px;
}

.basket-aside .basket__heading p {
  @apply m-0 text-22px leading-9 font-bold;
}

.rhd-menu__brands__a2z {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 62em) {
  .rhd-menu__brands__a2z {
    max-width: 49.0625rem;
  }
}
@media (min-width: 74.5em) {
  .rhd-menu__brands__a2z {
    max-width: 36.875rem;
  }
}
.rhd-menu__brands__a2z__heading {
  color: white;
  font-size: 2.5rem;
  line-height: 3.0625rem;
  font-weight: 700;
  width: 100%;
  margin: 0 0 1.25rem 0;
}
.rhd-menu__brands__a2z__heading:nth-child(n + 2) {
  margin: 1.875rem 0 1.25rem 0;
}
.rhd-menu__brands__a2z__link {
  margin: 0 1.25rem 1.25rem 0;
  width: 7.1875rem;
}
@media (min-width: 36em) {
  .rhd-menu__brands__a2z__link {
    width: 8.4375rem;
  }
}
@media (min-width: 74.5em) {
  .rhd-menu__brands__a2z__link {
    width: 9.375rem;
  }
}

.rhd-basket__table__body {
  display: flex;
  flex-direction: column;
  margin: 1.5625rem 0 0 0;
}
.rhd-basket__table__body.active > .rhd-basket__table__row::after {
  content: '';
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(34, 35, 35, 0.9);
}
.rhd-basket__table__body.active > .rhd-basket__table__row.active::after {
  display: none;
}

.rhd-basket__table__row {
  padding: 0 0 1.25rem 0;
  border-bottom: 1px solid #393b3b;
  position: relative;
}
.rhd-basket__table__row ~ .rhd-basket__table__row {
  padding: 1.25rem 0 1.25rem 0;
}
.rhd-basket__table__row .rhd-aside__input__validation:first-of-type {
  margin: 1.25rem 0 0 0;
}
.rhd-basket__table__row .rhd-aside__input__validation,
.rhd-basket__table__row .rhd-aside__input__validation:last-of-type {
  margin: 1.25rem 0 0;
}

.rhd-basket__summary {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 1.25rem 0;
}
.rhd-basket__summary__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1.25rem 0;
}
.rhd-basket__summary__toggle p {
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.rhd-basket__summary__toggle .switch {
  display: flex;
  border-radius: 17px;
}
.rhd-basket__summary__toggle .switch:focus.focus-visible {
  outline: none;
  box-shadow: 0 0 0 4px #222323, 0 0 0 6px #4d4e4e;
  transition: box-shadow 0.2s ease-out;
}
.rhd-basket__summary__table {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 1.25rem 0;
  border-top: 1px solid #393b3b;
  border-bottom: 1px solid #393b3b;
}
.rhd-basket__summary__table__row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.25rem 0;
}
.rhd-basket__summary__table__row p {
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin: 0;
}
.theme-id .rhd-basket__summary__table__row .highlight {
  color: #efa847;
}
.theme-ed .rhd-basket__summary__table__row .highlight {
  color: #c7d303;
}
.rhd-basket__summary__table__row--total {
  margin: 0;
}
.rhd-basket__summary__table__row--total p {
  font-size: 1.25rem;
  line-height: 1.9375rem;
  font-weight: 700;
}

.rhd-basket__cta {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 6.25rem 0;
}
.rhd-basket__cta .cta-btn:first-child,
.rhd-basket__cta .cta-btn--transparent--option:first-child,
.rhd-basket__cta .cta-btn--transparent--option--active:first-child,
.rhd-basket__cta
  .product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item
  .product-rev__bottom
  .active:first-child,
.product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item
  .product-rev__bottom
  .rhd-basket__cta
  .active:first-child,
.rhd-basket__cta
  .product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item--status
  .product-rev__bottom
  .active:first-child,
.product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item--status
  .product-rev__bottom
  .rhd-basket__cta
  .active:first-child,
.rhd-basket__cta
  .product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item--status--fail
  .product-rev__bottom
  .active:first-child,
.product-rev__wrapper--view
  .product-rev__inner--left
  .product-rev__item--status--fail
  .product-rev__bottom
  .rhd-basket__cta
  .active:first-child {
  margin: 0 0 0.9375rem 0;
}

.rhd-product {
  color: #fff;
  display: flex;
}
.rhd-product__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.25rem 0 0;
  background-color: #fff;
  width: 100%;
  max-width: 5.4375rem;
  height: 7.3125rem;
  border-radius: 5px;
}
.rhd-product__img img {
  width: 100%;
}
.rhd-product__sale {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 4%;
  color: #000;
  border-radius: 20px;
  width: 90%;
  height: 1.5rem;
  font-size: 0.875rem;
  padding: 0.25rem;
}
.theme-id .rhd-product__sale {
  background-color: #f3c586;
}
.theme-ed .rhd-product__sale {
  background-color: #ecf286;
}
.rhd-product__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rhd-product__name {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.rhd-product__name:hover {
  @apply underline text-white;
}
.rhd-product__price {
  margin: 0.5rem 0 0.5rem 0;
}
.rhd-product__price > p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}
.rhd-product__controls {
  display: flex;
  justify-content: space-between;
}
.rhd-product__controls__qty {
  display: flex;
  flex-direction: column;
}

.rhd-product__qty {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #d3d3d7;
  background-color: #e6e6e6;
  overflow: hidden;
  width: 6.1875rem;
  height: 1.8125rem;
  font-size: 0.75rem;
}
.rhd-product__qty__input {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  width: 25%;
  text-align: center;
  border: none;
  cursor: pointer;
}
.rhd-product__qty__input svg {
  width: 0.78125rem;
  height: 0.78125rem;
}
.rhd-product__qty__input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.rhd-product__qty__input:focus.focus-visible {
  border: 1px solid #4d4e4e;
}
.rhd-product__qty__field {
  border: none;
  background-color: #fff;
  height: 100%;
  width: 50%;
  text-align: center;
  font-size: 0.75rem;
  color: #242424;
  -moz-appearance: textfield;
}
.rhd-product__qty__field::-webkit-outer-spin-button,
.rhd-product__qty__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rhd-product__qty + .rhd-product__confirm {
  margin: 0.5rem 0 0 0;
}

.rhd-product__confirm {
  display: flex;
  align-items: center;
}
.rhd-product__confirm__cancel,
.rhd-product__confirm__update {
  font-size: 0.875rem;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}
.rhd-product__confirm__cancel:hover,
.rhd-product__confirm__update:hover {
  text-decoration: none;
}
.rhd-product__confirm__cancel:focus.focus-visible,
.rhd-product__confirm__update:focus.focus-visible {
  outline: none;
  box-shadow: 0 0 0 4px #222323, 0 0 0 6px #4d4e4e;
  transition: box-shadow 0.2s ease-out;
}
.rhd-product__confirm span {
  margin: 0 0.5rem 0 0.5rem;
}
.rhd-product__confirm__cancel {
  color: #fff;
}
.theme-id .rhd-product__confirm__update {
  color: #efa847;
}
.theme-ed .rhd-product__confirm__update {
  color: #c7d303;
}

.rhd-aside__input__validation {
  margin: 1.25rem 0 0 0;
  background: #2a2b2b;
  border: 1px solid #333535;
  border-radius: 5px;
  padding: 1rem 1.25rem;
}
.rhd-aside__input__validation:focus.focus-visible {
  outline: none;
  box-shadow: 0 0 0 4px #222323, 0 0 0 6px #4d4e4e;
  transition: box-shadow 0.2s ease-out;
}
.rhd-aside__input__validation--link {
  text-decoration: none;
}
.rhd-aside__input__validation--link:hover .rhd-aside__input__validation__title {
  text-decoration: underline;
}
.rhd-aside__input__validation--basket {
  margin: 0 0 1.25rem 0;
}
.rhd-aside__input__validation__title {
  display: inline-block;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0.25rem 0.25rem 0;
}
.theme-id .rhd-aside__input__validation__title {
  color: #efa847;
}
.theme-ed .rhd-aside__input__validation__title {
  color: #c7d303;
}
.rhd-aside__input__validation__title.question::before,
.rhd-aside__input__validation__title.warning::before,
.rhd-aside__input__validation__title.successful::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0.25rem 0.25rem 0;
}
.theme-id .rhd-aside__input__validation__title.backorder {
  color: #d32f2f;
}
.theme-ed .rhd-aside__input__validation__title.backorder {
  color: #d32f2f;
}
.theme-id .rhd-aside__input__validation__title.warning {
  color: #d32f2f;
}
.theme-ed .rhd-aside__input__validation__title.warning {
  color: #d32f2f;
}
.rhd-aside__input__validation__title.warning::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cdefs%3E%3Cstyle%3E.fa-secondary%7Bfill:%23d32f2f%7D%3C/style%3E%3C/defs%3E%3Cpath d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 376a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 265.52 288h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 233.68 128h44.64a16 16 0 0 1 15.92 17.59z" class="fa-secondary"/%3E%3Cpath d="M278.32 128h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 246.48 288h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 278.32 128zM256 320a32 32 0 1 0 32 32 32 32 0 0 0-32-32z" class="fa-primary"/%3E%3C/svg%3E');
}
.rhd-aside__input__validation__title--id.question::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cdefs%3E%3Cstyle%3E.fa-secondary%7Bfill:%23EFA847%7D%3C/style%3E%3C/defs%3E%3Cpath d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 422a46 46 0 1 1 46-46 46.05 46.05 0 0 1-46 46zm40-131.33V300a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12v-4c0-41.06 31.13-57.47 54.65-70.66 20.17-11.31 32.54-19 32.54-34 0-19.82-25.27-33-45.7-33-27.19 0-39.44 13.14-57.3 35.79a12 12 0 0 1-16.67 2.13L148.82 170a12 12 0 0 1-2.71-16.26C173.4 113 208.16 90 262.66 90c56.34 0 116.53 44 116.53 102 0 77-83.19 78.21-83.19 106.67z" class="fa-secondary"/%3E%3Cpath d="M256 338a46 46 0 1 0 46 46 46 46 0 0 0-46-46zm6.66-248c-54.5 0-89.26 23-116.55 63.76a12 12 0 0 0 2.71 16.24l34.7 26.31a12 12 0 0 0 16.67-2.13c17.86-22.65 30.11-35.79 57.3-35.79 20.43 0 45.7 13.14 45.7 33 0 15-12.37 22.66-32.54 34C247.13 238.53 216 254.94 216 296v4a12 12 0 0 0 12 12h56a12 12 0 0 0 12-12v-1.33c0-28.46 83.19-29.67 83.19-106.67 0-58-60.19-102-116.53-102z" class="fa-primary"/%3E%3C/svg%3E');
}
.rhd-aside__input__validation__title--id.successful::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cdefs%3E%3Cstyle%3E.fa-secondary%7Bfill:%23EFA847%7D%3C/style%3E%3C/defs%3E%3Cpath d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.31 195.31l-184 184a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62z" class="fa-secondary"/%3E%3Cpath d="M227.31 387.31a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62l-184 184z" class="fa-primary"/%3E%3C/svg%3E');
}
.rhd-aside__input__validation__title--ed.question::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cdefs%3E%3Cstyle%3E.fa-secondary%7Bfill:%23C7D303%7D%3C/style%3E%3C/defs%3E%3Cpath d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 422a46 46 0 1 1 46-46 46.05 46.05 0 0 1-46 46zm40-131.33V300a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12v-4c0-41.06 31.13-57.47 54.65-70.66 20.17-11.31 32.54-19 32.54-34 0-19.82-25.27-33-45.7-33-27.19 0-39.44 13.14-57.3 35.79a12 12 0 0 1-16.67 2.13L148.82 170a12 12 0 0 1-2.71-16.26C173.4 113 208.16 90 262.66 90c56.34 0 116.53 44 116.53 102 0 77-83.19 78.21-83.19 106.67z" class="fa-secondary"/%3E%3Cpath d="M256 338a46 46 0 1 0 46 46 46 46 0 0 0-46-46zm6.66-248c-54.5 0-89.26 23-116.55 63.76a12 12 0 0 0 2.71 16.24l34.7 26.31a12 12 0 0 0 16.67-2.13c17.86-22.65 30.11-35.79 57.3-35.79 20.43 0 45.7 13.14 45.7 33 0 15-12.37 22.66-32.54 34C247.13 238.53 216 254.94 216 296v4a12 12 0 0 0 12 12h56a12 12 0 0 0 12-12v-1.33c0-28.46 83.19-29.67 83.19-106.67 0-58-60.19-102-116.53-102z" class="fa-primary"/%3E%3C/svg%3E');
}
.rhd-aside__input__validation__title--ed.successful::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cdefs%3E%3Cstyle%3E.fa-secondary%7Bfill:%23C7D303%7D%3C/style%3E%3C/defs%3E%3Cpath d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.31 195.31l-184 184a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62z" class="fa-secondary"/%3E%3Cpath d="M227.31 387.31a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62l-184 184z" class="fa-primary"/%3E%3C/svg%3E');
}
.rhd-aside__input__validation__body {
  color: #fff;
  font-size: 1rem;
  margin: 0;
}
.rhd-aside__input__validation.inactive {
  display: none;
}
.rhd-aside__input__validation.active {
  display: block;
}

.rhd-btn {
  transition: all 200ms linear;
  transition-property: background, background-color, color, border-color;
  display: inline-flex;
  position: relative;
  background: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 0;
  border-radius: 50%;
}
.rhd-btn--small {
  font-size: 0.75rem;
}
.rhd-btn--icon-tertiary {
  color: #fff;
  text-decoration: none;
}
.rhd-btn.active {
  color: #efa847;
}
.theme-id .rhd-btn.active {
  color: #efa847;
}
.theme-ed .rhd-btn.active {
  color: #c7d303;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .rhd-btn:hover {
    background: #353636;
  }
  .theme-id .rhd-btn:hover {
    color: #efa847;
  }
  .theme-ed .rhd-btn:hover {
    color: #c7d303;
  }
}
.rhd-btn--hbasket {
  border-radius: 0;
  height: 1.25rem;
  margin: 0.25rem 0 0 0;
}
.rhd-btn--hbasket:hover {
  background: none;
}
.rhd-btn:focus.focus-visible {
  outline: none;
  box-shadow: 0 0 0 4px #fff, 0 0 0 6px #222323;
  transition: box-shadow 0.2s ease-out;
}
.rhd-btn--focus-dark:focus.focus-visible {
  outline: none;
  box-shadow: 0 0 0 4px #222323, 0 0 0 6px #4d4e4e;
  transition: box-shadow 0.2s ease-out;
}

@media (min-width: 62em) {
  .rhd-btn.menu {
    border-radius: 8px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.rhd-btn__inner {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 48em) {
  .rhd-btn__inner {
    width: 2.25rem;
    height: 2.25rem;
    width: auto;
  }
}
@media (min-width: 62em) {
  .rhd-btn__inner {
    padding: 0 0.5rem;
  }
}
.rhd-btn__inner--hbasket {
  width: auto;
  height: 1.25rem;
}
.rhd-btn__inner--hbasket span {
  text-decoration: underline;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .rhd-btn__inner--hbasket:hover span {
    text-decoration: none;
  }
  .theme-id .rhd-btn__inner--hbasket:hover svg {
    fill: #efa847;
  }
  .theme-ed .rhd-btn__inner--hbasket:hover svg {
    fill: #c7d303;
  }
}
.rhd-btn__inner--hbasket svg {
  transition: all 200ms linear;
  transition-property: background, background-color, color, border-color, fill;
  display: none;
  width: 0.78125rem;
  fill: #ffffff;
  height: 0.78125rem;
  margin-left: 0.375rem;
  margin-top: 0.25rem;
}
@media (min-width: 36em) {
  .rhd-btn__inner--hbasket svg {
    display: block;
  }
}

.rhd-btn--small .rhd-btn__inner {
  padding: 0 0.5rem;
}

.rhd-btn--small .rhd-btn__inner::after {
  content: '';
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  display: block;
}

.switch.switch--default > .switch-toggle {
  height: 34px;
  width: 55.25px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 17px;
  will-change: background;
  transition: background 0.2s 0.1 0.5s ease-out;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #fff !important;
  will-change: transform;
  transition: transform 0.2s ease-out;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before {
  background: #efa847 !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::after {
  transform: translateX(23.25px);
}
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  transform: translateX(2px);
}

.switch.switch--ed > .switch-toggle {
  height: 34px;
  width: 55.25px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}
.switch.switch--ed > .switch-toggle.switch-toggle--on::before,
.switch.switch--ed > .switch-toggle.switch-toggle--on::after,
.switch.switch--ed > .switch-toggle.switch-toggle--off::before,
.switch.switch--ed > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}
.switch.switch--ed > .switch-toggle.switch-toggle--on::before,
.switch.switch--ed > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 17px;
  will-change: background;
  transition: background 0.2s 0.1 0.5s ease-out;
}
.switch.switch--ed > .switch-toggle.switch-toggle--on::after,
.switch.switch--ed > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #fff !important;
  will-change: transform;
  transition: transform 0.2s ease-out;
}
.switch.switch--ed > .switch-toggle.switch-toggle--on::before {
  background: #c7d303 !important;
}
.switch.switch--ed > .switch-toggle.switch-toggle--on::after {
  transform: translateX(23.25px);
}
.switch.switch--ed > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}
.switch.switch--ed > .switch-toggle.switch-toggle--off::after {
  transform: translateX(2px);
}

.switch.switch--account > .switch-toggle {
  height: 34px;
  width: 55.25px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}
.switch.switch--account > .switch-toggle.switch-toggle--on::before,
.switch.switch--account > .switch-toggle.switch-toggle--on::after,
.switch.switch--account > .switch-toggle.switch-toggle--off::before,
.switch.switch--account > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}
.switch.switch--account > .switch-toggle.switch-toggle--on::before,
.switch.switch--account > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 17px;
  will-change: background;
  transition: background 0.2s 0.1 0.5s ease-out;
}
.switch.switch--account > .switch-toggle.switch-toggle--on::after,
.switch.switch--account > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #fff !important;
  will-change: transform;
  transition: transform 0.2s ease-out;
}
.switch.switch--account > .switch-toggle.switch-toggle--on::before {
  background: #173090 !important;
}
.switch.switch--account > .switch-toggle.switch-toggle--on::after {
  transform: translateX(23.25px);
}
.switch.switch--account > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}
.switch.switch--account > .switch-toggle.switch-toggle--off::after {
  transform: translateX(2px);
}

.switch.switch--graphite-small > .switch-toggle {
  height: 20px;
  width: 32.5px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 10px;
  will-change: background;
  transition: background 0.2s 0.1 0.5s ease-out;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #fff !important;
  will-change: transform;
  transition: transform 0.2s ease-out;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
  background: gray !important;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
  transform: translateX(14.5px);
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  transform: translateX(2px);
}

.menu-container__nav--products .products-nav__level1 {
  padding: 0.5rem 1.3125rem 0.5rem 0;
  border-bottom: 1px solid #393b3b;
  @apply relative text-14px leading-22px w-full max-w-none;
}

.theme-id .menu-container__nav--products .products-nav__level1 .products-nav__link.active {
  @apply text-orange pointer-events-none;
}

.theme-ed .menu-container__nav--products .products-nav__level1 .products-nav__link.active {
  @apply text-green pointer-events-none;
}

.menu-container__nav--products .products-nav__level1 .products-nav__link {
  padding: 0.75rem 0;
  @apply flex justify-between items-center w-full text-white border-none bg-transparent no-underline font-normal text-left;
}

.menu-container__nav--products .products-nav__level1 .products-nav__link:hover {
  @apply underline;
}

.menu-container__nav--products .products-nav__level1 .products-nav__link.active svg {
  transform: rotate(180deg);
}

.menu-container__nav--products .products-nav__level1 .products-nav__link svg {
  width: 0.78125rem;
  height: 0.78125rem;
  transition: transform 0.2s 0s;
  transform: rotate(0deg);
  @apply inline-block align-middle overflow-visible fill-current;
}

.menu-container__nav--products .products-nav__level1 .nav--products-stage2 {
  padding: 0 1.125rem 0 1.25rem;
  top: -2.5rem;
  left: 100%;
  border-bottom: 1px solid #393b3b;
  @apply h-auto absolute w-full block;
}

.menu-container__nav--products
  .products-nav__level1
  .nav--products-stage2
  .products-stage2__heading {
  padding: 0.75rem 0;
  @apply flex justify-between items-center w-full text-white border-none bg-transparent no-underline font-normal;
}

.menu-container__nav--products .products-nav__level1 .nav--products-stage2 .nav--products-stage3 {
  border-bottom: 1px solid #393b3b;
  padding: 0 1.125rem 0 1.25rem;
  left: 100%;
  @apply h-auto absolute top-0 w-full;
}

#account-aside .credit__header {
  @apply text-white text-16px leading-18px mb-3;
}

#account-aside .credit__main .credit__progress {
  border-radius: 6.5px;
  @apply relative overflow-hidden h-3 w-full bg-grey-lightestx3;
}

#account-aside .credit__main .credit__progress span {
  display: block;
  height: 100%;
  border-radius: 6.5px;
}

#account-aside .credit__main .credit__amount p {
  color: #6b6b6b;
  @apply text-14px leading-16px mt-2.5;
}

.theme-id #account-aside .credit__main .credit__amount p span {
  @apply text-orange text-20px leading-24px;
}

.theme-ed #account-aside .credit__main .credit__amount p span {
  @apply text-green text-24px leading-24px;
}

#account-aside #aside-account-block .account__loader,
#account-aside #aside-login-block .account__loader {
  z-index: 99;
  background-color: rgba(34, 35, 35, 0.9);
  @apply top-0 right-0 left-0 bottom-0 absolute justify-center items-center flex;
}

#account-aside .credit__main .credit__progress .progress__meter {
  -webkit-animation: progressBar 1s ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar 1s ease-in-out;
  -moz-animation-fill-mode: both;
}

.theme-id #account-aside .credit__main .credit__progress .progress__meter {
  @apply bg-orange;
}

.theme-ed #account-aside .credit__main .credit__progress .progress__meter {
  @apply bg-green;
}

.cro-main__link .cro-nav__link {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  @apply flex justify-between items-center px-4 text-black-lighter bg-white transition-colors duration-200 w-full;
}

.cro-main__link .cro-nav__link svg {
  @apply fill-black-lighter;
}

.cro-main__link .cro-link {
  left: 274px;
  width: calc(100% - 274px);
  @apply top-0 absolute;
}

.cro-main__link .cro-link .cro-link__content {
  height: 65vh;
  @apply hidden w-full flex-col flex-wrap;
}

.cro-main__link:hover .cro-nav__link {
  @apply text-white bg-black-light;
}

.theme-id .cro-main__link:hover .cro-nav__link svg {
  @apply fill-orange;
}

.theme-ed .cro-main__link:hover .cro-nav__link svg {
  @apply fill-green;
}

.cro-main__link:hover .cro-link .cro-link__content {
  @apply flex;
}

.cro-nav__btn {
  transform: rotate(0deg);
  @apply text-white font-bold no-underline mr-6 flex items-center py-10px border-b-2 border-solid border-transparent transition-all duration-200;
}

.theme-id .cro-nav__btn.active,
.theme-id .cro-nav__btn:hover {
  @apply text-orange border-orange;
}

.theme-ed .cro-nav__btn.active,
.theme-ed .cro-nav__btn:hover {
  @apply text-green border-green;
}

.cro-nav__btn.active svg {
  transform: rotate(90deg);
}

.cro-main__link--brand .cro-nav__link--brand {
  @apply cursor-pointer w-full flex justify-between items-center py-2 px-4 text-black-lighter bg-white transition-colors duration-200;
}

.theme-id .cro-main__link--brand.active .cro-nav__link--brand,
.theme-id .cro-main__link--brand:hover .cro-nav__link--brand {
  @apply text-orange bg-black-lighter;
}

.theme-ed .cro-main__link--brand.active .cro-nav__link--brand,
.theme-ed .cro-main__link--brand:hover .cro-nav__link--brand {
  @apply text-green bg-black-lighter;
}

.cro-main__link--brand .cro-nav__link--brand svg {
  @apply transition-all duration-200 fill-black-lighter h-4 w-4;
}

.theme-id .cro-main__link--brand.active .cro-nav__link--brand svg,
.theme-id .cro-main__link--brand:hover .cro-nav__link--brand svg {
  @apply fill-orange;
}

.theme-ed .cro-main__link--brand.active .cro-nav__link--brand svg,
.theme-ed .cro-main__link--brand:hover .cro-nav__link--brand svg {
  @apply fill-green;
}

.cro-main__link--brand .cro-link--brand {
  left: 274px;
  width: calc(100% - 274px);
  @apply top-0 absolute;
}

.cro-main__link--brand .cro-link--brand .cro-link__content--brand {
  height: 65vh;
  @apply hidden w-full flex-col flex-wrap;
}

.cro-main__link--brand.active .cro-link--brand .cro-link__content--brand,
.cro-main__link--brand:hover .cro-link--brand .cro-link__content--brand {
  @apply flex;
}

.nav-header__wrapper {
  height: 200px;
}

.nav-header__wrapper.is-hompage {
  height: 0px;
}

.nav-category__main {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  @apply font-bold py-1 px-4 text-black-lighter bg-white transition-colors duration-200;
}

.nav-category__main:hover {
  @apply text-white bg-black-light;
}

.nav-header__categories {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@screen md {
  .nav-menu {
    width: 21.875rem;
  }

  .nav-menu--brand {
    @apply w-full;
  }

  .nav-menu--product {
    @apply w-full;
  }

  .nav-menu--alt {
    width: 28.125rem;
    @apply py-10 px-7/50;
  }

  .menu--head {
    @apply pt-5 px-7/50 pb-0;
  }

  .head__btn--svg {
    @apply mr-0;
  }

  .head--logo {
    @apply ml-30px;
  }

  .head--logo__img {
    @apply w-32 h-8;
  }

  .head__btn--back {
    top: 8.75rem;
  }

  .menu-container__nav {
    @apply pt-14 px-7/50 pb-0;
  }

  .menu-container__nav--hidden {
    @apply pt-0 pr-0 pl-0 pb-0;
  }

  .nav-menu--stage2 .menu-container__nav--products {
    transform: translateX(0);
  }

  .nav-menu--stage3 .menu-container__nav--products .nav--products-stage2 {
    transform: translateX(0);
  }

  .menu-container__nav--products {
    max-width: 17.1875rem;
    margin-top: 7.1875rem;
    @apply py-0 px-7/50;
  }

  .menu-container__nav--brands {
    margin-top: 7.1875rem;
    @apply py-0 px-7/50;
  }

  .menu--bottom {
    @apply pt-7/50 px-7/50 pb-0;
  }

  .search-box__container {
    min-height: 26.8125rem;
    max-height: 48.75rem;
    @apply pt-7/50 px-7/50 pb-0 h-auto;
  }

  .search-box__wrapper {
    @apply flex-no-wrap;
  }

  .search-box__results {
    @apply mt-0 ml-5 mr-0 max-w-full;
  }

  .search-box__container .suggestions__anchor {
    width: 7.25rem;
  }

  .search-box__container .suggestions__anchor .suggestions__img {
    width: 7.0625rem;
    height: 7.0625rem;
  }

  .basket-aside .basket__heading p {
    @apply text-20px leading-8;
  }

  .menu-container__nav--products .products-nav__level1 .products-nav__link {
    padding: 0.78125rem 0;
  }

  .menu-container__nav--products .products-nav__level1 .nav--products-stage2 {
    min-width: 17.1875rem;
    top: -8.5%;
    left: 15.625rem;
    padding: 0 1.3125rem 0 1.625rem;
  }

  .menu-container__nav--products
    .products-nav__level1
    .nav--products-stage2
    .products-stage2__heading {
    padding: 0.78125rem 0;
  }

  .menu-container__nav--products
    .products-nav__level1
    .nav--products-stage2
    .products-stage2__heading:hover {
    @apply underline;
  }

  .menu-container__nav--products .products-nav__level1 .nav--products-stage2 .nav--products-stage3 {
    padding: 0 0 0 1.3125rem;
    border-left: 1px solid #393b3b;
  }

  .nav-header__wrapper,
  .nav-header__wrapper.is-hompage {
    height: 164px;
  }
}

@screen lg {
  .nav-menu--product {
    width: 30rem;
  }

  .nav-menu--stage2 {
    width: 34.375rem;
  }

  .nav-menu--stage3 {
    width: 50rem;
  }

  .nav-menu--brand {
    width: 58.75rem;
  }

  .basket-aside .basket-container .basket__empty p {
    @apply leading-8 text-20px;
  }

  .basket-aside .basket__heading svg {
    width: 1.625rem;
    height: 1.5625rem;
    @apply mr-4.5;
  }

  .basket-aside .basket__heading p {
    font-size: 1.75rem;
    line-height: 2.6875rem;
  }

  .menu-container__nav--products .products-nav__level1 {
    padding: 0.78125rem 0 1.03125rem;
  }

  .menu-container__nav--products .products-nav__level1 .nav--products-stage2 {
    min-width: 15.625rem;
  }
}

@screen 1192px {
  .theme-id .navFooter {
    @apply flex flex-row h-navFooter-id mb-15 py-0 border-t-0;
  }

  .theme-ed .navFooter {
    @apply flex flex-row h-navFooter-ed mb-15 py-0 border-t-0;
  }

  .nav-menu--brand .menu--brands {
    padding: 1.875rem 0;
  }
}

@screen xl {
  .nav-menu {
    width: 30rem;
  }

  .nav-menu--stage2 {
    width: 50rem;
  }

  .nav-menu--stage3 {
    width: 68.75rem;
  }

  .nav-menu--brand {
    width: 58.75rem;
  }

  .nav-menu--alt {
    width: 30rem;
    @apply py-9 pr-12/25 pl-45px;
  }

  .menu--head {
    @apply pt-7/50 pb-0;
  }

  .head__btn:hover {
    @apply rounded-lg;
  }

  .head__btn--span {
    @apply h-9;
  }

  .head__btn--svg {
    @apply mr-2;
  }

  .head--logo {
    @apply ml-12;
  }

  .head--logo__img {
    @apply w-32 h-30px;
  }

  .head__btn--back {
    top: 7.8125rem;
    @apply bg-transparent;
  }

  .menu-container__nav {
    @apply pt-45px pr-20 pb-0 pl-40;
  }

  .menu-container__nav--hidden {
    @apply pt-0 pr-0 pl-0 pb-0;
  }

  .menu-container__nav--products {
    max-width: 29.6875rem;
    margin-top: 3.3125rem;
    @apply py-0 pr-12/25 pl-40;
  }

  .menu-container__nav--products .products-nav__level1 {
    padding: 0.6875rem 0 1.03125rem;
  }

  .menu-container__nav--brands {
    margin-top: 3.3125rem;
    @apply py-0 pr-20 pl-40;
  }

  .menu--bottom {
    @apply pt-30px pr-20 pb-0 pl-40;
  }

  .search-box__results {
    max-width: 41.25rem;
    @apply m-auto;
  }

  .menu-container__nav--products .products-nav__level1 .nav--products-stage2 {
    left: 22.8125rem;
    padding: 0 2.5rem 0 0;
    max-width: 17.3125rem;
  }

  .theme-id #ReactHeaderQty .header-basket__qty {
    right: 0;
  }

  .theme-ed #ReactHeaderQty .header-basket__qty {
    right: 0;
  }

  .nav-header__wrapper,
  .nav-header__wrapper.is-hompage {
    height: 232px;
  }
}

/* Next Day Delivery Timer Countdown */
.cv-hide {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.countdown-wrapper--id {
  background: #faead4;
  border: 1px solid #e49426;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-wrapper--ed {
  background: #f3f6cc;
  border: 1px solid #a2ae32;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 79.9375rem) {
  .countdown-wrapper:hover .countdown-delivery__tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
.countdown-delivery__banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 13px;
  position: relative;
}
.countdown-delivery__banner-text {
  font-size: 16px;
  line-height: 18px;
  color: #242424;
  margin: 0 10px;
  margin-top: -1px;
  position: relative;
  margin-right: 0;
}
.countdown-delivery__banner-text span {
  display: inline-block;
  width: 67px;
}
.countdown-delivery__banner--id svg {
  width: 20px;
  height: 20px;
  color: #e49426;
}
.countdown-delivery__banner--ed svg {
  width: 20px;
  height: 20px;
  color: #a2ae32;
}
.countdown-delivery__banner .delivery__icon {
  width: 30px;
}
@media only screen and (max-width: 1278px) {
  .countdown-delivery__banner {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .countdown-delivery__banner {
    padding: 20px 8px;
  }
  .countdown-delivery__banner svg {
    width: 16px;
    height: 16px;
  }
  .countdown-delivery__banner .delivery__icon {
    width: 20px;
  }
  .countdown-delivery__banner-text {
    font-size: 12px;
  }
  .countdown-delivery__banner-text span {
    display: inline-block;
    width: 52px;
  }
}
.countdown-delivery__tooltip {
  position: absolute;
  width: 287px;
  height: 154px;
  right: 0;
  top: calc(100% + 10px);
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
  transform: translateY(-50px);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}
.countdown-delivery__tooltip-top {
  display: flex;
  align-items: center;
  color: #242424;
  margin-bottom: 8px;
}
.countdown-delivery__tooltip-top p {
  margin: 0 10px;
}
.countdown-delivery__tooltip-top svg {
  color: #242424;
}
.countdown-delivery__tooltip-close {
  margin-left: auto;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 9;
}
.countdown-delivery__tooltip-close svg {
  width: 12px;
  height: 12px;
}
.countdown-delivery__tooltip-text {
  font-size: 14px;
  line-height: 21px;
  color: #242424;
}
@media only screen and (min-width: 79.9375rem) {
  .countdown-delivery__tooltip {
    left: calc(100% - 43px);
    top: calc(100% - 10px);
  }
  .countdown-delivery__tooltip-close {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .countdown-delivery__tooltip.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1278px) {
  .countdown-delivery__tooltip {
    right: 11.5%;
    left: inherit;
  }
}
@media only screen and (max-width: 575px) {
  .countdown-delivery__tooltip {
    right: inherit;
    left: calc(50% - 144px);
  }
}
.basket-aside .countdown-wrapper--id {
  background: #000000;
  border: 1px solid #e49426;
  margin-top: 24px;
  margin-bottom: 0;
}
.basket-aside .countdown-wrapper--ed {
  background: #000000;
  border: 1px solid #a2ae32;
  margin-top: 24px;
  margin-bottom: 0;
}
@media only screen and (min-width: 79.9375rem) {
  .basket-aside .countdown-wrapper {
    max-width: none;
    width: 100%;
  }
}
.basket-aside .countdown-delivery__banner {
  width: 100%;
  padding: 20px 10px;
}
.basket-aside .countdown-delivery__banner-text {
  color: #fff;
  font-size: 14px;
}
@media only screen and (min-width: 1280px) {
  .basket-aside .countdown-delivery__banner {
    justify-content: space-between;
  }
  .basket-aside .countdown-delivery__banner-text {
    margin: 0;
  }
  .basket-aside .countdown-delivery__banner-text span {
    width: 58px;
  }
}
@media only screen and (max-width: 575px) {
  .basket-aside .countdown-delivery__banner-text {
    font-size: 12px;
  }
}
.basket-aside .countdown-delivery__tooltip {
  left: inherit !important;
  top: calc(100% + 10px);
  right: 0 !important;
}

/* Scrollable Nav CRO */
.inserted-cro-link__content {
  color: #000;
  height: 67vh;
  min-height: 417px !important;
  overflow-y: scroll;
  padding-left: 10px;
  margin-left: -5px;
  position: relative;
}
.cro-main__link .cro-link .cro-link__content {
  height: auto !important;
}
.inserted-link-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #fff;
  padding-left: 25px;
  margin-bottom: 5px;
  border-bottom: 2px solid rgba(230, 230, 230, 0.5);
  padding-bottom: 5px;
}
.inserted-link-container a.font-bold:first-of-type {
  margin-top: 10px !important;
}
.inserted-link-container a {
  margin-bottom: 4px;
  font-size: 14px !important;
  line-height: 20px !important;
  width: max-content;
}
.inserted-cro-link__content .nav-header__categories {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.inserted-title_cont p {
  background-color: #222323;
  color: #ededed;
  margin: 15px 15px 0px 15px;
  padding: 15px 20px;
  font-size: 14px;
  border-radius: 5px !important;
}
.inserted-cro-link__content a.font-bold {
  padding-left: 28px !important;
  margin-top: 5px !important;
  margin-left: -2px !important;
  font-size: 14px;
  padding-top: 12px !important;
  border-top-width: 0px !important;
  width: max-content !important;
}
.inserted-title span {
  margin-left: 11px;
}
.inserted-title--id span {
  color: #efa847;
}
.inserted-title--ed span {
  color: #c7d402;
}
.inserted-title > span:hover {
  text-decoration: underline;
}
.inserted-title span svg {
  display: inline !important;
  width: 23px;
  height: 17px;
}
.inserted-cro-link__content .font-bold svg {
  width: 20px;
  margin-left: 10px;
}
.inserted-close-container {
  background: #fff;
  color: #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.inserted-close-container p {
  position: absolute;
  left: 20px;
  top: 81px;
  color: #fff;
}
.transparent-cont {
  position: absolute;
  z-index: -20;
  right: -71px;
  top: -15px;
  display: none;
  justify-content: center;
  width: 80px;
  height: 190px;
  align-items: start;
  padding-top: 50px;
}
header div.flex.w-full.absolute {
  width: 95% !important;
}
header div.flex.w-full.absolute:hover .transparent-cont {
  display: flex;
}
.flex.flex-row.bg-white.left-0.absolute.w-full {
  height: 67vh !important;
}
.inserted-title_cont {
  background: white;
  position: sticky;
  top: 0px;
  width: 100%;
  padding: 5px 0 0 0;
}
.inner-close-container {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-hidden {
  display: none !important;
}
.inserted-link-container .border-t-2 {
  color: #eeeeee !important;
}
.width-control {
  white-space: normal !important;
  max-width: 180px;
  padding-top: 23px;
  margin-bottom: 0px !important;
}
.width-control-parent {
  margin-top: -10px;
}

@media (min-width: 1920px) {
  .inserted-link-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
