/* SWITCH MIXIN */
.m--category-tiles .switch.switch--default > .switch-toggle {
  height: 34px;
  width: 55.25px;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 17px;
  will-change: background;
  -webkit-transition: background 0.2s 0.1 0.5s ease-out;
  transition: background 0.2s 0.1 0.5s ease-out;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #ffffff !important;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--on::before {
  background: #173090 !important;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--on::after {
  -webkit-transform: translateX(23.25px);
  transform: translateX(23.25px);
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}

.m--category-tiles .switch.switch--default > .switch-toggle.switch-toggle--off::after {
  -webkit-transform: translateX(2px);
  transform: translateX(2px);
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle {
  height: 20px;
  width: 32.5px;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  content: '';
  left: 0;
  position: absolute !important;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 10px;
  will-change: background;
  -webkit-transition: background 0.2s 0.1 0.5s ease-out;
  transition: background 0.2s 0.1 0.5s ease-out;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff !important;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
  background: gray !important;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
  -webkit-transform: translateX(14.5px);
  transform: translateX(14.5px);
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
  background: #cccccc !important;
}

.m--category-tiles .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
  -webkit-transform: translateX(2px);
  transform: translateX(2px);
}

@-webkit-keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.m--category-tiles .m--btn {
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 40px;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 10px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.m--category-tiles .m--btn [data-prefix='fas'].leftOf {
  margin-right: 8px;
}

.m--category-tiles .m--btn [data-prefix='fas'].rightOf {
  margin-left: 8px;
}

.m--category-tiles .m--btn:hover {
  text-decoration: none;
}

.m--category-tiles .m--btn-half {
  width: 48% !important;
}

.m--category-tiles .m--btn-fl {
  float: left !important;
}

.m--category-tiles .m--btn-fr {
  float: right !important;
}

.m--category-tiles .m--btn-square {
  border-radius: 0;
  border: 0;
}

.m--category-tiles .m--btn-rounded {
  border-radius: 3px;
  border: 0;
}

.m--category-tiles .m--btn-pill {
  border-radius: 100px;
  border: 0;
}

.m--category-tiles .m--btn-bordered {
  border-radius: 3px;
  border: 2px solid #152f90;
  color: #152f90;
  background-color: transparent !important;
}

.m--category-tiles .m--btn-bordered.m--btn-gray {
  border-color: #9b9b9b;
  color: #9b9b9b;
}

.m--category-tiles .m--btn-bordered.m--btn-dark {
  border-color: #222222;
  color: #222222;
}

.m--category-tiles .m--btn-blue {
  background-color: #152f90;
  color: #ffffff;
}

.m--category-tiles .m--btn-blue:hover {
  background-color: #0f2064;
  color: #ffffff;
}

.m--category-tiles .m--btn-red {
  background-color: #d32f2f;
  color: #ffffff;
}

.m--category-tiles .m--btn-red:hover {
  background-color: #aa0000;
  color: #ffffff;
}

.m--category-tiles .m--btn-green {
  background-color: #368200;
  color: #ffffff;
}

.m--category-tiles .m--btn-green:hover {
  background-color: #265a00;
  color: #ffffff;
}

.m--category-tiles .m--btn-gray {
  background-color: #e6e6e6;
  color: #222222;
}

.m--category-tiles .m--btn-gray:hover {
  background-color: #d1d1d1;
}

.m--category-tiles .m--btn-dark {
  background-color: #222222;
  color: #ffffff;
}

.m--category-tiles .m--btn-dark:hover {
  background-color: #000000;
}

.m--category-tiles .m--btn-xs {
  height: 24px;
}

.m--category-tiles .m--btn-sm {
  height: 40px;
}

.m--category-tiles .m--btn-md {
  height: 57px;
}

.m--category-tiles .m--btn-lg {
  height: 80px;
}

@media (max-width: 991px) {
  .m--category-tiles .m--btn-half {
    width: 48%;
  }
  .m--category-tiles .m--btn-half:first-child {
    margin-right: 4%;
  }
}

@media (min-width: 768px) {
  .m--category-tiles .m--btn {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .m--category-tiles .m--btn {
    width: auto;
    padding: 0px 20px;
    font-size: 14px;
  }
}

.m--category-tiles .form-verification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  border-radius: 5px;
  padding: 0;
  margin: 10px auto 0;
}

.m--category-tiles .form-verification--icon-block {
  width: 15%;
  text-align: center;
}

.m--category-tiles .form-verification--icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px auto;
  font-size: 22px;
  color: #ffffff;
}

.m--category-tiles .form-verification--text-block {
  width: 85%;
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  padding: 5px 20px;
  margin: 7.5px 0;
  border-left: 1px solid #ffffff;
}

.m--category-tiles .form-verification--invalid {
  background-color: #d32f2f;
}

.m--category-tiles .form-verification--invalid .form-verification--icon::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00d';
}

.m--category-tiles .form-verification--valid {
  background-color: #368200;
}

.m--category-tiles .form-verification--info {
  background-color: #152f90;
}

.m--category-tiles .form-verification--warning {
  background-color: #ffc324;
}

.m--category-tiles .form-verification--warning .form-verification--icon {
  color: #222222;
}

.m--category-tiles .form-verification--warning .form-verification--text-block {
  color: #222222;
  border-left: 1px solid #222222;
}

.m--category-tiles .form-verification--hide {
  display: none;
}

.m--category-tiles .form-verification--invalid-email-link {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
}

.m--category-tiles .form-verification--invalid-email-link:hover {
  font-size: inherit;
  color: inherit;
}

.m--category-tiles .cus-modal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
  padding: 30px;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  width: 80%;
  max-width: 620px;
}

.m--category-tiles .cus-modal-close {
  position: absolute;
  background: #152f90;
  color: #fff;
  border: none;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  right: 6%;
  top: 10%;
}

.m--category-tiles .cus-modal-close:hover {
  background: #0f2064;
}

.m--category-tiles .cus-modal-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 10060000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: inherit;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.m--category-tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
}

.m--category-tiles .m--category-tile {
  margin-bottom: 25px;
}

.m--category-tiles .m--category-tile-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
}

.m--category-tiles .m--category-tile-link:hover {
  @apply underline;
}

.m--category-tiles .m--category-tile-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 10px;
}

.m--category-tiles .m--category-tile-img {
  width: 100%;
  max-width: 200px;
}

.m--category-tiles .m--category-tile-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: auto;
  text-align: center;
  background-color: #e6e6e6;
  padding: 0 20px;
  font-size: 12px;
  color: #333333;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .m--category-tiles .m--category-tile {
    width: 48%;
  }
  .m--category-tiles .m--category-tile:not(:nth-of-type(2n)) {
    margin-right: 4%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .m--category-tiles .m--category-tile {
    width: 32%;
  }
  .m--category-tiles .m--category-tile:not(:nth-of-type(3n)) {
    margin-right: 2%;
  }
  .m--category-tiles .m--category-tile-name {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .m--category-tiles .m--category-tile-img-container {
    padding: 20px;
  }
  .m--category-tiles .m--category-tile-name {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .m--category-tiles .m--category-tile {
    width: 24%;
  }
  .m--category-tiles .m--category-tile:not(:nth-of-type(4n)) {
    margin-right: 1.33333%;
  }
}
/*# sourceMappingURL=index.css.map */

.cv-4-17__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  margin-bottom: 70px;
  margin-top: 30px;
}
.cv-4-17__grid .m--category-tile {
  margin: 0 !important;
  width: 100%;
}
.cv-4-17__grid .m--category-tile-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f2f2f2;
  border-radius: 6px;
  padding: 24px;
  transition: all 0.2s;
}
@media (pointer: fine) {
  .cv-4-17__grid .m--category-tile-link:hover {
    background: #cccccc;
  }
}
@media (pointer: coarse) {
  .cv-4-17__grid .m--category-tile-link:active {
    background: #cccccc;
  }
}
.cv-4-17__grid .m--category-tile-img-container {
  --size: 120px;
  max-width: var(--size);
  max-height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  padding: 0;
  overflow: hidden;
  background: #ffffff;
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  padding: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cv-4-17__grid .m--category-tile-img-container img {
  --size: 96px;
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);
  object-fit: cover;
  display: block !important;
}
.cv-4-17__grid .m--category-tile-img-container img.hidden {
  display: none !important;
}
.cv-4-17__grid .m--category-tile-name {
  margin: 0 !important;
  background: none;
  font-size: 22px;
  line-height: 150%;
  flex-shrink: 1;
  text-align: left;
  font-weight: 590;
}
@media only screen and (max-width: 1500px) {
  .cv-4-17__grid {
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, minmax(350px, 1fr));
  }
  .cv-4-17__grid .m--category-tile-link {
    padding: 16px;
  }
  .cv-4-17__grid .m--category-tile-name {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1200px) {
  .cv-4-17__grid {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media only screen and (max-width: 860px) {
  .cv-4-17__grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
  .cv-4-17__grid .m--category-tile-link {
    padding: 12px;
  }
  .cv-4-17__grid .m--category-tile-img-container {
    --size: 100px;
  }
  .cv-4-17__grid .m--category-tile-img-container img {
    --size: 80px;
  }
  .cv-4-17__grid .m--category-tile-name {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .cv-4-17__grid {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media only screen and (max-width: 668px) {
  .cv-4-17__grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
@media only screen and (max-width: 460px) {
  .cv-4-17__grid .m--category-tile-link {
    padding: 8px;
  }
  .cv-4-17__grid .m--category-tile-img-container {
    --size: 60px;
  }
  .cv-4-17__grid .m--category-tile-img-container img {
    --size: 48px;
  }
  .cv-4-17__grid .m--category-tile-name {
    font-size: 14px;
  }
}

/*# sourceMappingURL=variation.css.map */
