.our-brands {
  @apply overflow-hidden;
}

.our-brands .our-brands__header {
  @apply w-full my-20px mx-0 text-center;
}

.our-brands .our-brands__title {
  @apply text-30px font-bold;
}

.our-brands .our-brands__title--brand {
  line-height: 37px;
  text-shadow: #000 1px 0 15px;  
  @apply tracking-normal mb-15px;
}

.our-brands .our-brands__title--primary {
  letter-spacing: -2px;
  font-size: 38px;
}

.theme-id .our-brands .our-brands__title--secondary {
  letter-spacing: -0.95px;
  line-height: 32px;
  @apply text-20px font-light text-orange;
}

.theme-ed .our-brands .our-brands__title--secondary {
  letter-spacing: -0.95px;
  line-height: 32px;
  @apply text-20px font-light text-green;
}

.our-brands .our-brands__text {
  text-shadow: #000 1px 0 15px;    
  @apply max-w-full text-18px tracking-normal leading-6 text-center;
}

.our-brands .our-brands__list {
  @apply flex flex-wrap justify-evenly w-full;
}

.our-brands .our-brands__brand {
  max-height: 500px;
  @apply w-full mb-10px text-white;
}

.our-brands .our-brands__brand--inner {
  @apply bg-cover bg-no-repeat w-full h-full rounded-5px;
}

.our-brands__brand-content {
  @apply h-full flex flex-col justify-between items-center py-20px px-40px text-center;
}

.our-brands .our-brands__logo img {
  width: 120px;
  height: 120px;
  @apply rounded-5px mb-20px;
}

@screen md {
  .our-brands .our-brands__header {
    @apply my-40px;
  }

  .our-brands .our-brands__title--primary {
    font-size: 55px;
    line-height: 67px;
  }

  .our-brands .our-brands__title--secondary {
    @apply text-26px;
  }

  .our-brands .our-brands__brand {
    width: 48%;
  }

  .theme-id .our-brands .our-brands__title--secondary {
    @apply text-26px;
  }
  
  .theme-ed .our-brands .our-brands__title--secondary {
    @apply text-26px;
  }
}

@screen lg {
  .our-brands .our-brands__brand {
    width: 31%;
    @apply mb-20px;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  /* IE10+ specific styles go here */ 
  .our-brands .our-brands__list {
    justify-content: space-around !important; 
  }
}