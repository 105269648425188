.m--foundit--pdp .fi-slot--plp-main {
  @apply m-0;
}

.m--foundit--pdp .fi-plp-main__text {
  @apply text-black-lighter;
}

.m--foundit--pdp .fi-plp-main__image-crop {
  @apply p-10px bg-white;
}

.theme-id .m--foundit--pdp .fi-plp-main__image-crop:hover {
  @apply border-orange;
}

.theme-ed .m--foundit--pdp .fi-plp-main__image-crop:hover {
  @apply border-green;
}

.theme-id .m--foundit--pdp .fi-arrow {
  border-radius: 20px;
  @apply h-40px w-40px z-1 bg-orange;
}

.theme-ed .m--foundit--pdp .fi-arrow {
  border-radius: 20px;
  @apply h-40px w-40px z-1 bg-green;
}

.m--foundit--pdp .fi-arrow:before {
  @apply h-3 w-3 border-black;
}

.m--foundit--pdp .fi-arrow-left {
  transform: translateY(-50%) !important;
  @apply left-0 top-1/2 hidden;
}

.m--foundit--pdp .fi-arrow-left:before {
  margin: 13.5px;
}

.m--foundit--pdp .fi-arrow-right {
  transform: translateY(-50%) !important;
  @apply right-0 top-1/2 hidden;
}

.m--foundit--pdp .fi-arrow-right:before {
  margin: 13.5px 13px;
}

.m--foundit--pdp .fi-arrow-active {
  @apply block;
}
