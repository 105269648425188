.carousel {
@apply overflow-hidden mt-30px relative w-full;
}

.carousel__viewport {
  @apply w-full overflow-visible;
}

.carousel__container {
  @apply flex select-none w-full;
  -webkit-tap-highlight-color: transparent;
}

.carousel__slide {
  @apply relative;
}

.carousel--loader {
  @apply flex select-none w-full overflow-hidden;
  -webkit-tap-highlight-color: transparent;
}

.carousel__button {
  touch-action: manipulation;
  z-index: 3;
  @apply outline-none cursor-pointer bg-transparent absolute top-1/2 border-0 w-30px h-30px justify-center items-center fill-black-lighter p-0;
}

.carousel-btn__svg {
  border-radius: 50%;
  @apply w-full h-full p-2.5;
}

.carousel__button--left {
  touch-action: manipulation;
  left: 0.3125rem;
  z-index: 3;
  @apply outline-none cursor-pointer bg-transparent absolute top-1/2 border-0 w-30px h-30px justify-center items-center fill-black-lighter p-0;
}

.carousel__button--right {
  touch-action: manipulation;
  right: 0.3125rem;
  z-index: 3;
  @apply outline-none cursor-pointer bg-transparent absolute top-1/2 border-0 w-30px h-30px justify-center items-center fill-black-lighter p-0;
}