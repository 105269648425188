.home-products {
  padding: 4.0625rem 0 1.875rem;
}

.home-products .home-products__inner .home-products__head {
  margin-bottom: 2.125rem;
}

.home-products .home-products__inner .home-products__head .head__inner {
  -webkit-box-pack: justify;
  @apply flex items-center justify-between;
}

.home-products .home-products__inner .home-products__head .head__inner h2 {
  flex: 1 1;
  line-height: 1.17;
  @apply m-0;
}

.home-products .home-products__inner .home-products__head .head__inner .head__actions {
  margin-top: 0.1875rem;
}

.home-products .home-products__inner .home-products__body .body__actions {
  @apply mt-6;
}

.home-products .home-products__inner .home-products__body .carousel {
  @apply mt-0;
}

.home-products .home-products__inner .home-products__body .carousel .carousel__slide:not(:first-child):not(:last-child) {
  padding: 0 0.625rem;
}

.home-products .home-products__inner .home-products__body .carousel .carousel__slide:first-child {
  padding-right: 0.625rem;;
}

.home-products .home-products__inner .home-products__body .carousel .carousel__slide:last-child {
  padding-left: 0.625rem;
}

@screen sm {
  .home-products .home-products__inner .home-products__head {
    @apply mb-7/50;
  }

  .home-products .home-products__inner .home-products__body .carousel .carousel__slide:not(:first-child):not(:last-child) {
    padding: 0 0.75rem;
  }
  
  .home-products .home-products__inner .home-products__body .carousel .carousel__slide:first-child {
    padding-right: 0.75rem;
  }
  
  .home-products .home-products__inner .home-products__body .carousel .carousel__slide:last-child {
    padding-left: 0.75rem;
  }
}

@screen md {
  .home-products {
    padding: 4.4375rem 0 2.0625rem;
  }
}

@screen lg {
  .home-products .home-products__inner .home-products__head .head__inner h2 {
    margin: 0 0.625rem 0 0;
  }
}

@screen xl {
  .home-products {
    padding: 8rem 0 5.4375rem;
  }

  .home-products .home-products__inner .home-products__head {
    margin-bottom: 2.625rem;
  }

  .home-products .home-products__inner .home-products__head .head__inner h2 {
    flex: none;
  }
}

@screen xxl {
  .home-products .home-products__inner .home-products__head {
    margin-bottom: 1.8125rem;
  }
}