.home-testimonials {
  padding: 2.4375rem 0 2.5rem;
  @apply bg-grey-lightestx4 overflow-hidden;
}

.home-testimonials .home-testimonials__inner {
  @apply block;
}

.home-testimonials .home-testimonials__inner .home-testimonials__head {
  margin: 0 0 1.8125rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner .head--image {
  max-width: none;
  margin-bottom: 1.375rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner h2 {
  margin-bottom: 0.6875rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner p {
  @apply mb-4 text-16px leading-5;
}

.home-testimonials .home-testimonials__inner .home-testimonials__head .head--actions {
  @apply mt-5;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body {
  padding-top: 0.125rem;
  @apply pl-0 overflow-hidden;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .carousel {
  @apply mt-0;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:not(:first-child):not(:last-child) {
  padding: 0 0.625rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:first-child {
  padding-right:  0.625rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:last-child {
  padding-left:  0.625rem;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial {
  max-width: 100%;
  min-width: 365px;
  width: 30vw;
  @apply h-full;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner {
  border: 0.09375rem solid #eee;
  padding: 1rem 1.125rem 1.4375rem;
  @apply bg-white rounded-10px overflow-hidden h-full flex flex-col justify-between;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content {
  flex: auto;
  @apply mb-4;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content h3 {
  @apply mb-2;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content p {
  @apply mb-4 text-16px leading-5;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating {
  @apply block pr-0;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating .testimonial__rating--list .list__rating {
  @apply flex flex-wrap items-center;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating .testimonial__rating--list .list__rating svg {
  @apply text-20px h-20px w-20px;
}

.home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating p {
  margin-top: 0.5625rem;
  @apply font-medium text-grey-light text-16px leading-5;
}

@screen sm {
  .home-testimonials .home-testimonials__inner .home-testimonials__head {
    max-width: 26.875rem;
    margin: 0 0 2.0625rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:not(:first-child):not(:last-child) {
    padding: 0 0.75rem;
  }
  
  .home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:first-child {
    padding-right:  0.75rem;
  }
  
  .home-testimonials .home-testimonials__inner .home-testimonials__body .carousel__slide:last-child {
    padding-left:  0.75rem;
  }
}

@screen md {
  .home-testimonials .home-testimonials__inner {
    @apply flex;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head {
    max-width: 17.5rem;
    flex: 0 0 17.5rem;
    margin: 0 1.875rem 0 0;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner .head--image {
    max-width: 6.125rem;
    margin-bottom: 1rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner h2 {
    margin-bottom: 0.875rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner {
    padding: 1.3125rem 1.5rem 1.4375rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content {
    margin-bottom: 1.3125rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content h3 {
    margin-bottom: 0.625rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating {
    @apply flex flex-wrap justify-between items-center;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__rating p {
    @apply mt-0;
  }
}

@screen xl {
  .home-testimonials {
    padding: 5.25rem 0 5.0625rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head {
    max-width: 20.125rem;
    flex: 0 0 20.125rem;
    margin: 0 3.75rem 0 0;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head .head--actions {
    margin-top: 1.5625rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner .head--image {
    margin-bottom: 1.1875rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__head .head--inner h2 {
    margin-bottom: 1.5rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content {
    margin-bottom: 1.875rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial .testimonial__inner .testimonial__content h3 {
    margin-bottom: 0.875rem;
  }

  .home-testimonials .home-testimonials__inner .home-testimonials__body .testimonial {
    max-width: 400px;
    min-width: none;
  }
}