#ReactJigsawPage {
  @apply overflow-x-hidden;
}

#ReactJigsawPage .jigsaw__embla {
  @apply relative w-full max-w-full;
}

#ReactJigsawPage .jigsaw__embla__viewport {
  @apply w-full overflow-visible;
}

#ReactJigsawPage .jigsaw__embla__container {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  @apply flex;
}

#ReactJigsawPage .jigsaw__embla__slide {
  @apply relative;
}

@media (min-width: 64em) {
  #ReactJigsawPage .category .jigsaw__embla__container {
    margin: 0 -0.75rem;
  }
}

@media (min-width: 64em) {
  #ReactJigsawPage .category .jigsaw__embla__slide {
    flex: 1 0 20%;
    padding: 0 0.75rem;
  }
}
@media all and (min-width: 64em) and (-ms-high-contrast: none),
  (min-width: 64em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .category .jigsaw__embla__slide {
    /* IE10+ CSS */
    flex: 1 0 0%;
  }
}

@media (min-width: 64em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .category .jigsaw__embla__slide {
      /* Edge 12+ CSS */
      flex: 1 0 0%;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .category .jigsaw__embla__slide {
      /* Edge 16+ CSS */
      flex: 1 0 0%;
    }
  }
}

#ReactJigsawPage .jigsaw__embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 5;
  top: 40%;
  transform: translateY(-40%);
  border: 0;
  width: 1.875rem;
  height: 1.875rem;
  justify-content: center;
  align-items: center;
  fill: black;
  padding: 0;
}

#ReactJigsawPage .jigsaw__embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

#ReactJigsawPage .jigsaw__embla__button__svg {
  width: 100%;
  height: 100%;
  background: #c7d303;
  border-radius: 50%;
  padding: 0.625rem;
  box-shadow: 0px 0px 8px 3px #aaaba2d4;
}

#ReactJigsawPage .jigsaw__embla__button--prev {
  left: 0.3125rem;
}

#ReactJigsawPage .jigsaw__embla__button--next {
  right: 0.3125rem;
}

@media (min-width: 0em) {
  #ReactJigsawPage .about {
    padding-top: 3.75rem;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .about {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .about {
    padding-top: 6.25rem;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .about__details {
    max-width: 50%;
  }
}

@media (min-width: 0em) {
  #ReactJigsawPage .about__image {
    padding-top: 1.5625rem;
    width: 100%;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .about__image {
    padding-top: 0;
    width: 45%;
    height: 16.9375rem;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .about__image {
    width: 35.5625rem;
    height: 17.6875rem;
    margin-left: 3.125rem;
  }
}

#ReactJigsawPage .about__details__title {
  font-weight: 700;
}
@media (min-width: 0em) {
  #ReactJigsawPage .about__details__title {
    padding-bottom: 0.875rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .about__details__title {
    padding-bottom: 1.25rem;
  }
}

@media (min-width: 0em) {
  #ReactJigsawPage .about__details__description {
    font-size: calc((13px) + 1vw);
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .about__details__description {
    font-size: calc((7px) + 1vw);
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .about__details__description {
    font-size: 1.125rem;
  }
}

#ReactJigsawPage .banner {
  position: relative;
  display: flex;
}

#ReactJigsawPage .banner__cover {
  padding: 1.25rem;
  width: 100%;
  background: #222323;
}

#ReactJigsawPage .banner__content--absolute {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ReactJigsawPage .banner__cover__image {
  background-image: linear-gradient(to right, #21232494, #222324),
    url('https://res.cloudinary.com/manutantraders/image/upload/v1615568285/ironmongery/Jigsaw_Images%20%EF%A7%A9%20/Jigsaw_Nikko/Images/Electrcian.png');
  background-size: cover;
}

@media (min-width: 0em) {
  #ReactJigsawPage .banner__content {
    height: 25rem;
    width: 100%;
    background-size: cover;
    position: relative;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .banner__content {
    background: transparent;
    width: 50%;
    height: 18.375rem;
  }

  #ReactJigsawPage .banner__cover {
    width: 50%;
    background: #222323;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .banner__content {
    height: 31rem;
  }
}

#ReactJigsawPage .banner__image {
  width: 50%;
}
@media (min-width: 48em) {
  .banner__image {
    height: 22.375rem;
  }
}
@media (min-width: 74.5em) {
  .banner__image {
    height: 27.625rem;
  }
}

#ReactJigsawPage .banner__content__image {
  width: 90%;
  margin-top: 0.75rem;
}
@media (min-width: 48em) {
  #ReactJigsawPage .banner__content__image {
    width: 21rem;
    margin-top: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .banner__content__image {
    width: 28.1875rem;
  }
}

#ReactJigsawPage .banner__content__title {
  color: #fff;
  font-weight: 700;
}
@media (min-width: 0em) {
  #ReactJigsawPage .banner__content__title {
    font-size: calc((23px) + 5.5vw);
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .banner__content__title {
    font-size: calc((30px) + 1vw);
    padding-top: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .banner__content__title {
    padding-top: 2.5rem;
    font-size: 3.4375rem;
  }
}

#ReactJigsawPage .banner__content__description {
  color: #fff;
}
@media (min-width: 0em) {
  #ReactJigsawPage .banner__content__description {
    font-size: calc((5px) + 2.5vw);
    margin-top: 0.625rem;
    max-width: unset;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .banner__content__description {
    font-size: calc((7px) + 1vw);
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .banner__content__description {
    font-size: 1.125rem;
    margin-top: 1.875rem;
    max-width: 75%;
  }
}

#ReactJigsawPage .banner__content--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

#ReactJigsawPage .brands {
  background: #e8ed9a;
}
@media (min-width: 0em) {
  #ReactJigsawPage .brands {
    margin-top: 3.75rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .brands {
    margin-top: 7.5rem;
  }
}

#ReactJigsawPage .brands__container {
  padding: 2.1875rem 0;
}
@media (min-width: 48em) {
  #ReactJigsawPage .brands__container {
    display: flex;
    align-items: center;
    padding: 6.875rem 0;
  }
}

#ReactJigsawPage .brands__header__title {
  font-weight: 700;
}

@media (min-width: 0em) {
  #ReactJigsawPage .brands__lists__gallery {
    overflow-x: scroll;
    display: flex;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .brands__lists__gallery {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin-left: 1.5625rem;
  }
}
@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery {
    /* IE10+ CSS */
    display: -ms-grid;
    align-self: center;
    justify-self: center;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery {
      /* Edge 12+ CSS */
      display: -ms-grid;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery {
      /* Edge 16+ CSS */
      display: -ms-grid;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 1) {
    /* IE10+ CSS */
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 1) {
      /* Edge 12+ CSS */
      -ms-grid-column-align: center;
      -ms-grid-row-align: center;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 1) {
      /* Edge 16+ CSS */
      -ms-grid-column-align: center;
      -ms-grid-row-align: center;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 1) {
    /* IE10+ CSS */
    -ms-grid-column: 1;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 1) {
      /* Edge 12+ CSS */
      -ms-grid-column: 1;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 1) {
      /* Edge 16+ CSS */
      -ms-grid-column: 1;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 2) {
    /* IE10+ CSS */
    -ms-grid-column: 2;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 2) {
      /* Edge 12+ CSS */
      -ms-grid-column: 2;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 2) {
      /* Edge 16+ CSS */
      -ms-grid-column: 2;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 3) {
    /* IE10+ CSS */
    -ms-grid-column: 3;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 3) {
      /* Edge 12+ CSS */
      -ms-grid-column: 3;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 3) {
      /* Edge 16+ CSS */
      -ms-grid-column: 3;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 4) {
    /* IE10+ CSS */
    -ms-grid-column: 4;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 4) {
      /* Edge 12+ CSS */
      -ms-grid-column: 4;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(4n + 4) {
      /* Edge 16+ CSS */
      -ms-grid-column: 4;
    }
  }
}

@media all and (min-width: 48em) and (-ms-high-contrast: none),
  (min-width: 48em) and (-ms-high-contrast: active) {
  #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 5):nth-child(-n + 8) {
    /* IE10+ CSS */
    -ms-grid-row: 2;
  }
}

@media (min-width: 48em) {
  @supports (-ms-accelerator: true) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 5):nth-child(-n + 8) {
      /* Edge 12+ CSS */
      -ms-grid-row: 2;
    }
  }
  @supports (-ms-ime-align: auto) {
    #ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 5):nth-child(-n + 8) {
      /* Edge 16+ CSS */
      -ms-grid-row: 2;
    }
  }
}

#ReactJigsawPage .brands__lists__gallery__images:nth-child(n + 5):nth-child(-n + 8) > img {
  justify-self: center;
}

#ReactJigsawPage .brands__lists__gallery::-webkit-scrollbar {
  display: none;
}

@media (min-width: 0em) {
  #ReactJigsawPage .brands__header {
    width: 100%;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .brands__header {
    width: 33%;
  }
}

@media (min-width: 64em) {
  #ReactJigsawPage .brands__header {
    margin-right: 4.5625rem;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .brands__header {
    width: 30%;
    margin-right: 2.5rem;
  }
}

#ReactJigsawPage .brands__lists__gallery__images {
  margin: 1.25rem 1.25rem 1.25rem;
}

#ReactJigsawPage .brands__header__button {
  margin-top: 0.9375rem;
  background: #e8ed9a;
  height: 2.5rem;
  width: 8.5625rem;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #242424;
}

#ReactJigsawPage .brands__header__button:hover {
  background: #d6da8e;
}

@media (min-width: 0em) {
  #ReactJigsawPage .brands__lists {
    margin-top: 1.875rem;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .brands__lists {
    margin-top: 0;
  }
}

#ReactJigsawPage .button--redirect {
  background: #c7d303;
  height: 2.5rem;
  width: 12.25rem;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3125rem;
  color: #242424;
}

#ReactJigsawPage .button--redirect:hover {
  background: #b6c103;
}

#ReactJigsawPage .button--fullrange {
  background: #c7d303;
  height: 2.5rem;
  width: 9.75rem;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #242424;
}

#ReactJigsawPage .button--fullrange:hover {
  background: #b6c103;
}

#ReactJigsawPage .button__cards {
  background: #c7d303;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 5px #aaaba2d4;
}

#ReactJigsawPage .button__image {
  padding: 0.625rem;
  zoom: 3%;
}

#ReactJigsawPage .button--next--show {
  align-self: center;
  display: flex;
  position: sticky;
  cursor: pointer;
  visibility: visible;
  z-index: 1;
}
@media (min-width: 0em) {
  #ReactJigsawPage .button--next--show {
    right: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .button--next--show {
    right: 3.125rem;
  }
}

#ReactJigsawPage .button--previous--show {
  align-self: center;
  display: flex;
  position: sticky;
  cursor: pointer;
  visibility: visible;
  z-index: 1;
}
@media (min-width: 0em) {
  #ReactJigsawPage .button--previous--show {
    left: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .button--previous--show {
    left: 3.125rem;
  }
}

#ReactJigsawPage .button--next--hidden {
  align-self: center;
  display: flex;
  position: sticky;
  cursor: pointer;
  visibility: hidden;
  z-index: 1;
}
@media (min-width: 0em) {
  #ReactJigsawPage .button--next--hidden {
    right: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .button--next--hidden {
    right: 3.125rem;
  }
}

#ReactJigsawPage .button--previous--hidden {
  align-self: center;
  display: flex;
  position: sticky;
  cursor: pointer;
  visibility: hidden;
  z-index: 1;
  width: 0;
}
@media (min-width: 0em) {
  #ReactJigsawPage .button--previous--hidden {
    left: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .button--previous--hidden {
    left: 3.125rem;
  }
}

#ReactJigsawPage .category {
  display: flex;
}
@media (min-width: 0em) {
  #ReactJigsawPage .category {
    margin: 1.875rem 0;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .category {
    margin-top: 2.5rem;
  }
}
@media (max-width: 64em) {
  #ReactJigsawPage .category .jigsaw__embla__slide:not(:last-of-type) {
    margin: 0 1.5rem 0 0;
  }
}

#ReactJigsawPage .category::-webkit-scrollbar {
  display: none;
}

#ReactJigsawPage .category__card {
  position: relative;
  min-width: 13rem;
  max-width: 13rem;
  background: #e6e6e6;
  border-radius: 8px;
  height: 100%;
  min-height: 15.625rem;
}
@media (min-width: 48em) {
  #ReactJigsawPage .category__card {
    max-width: 16.75rem;
  }
}
@media (min-width: 64em) {
  #ReactJigsawPage .category__card {
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .category__card {
    border-radius: 10px;
    margin: 0 1.5625rem 0 0;
  }
}
@media (min-width: 90em) {
  #ReactJigsawPage .category__card {
    min-height: 17.1875rem;
  }
}
#ReactJigsawPage .category__card:hover .category__card__details {
  color: #c7d303;
}
#ReactJigsawPage .category__card:hover .category__card__details__button {
  border-color: #c7d303;
  color: #c7d303;
}

#ReactJigsawPage .category__card--variant {
  margin-right: 0;
}

#ReactJigsawPage .category__card__image {
  width: 100%;
}

#ReactJigsawPage .category__card__details {
  transition: color 200ms linear;
  padding: 1.25rem;
}

#ReactJigsawPage .category__card__details__title {
  font-size: 1.125rem;
  font-weight: 700;
}

#ReactJigsawPage .category__card__details__button {
  background: #e6e6e6;
  height: 2.5rem;
  width: 8.5625rem;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #202020;
  bottom: 0;
  position: absolute;
  padding: 8px;
  margin: 0 0 1.25rem 1.25rem;
  transition: color 200ms linear;
  transition-property: color, border-color;
}

#ReactJigsawPage .category__card__details__button:hover {
  text-decoration: none;
}

#ReactJigsawPage .closingBanner {
  position: relative;
  display: flex;
}
@media (min-width: 0em) {
  #ReactJigsawPage .closingBanner {
    margin-top: 3.75rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner {
    margin-top: 7.5rem;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(40%, #222323),
      to(rgba(31, 31, 31, 0))
    );
    background: -o-linear-gradient(left, #222323 40%, rgba(31, 31, 31, 0) 100%);
    background: linear-gradient(to right, #222323 40%, rgba(31, 31, 31, 0) 100%);
    pointer-events: none;
  }
}

#ReactJigsawPage .closingBanner__cover__image {
  background-image: linear-gradient(to right, #21232494 40%, #222324 100%),
    url('https://res.cloudinary.com/manutantraders/image/upload/v1615568286/ironmongery/Jigsaw_Images%20%EF%A7%A9%20/Jigsaw_Nikko/Images/Brocure-ED.png');
  background-size: cover;
}

#ReactJigsawPage .closingBanner__container {
  height: 17.625rem;
  width: 100%;
  background-size: cover;
  position: relative;
}
@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__container {
    height: 17.875rem;
    background: transparent;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner__container {
    height: 20.375rem;
  }
}

@media (min-width: 0em) {
  #ReactJigsawPage .closingBanner__image {
    height: 17.625rem;
    width: 50%;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__image {
    height: 17.875rem;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner__image {
    height: 20.375rem;
  }
}

@media (min-width: 36em) {
  .closingBanner__container__header__image {
    width: 15.625rem;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__container__header__image {
    width: 17.6875rem;
  }
}

#ReactJigsawPage .closingBanner__container__header__title {
  color: #fff;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media (min-width: 0em) {
  #ReactJigsawPage .closingBanner__container__header__title {
    font-size: calc((16px) + 4vw);
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__container__header__title {
    font-size: calc((2px) + 2.5vw);
    margin-bottom: 0;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner__container__header__title {
    font-size: calc((18px) + 1.25vw);
  }
}

#ReactJigsawPage .closingBanner__container__details {
  color: #fff;
}
@media (min-width: 0em) {
  #ReactJigsawPage .closingBanner__container__details {
    font-size: calc((13px) + 1vw);
    margin-top: 1.25rem;
    max-width: unset;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__container__details {
    font-size: calc((2px) + 1.5vw);
    margin-right: 0.625rem;
    width: 50%;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner__container__details {
    margin-right: 0;
    margin-top: 1.5625rem;
    font-size: 1rem;
    max-width: 75%;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .closingBanner__container__header {
    display: flex;
    align-items: flex-end;
    align-items: center;
    padding-top: 3.75rem;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .closingBanner__container__header {
    padding-top: 5rem;
  }
}

@media (min-width: 0em) {
  #ReactJigsawPage .discover {
    padding-top: 3.75rem;
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .discover {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .discover {
    padding-top: 7.5rem;
  }
}

#ReactJigsawPage .discover__title {
  font-weight: 700;
  margin-bottom: 0.9375rem;
}

#ReactJigsawPage .customerReviews__headers_image {
  object-fit: cover;
}
@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__headers_image {
    height: 2.75rem;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__headers_image {
    height: 1.5rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__headers_image {
    height: 1.875rem;
  }
}

#ReactJigsawPage .customerReviews {
  margin-top: 7.5rem;
  background: #eeeeee;
  padding: 2.8125rem 0;
}

@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__container {
    display: flex;
  }
}

#ReactJigsawPage .customerReviews__container .jigsaw__embla__slide:not(:last-of-type) {
  margin-right: 1.5625rem;
}

#ReactJigsawPage .customerReviews__headers_title {
  font-weight: 700;
}
@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__headers_title {
    margin: 0.625rem 0;
  }
}
@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__headers_title {
    margin: 0.6875rem 0;
  }
}

@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__headers_description {
    font-size: calc((13px) + 1vw);
  }
}

@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__headers_description {
    font-size: calc((9px) + 1vw);
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__headers_description {
    font-size: 1rem;
  }
}

#ReactJigsawPage .customerReviews__headers_button {
  color: #242424;
  margin-top: 0.9375rem;
  background: #eeeeee;
  height: 2.5rem;
  width: 7.375rem;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #242424;
}

#ReactJigsawPage .customerReviews__headers_button:hover {
  background: #dbdbdb;
}

#ReactJigsawPage .customerReviews__card__container {
  background: #ffffff;
  border-radius: 0.625rem;
  position: relative;
  z-index: 0;
  height: 13.125rem;
  min-width: 17.375rem;
  max-width: 31.5625rem;
}
@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__card__container {
    min-width: 19.0625rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__card__container {
    height: 14.9375rem;
    min-width: 22.3125rem;
  }
}

#ReactJigsawPage .customerReviews__headers {
  z-index: 1;
  background: #eeeeee;
}
@media (min-width: 48em) {
  .customerReviews__headers {
    width: 55%;
  }
}
@media (min-width: 74.5em) {
  .customerReviews__headers {
    width: 30%;
  }
}

#ReactJigsawPage .customerReviews__card {
  z-index: 1;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin-top: 2rem;
}
@media (min-width: 48em) {
  #ReactJigsawPage .customerReviews__card {
    margin-left: 1.5625rem;
    margin-top: 0;
    width: 65%;
  }
  #ReactJigsawPage .customerReviews__card .jigsaw__embla {
    overflow: hidden;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__card {
    margin-left: 2.1875rem;
  }
}

#ReactJigsawPage .customerReviews__card::-webkit-scrollbar {
  display: none;
}

#ReactJigsawPage .customerReviews__card__container__headers__title {
  font-weight: 700;
}
@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__card__container__headers__title {
    font-size: 1.25rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__card__container__headers__title {
    font-size: 1.5rem;
  }
}

#ReactJigsawPage .customerReviews__card__container__headers__description {
  font-size: 1rem;
}
@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__card__container__headers__description {
    margin-top: 0.625rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__card__container__headers__description {
    margin-top: 0.9375rem;
  }
}

#ReactJigsawPage .customerReviews__card__container__footer {
  font-size: 1rem;
  color: #acacac;
  position: absolute;
  width: -webkit-fill-available;
}
@media (min-width: 0em) {
  #ReactJigsawPage .customerReviews__card__container__footer {
    bottom: 0;
    padding: 0 1.5625rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .customerReviews__card__container__footer {
    padding: 0 2.1875rem;
  }
}

#ReactJigsawPage .customerReviews__card__container__headers {
  padding: 1.5625rem;
}

#ReactJigsawPage .customerReviews__card__container__headers--wide {
  padding: 1.875rem;
}

#ReactJigsawPage .customerReviews__card__container__footer__rating {
  float: left;
}

#ReactJigsawPage .customerReviews__card__container__footer__timestamp {
  float: right;
}

#ReactJigsawPage .star__rating--checked {
  color: #fba528;
}

@media (min-width: 0em) {
  #ReactJigsawPage .topProducts {
    padding-top: 3.75rem;
  }
}

@media (min-width: 74.5em) {
  #ReactJigsawPage .topProducts {
    padding-top: 7.5rem;
  }
}

#ReactJigsawPage .topProducts__title {
  color: #242424;
  font-weight: 700;
}

#ReactJigsawPage .topProducts__items {
  display: flex;
  width: 100%;
  position: relative;
  max-width: 1540px;
  overflow: visible;
}
@media (min-width: 0em) {
  #ReactJigsawPage .topProducts__items {
    padding-top: 1.875rem;
  }
}
@media (min-width: 74.5em) {
  #ReactJigsawPage .topProducts__items {
    padding-top: 2.5rem;
    overflow: hidden;
  }
}
#ReactJigsawPage .topProducts__items .jigsaw__embla__slide:not(:last-of-type) {
  margin-right: 2.1875rem;
}

#ReactJigsawPage .topProducts__items::-webkit-scrollbar {
  display: none;
}

#ReactJigsawPage .card__products {
  min-width: 11.5625rem;
  max-width: 13.125rem;
  cursor: pointer;
}

#ReactJigsawPage .card__products--link {
  transition: color 200ms linear;
}

#ReactJigsawPage .card__products--link:hover {
  text-decoration: none;
  color: #c7d303;
}
#ReactJigsawPage .card__products--link:hover .card__products__photoContainer {
  border-color: #c7d303;
}

#ReactJigsawPage .card__products__photoContainer {
  min-width: 11.5625rem;
  max-width: 11.5625rem;
  margin-bottom: 1.25rem;
  height: 11.5625rem;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 200ms linear;
}

#ReactJigsawPage .card__products__photoContainer__image {
  height: 8.4375rem;
  width: 8.4375rem;
  object-fit: cover;
}

#ReactJigsawPage .card__products__rating {
  color: #f3c586;
  margin: 1.25rem 0 0.625rem 0;
  font-size: 18px;
}

#ReactJigsawPage .card__products__rating__text {
  color: black;
}

#ReactJigsawPage .card__products__title {
  margin-bottom: 0.625rem;
  font-size: 1rem;
}

#ReactJigsawPage .card__products__priceContainer {
  display: flex;
  align-items: center;
}

#ReactJigsawPage .card__products__priceContainer__price {
  font-size: 1.25rem;
  font-weight: 700;
}

#ReactJigsawPage .card__products__priceContainer__VATContainer {
  margin-left: 0.3125rem;
}

#ReactJigsawPage .card__products__priceContainer__VATContainer__VAT {
  font-size: 0.875rem;
  font-weight: normal;
}

.customerReviews__card__container .testimonial .testimonial__inner {
  border: 0.09375rem solid #eee;
  padding: 1rem 1.125rem 1.4375rem;
  @apply bg-white rounded-10px overflow-hidden h-full flex flex-col justify-between;
}

.customerReviews__card__container .testimonial .testimonial__inner .testimonial__content {
  flex: auto;
  @apply mb-4;
}

.customerReviews__card__container .testimonial .testimonial__inner .testimonial__content h3 {
  @apply mb-2;
}

.customerReviews__card__container .testimonial .testimonial__inner .testimonial__content p {
  @apply mb-4 text-16px leading-5;
}

.customerReviews__card__container .testimonial .testimonial__inner .testimonial__rating {
  @apply block pr-0;
}

.customerReviews__card__container
  .testimonial
  .testimonial__inner
  .testimonial__rating
  .testimonial__rating--list
  .list__rating {
  @apply flex flex-wrap items-center;
}

.customerReviews__card__container
  .testimonial
  .testimonial__inner
  .testimonial__rating
  .testimonial__rating--list
  .list__rating
  svg {
  @apply text-20px h-20px w-20px;
}

.customerReviews__card__container .testimonial .testimonial__inner .testimonial__rating p {
  margin-top: 0.5625rem;
  @apply font-medium text-grey-light text-16px leading-5;
}

@screen md {
  .customerReviews__card__container .testimonial .testimonial__inner {
    padding: 1.3125rem 1.5rem 1.4375rem;
  }

  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__content {
    margin-bottom: 1.3125rem;
  }

  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__content h3 {
    margin-bottom: 0.625rem;
  }

  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__rating {
    @apply flex flex-wrap justify-between items-center;
  }

  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__rating p {
    @apply mt-0;
  }
}

@screen xl {
  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__content {
    margin-bottom: 1.875rem;
  }

  .customerReviews__card__container .testimonial .testimonial__inner .testimonial__content h3 {
    margin-bottom: 0.875rem;
  }

  .customerReviews__card__container .testimonial {
    max-width: 400px;
    min-width: none;
  }
}
