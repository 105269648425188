

.promo-brands {
  text-align: center;
  margin: 40px 0 60px;
}

.promo-brands .container {
  @apply my-0 mx-auto;
}

.promo-brands__heading {
  @apply font-medium text-24px text-black-lighter;
}

.promo-brands a:not(.promo-brands__btn) {
  height: 100px;
  background-image: url(https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/v1550158411/shared/homepage/brand_sprite.png);
  transition: background-image .5s ease-in-out;
  @apply inline-block w-full bg-no-repeat;
}

.promo-brands a:not(.promo-brands__btn):hover {
  background-image: url(https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/v1550158411/shared/homepage/brand_sprite_color.png);
}

.promo-brands a:not(.promo-brands__btn).brand_01 {
  background-position: -15px center;
}

.promo-brands a:not(.promo-brands__btn).brand_02 {
  background-position: -285px center;
}

.promo-brands a:not(.promo-brands__btn).brand_03 {
  background-position: -550px center;
}

.promo-brands a:not(.promo-brands__btn).brand_04 {
  background-position: -795px center;
}

.promo-brands a:not(.promo-brands__btn).brand_05 {
  background-position: -1040px center;
}

.promo-brands a:not(.promo-brands__btn).brand_06 {
  background-position: -1285px center;
}

.promo-brands a:not(.promo-brands__btn).brand_07 {
  background-position: -1535px center;
}

.promo-brands a:not(.promo-brands__btn).brand_08 {
  background-position: -1790px center;
}

.promo-brands .slick-slider {
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  @apply relative box-border block;
}

.promo-brands .slick-slider .slick-list {
  transform: translateZ(0);
  @apply overflow-hidden m-0 p-0 relative block;
}

.promo-brands .slick-slider .slick-list .slick-track {
  @apply relative top-0 left-0 block mx-auto;
}

.promo-brands .slick-slider .slick-list .slick-track .slick-slide {
  min-height: 1px;
  @apply float-left h-full block;
}