.returns-main {
  @apply px-0 flex-content ml-0;
}

.returns-main__desc {
  @apply mb-20px font-normal text-14px leading-5;
}

.returns-desc__anchor {
  @apply font-bold text-blue-link underline;
}

.returns-desc__anchor:hover {
  @apply underline;
}

.returns-card__container {
  height: 326.469px;
  @apply align-top inline-flex flex-col justify-between text-16px text-center bg-concrete px-20px pt-20px pb-15px w-full mr-0 mb-5;
}

.accordion-card {
  @apply mb-10px;
}

.accordion-card p {
  @apply mb-10px text-14px leading-5;
}

.accordion-card-header {
  background-color: #353535;
  /* @apply rounded-3px border-none; */
}

.accordion-card-header h2,
.accordion-card-header h5 {
  @apply mb-2;
}

.accordion-card-header h2 button,
.accordion-card-header h5 button {
  @apply text-12px relative flex items-center w-full text-left h-60px font-normal leading-none whitespace-normal pr-60px pl-20px no-underline text-white rounded transition-shadow duration-200;
}

.accordion-card-header h2 button:focus,
.accordion-card-header h5 button:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.accordion-card-header h2 button svg,
.accordion-card-header h5 button svg {
  /* For IE */
  transform: translateY(-50%) rotate(0deg);
  @apply absolute right-20 top-1/2 text-40px fill-current overflow-visible h-40px inline-block w-3 transform -translate-y-1/2 rotate-0 duration-300;
}

.accordion-card-header h2 button svg.collapsed,
.accordion-card-header h5 button svg.collapsed {
  /* For IE */
  transform: translateY(-50%) rotate(90deg);
  @apply rotate-90;
}

.accordion-card-body {
  @apply pt-15px pb-10px px-20px;
}

.accordion-card-body p {
  @apply mb-10px text-14px leading-5;
}

.accordion-card-body p a {
  @apply text-blue-link font-bold underline;
}

.accordion-card .collapse {
  @apply max-h-0 invisible opacity-0 transition-all duration-500;
}

.accordion-card .collapse.show {
  @apply max-h-screen visible opacity-100;
}

@screen md {
  .returns-main {
    @apply px-15px;
  }

  .returns-card__container {
    margin-right: 2.5%;
    @apply w-returns-card-md;
  }

  .returns-card__container:last-child {
    @apply w-full mb-0;
  }

  .returns-card__container:not(:first-child) {
    @apply mr-0;
  }
}

@screen lg {
  .returns-main {
    @apply ml-auto;
  }

  .returns-card__container {
    @apply w-returns-card mb-0;
  }

  .returns-card__container:not(:first-child) {
    margin-right: 2.5%;
  }

  .returns-card__container:last-child {
    @apply mr-0 w-returns-card;
  }

  .accordion-card-header h2 button,
  .accordion-card-header h5 button {
    @apply text-14px;
  }
}
