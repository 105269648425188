.vat-toggle__container {
  @apply flex items-center ml-30px;
}

.vat-toggle__container .vat-toggle__text {
  @apply font-extrabold text-18px leading-none flex items-end text-center text-black-lighter mr-10px;
}

.vat-toggle__container .vat-toggle__slider {
  @apply inline-block relative w-16 h-6/25;
}

.vat-toggle__container .vat-toggle__slider input {
  @apply h-0 w-0 opacity-0;
}

.vat-toggle__container .vat-slider__main,
.vat-toggle__container .vat-slider__main.checked {
  border-radius: 20px;
  background-color: #acaaa8;
  @apply cursor-pointer transition-colors duration-500 border-1 border-solid border-black-lighter box-border w-full absolute;
}

.theme-id .vat-toggle__container .vat-slider__main.checked {
  @apply bg-orange;
}

.theme-ed .vat-toggle__container .vat-slider__main.checked {
  @apply bg-green;
}

.vat-toggle__container .vat-slider__main::before {
  left: 5px;
  top: 3px;
  content: '';
  @apply rounded-full absolute w-4.5 h-4.5 bg-white border-1 border-solid border-black-lighter box-border transition-all duration-500;
}

.vat-toggle__container .vat-slider__main.checked::before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

.vat-toggle__container .vat-slider__main .vat-slider__status--off {
  @apply font-bold text-12px leading-6 flex items-center justify-end text-black-lighter w-full pr-10px;
}

.vat-toggle__container .vat-slider__main.checked .vat-slider__status--off {
  @apply hidden;
}

.vat-toggle__container .vat-slider__main .vat-slider__status--on {
  @apply hidden;
}

.vat-toggle__container .vat-slider__main.checked .vat-slider__status--on {
  @apply font-bold text-12px leading-6 flex items-center justify-start text-black-lighter w-full pl-10px;
}
