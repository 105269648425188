.clsr-guide-banner__wrapper {
  padding: 5.75rem 0 7rem;
  @apply relative w-full bg-blackBG;
}

.clsr-guide-banner__container {
  max-width: 33.75em;
  @apply mx-auto my-0 px-15px py-0 w-full;
}

.clsr-guide-body__wrapper {
  @apply mx-auto my-12 px-15px py-0 w-full;
}

.clsr-guide-banner__main {
  @apply flex items-center flex-col;
}

.clsr-banner-main--head {
  @apply flex-full max-w-full mb-40px text-center;
}

.clsr-banner-head__heading {
  @apply text-white-dark font-bold leading-none text-36px mb-2;
}

.clsr-banner-head__subhead {
  letter-spacing: 0.4px;
  @apply text-white-dark font-normal text-22px leading-tight mb-4;
}

.clsr-banner-head__span--id {
  @apply block text-orange;
}

.clsr-banner-head__span--ed {
  @apply block text-green;
}

.clsr-banner-main--img {
  backface-visibility: hidden;
  @apply flex-full max-w-full text-center;
}

.clsr-calc__container {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  background-color: #222222;
  @apply p-40px relative text-white rounded-3px my-0 mx-auto;
}

.clsr-calc__heading {
  @apply mb-10px text-30px font-bold;
}

.clsr-calc__subhead {
  @apply text-14px font-normal leading-tight mb-4;
}

.clsr-calc-head__divider {
  @apply my-30px mx-0 text-white;
}

.clsr-calc-main {
  @apply flex flex-col flex-wrap -mx-15px;
}

.clsr-calc-main__items {
  @apply flex-full max-w-full relative w-full px-15px;
}

.clsr-calc-main__form {
  @apply mb-12;
}

.clsr-calc-form__row {
  @apply relative mb-10;
}

.clsr-calc-row__label {
  @apply inline-flex mb-5 text-20px font-bold leading-none;
}

.clsr-calc-row__label--hint {
  @apply h-20px w-20px flex items-center justify-center border-1 border-solid border-white cursor-pointer ml-auto flex-shrink-0 bg-clip-padding text-12px rounded-full;
}

.clsr-calc-row__label--hint:focus {
  @apply outline-none;
}

.clsr-calc-row__two--left {
  @apply flex-full w-auto max-w-full relative pl-15px mb-2 pr-2;
}

.clsr-calc-row__two--right {
  @apply flex-full w-auto max-w-full relative py-0 pr-2 pl-15px;
}

.clsr-calc-form__row--custom {
  border: 2px solid rgba(255, 255, 255, 0.3);
  @apply w-full inline-flex items-center rounded-3px px-20px py-0 bg-transparent;
}

.clsr-calc-form__row--custom.active {
  color: #222222;
  @apply border-2 border-solid border-white bg-white;
}

.clsr-calc-form__input {
  width: 70%;
  @apply border-none bg-transparent h-60px text-18px text-white font-normal italic leading-none overflow-visible;
}

.clsr-calc-form__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-white opacity-100;
}

.clsr-calc-form__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-white opacity-100;
}

.clsr-calc-form__input::-ms-input-placeholder {
  /* Microsoft Edge */
  @apply text-white opacity-100;
}

.clsr-calc-form__input--after {
  width: 30%;
  @apply text-right text-18px text-white leading-none font-bold;
}

.clsr-calc-form__input--btn {
  @apply w-full p-0 h-60px font-bold uppercase;
}

.clsr-calc-form__input--btn:focus {
  @apply outline-none;
}

.clsr-calc-form__verification {
  width: 100%;
  @apply bg-red flex items-center rounded-md p-0 mt-10px;
}

.clsr-calc-verification__icon {
  width: 15%;
  @apply text-center;
}

.clsr-calc-verification__svg {
  width: 0.6875em;
  @apply overflow-visible flex items-center justify-center my-0 mx-auto text-22px text-white;
}

.clsr-calc-verification__text {
  width: 85%;
  @apply text-12px text-white font-bold py-1.5 px-20px my-2 mx-0 border-l border-solid border-white;
}

.clsr-calc-form__results {
  @apply h-full flex items-center justify-center flex-col text-center;
}

.clsr-calc-form__results .results--icon {
  width: 1.125em;
  font-size: 100px;
  color: #ffc324;
  height: 1em;
  vertical-align: -0.125em;
  @apply overflow-visible mb-20px inline-block;
}

.clsr-calc-results__text {
  letter-spacing: -0.37px;
  @apply text-center text-20px text-white font-bold mb-4 leading-5;
}

.clsr-calc-results__text--sub {
  @apply text-16px leading-5 my-2;
}

.clsr-calc-results__span {
  font-size: 200px;
  @apply text-white font-bold leading-none;
}

.clsr-calc-suggestions__main {
  /* max-width: 50rem; */
  @apply flex flex-wrap items-center w-full my-0 mx-auto max-w-5xl;
}

.clsr-calc-suggestions__heading {
  letter-spacing: -0.96px;
  color: #222222;
  @apply w-full font-normal text-center mb-20px text-26px leading-30px;
}

.clsr-calc-suggestions__item {
  @apply w-full inline-flex justify-between flex-col text-center;
}

.clsr-calc-suggestions__img {
  width: 8.5rem;
  @apply my-0 mx-auto;
}

.clsr-calc-suggestions__span--title {
  @apply text-black-lighter font-bold text-14px;
}

.clsr-calc-suggestions__span--price {
  @apply text-black-lighter text-14px my-2;
}

.clsr-calc-common__container {
  @apply flex flex-wrap;
}

.clsr-calc-common__heading {
  letter-spacing: -0.96px;
  color: #222222;
  @apply w-full text-center text-26px mb-20px font-normal leading-30px mt-0;
}

.clsr-calc-common__item {
  @apply inline-flex justify-between flex-col text-center rounded-3px bg-white-darker mb-20px w-full p-20px;
}

.clsr-calc-common__img {
  @apply mx-auto mt-0 mb-10px;
}

.clsr-calc-common__span {
  letter-spacing: -0.37px;
  color: #222222;
  @apply block text-center mb-10px text-20px font-bold;
}

.clsr-calc-common__desc {
  color: #222222;
  @apply mb-40px text-14px leading-5;
}

.clsr-calc-common__btn {
  width: 11.0625rem;
  @apply h-40px text-white text-16px tracking-wider font-bold text-center items-center my-0 mx-auto justify-center inline-flex rounded-full py-0 px-20px uppercase;
}

.clsr-calc-popup__container {
  background-color: rgba(34, 34, 34, 0.9);
  @apply flex items-center justify-center text-center flex-col absolute inset-0 transition-all duration-500 opacity-0 invisible;
}

.clsr-calc-popup__heading {
  letter-spacing: -1.85px;
  @apply text-30px mb-20px font-bold leading-9;
}

.clsr-calc-popup__desc {
  @apply w-4/5 mx-auto mt-0 mb-50px text-14px leading-5;
}

.clsr-calc-popup__img {
  @apply mb-50px inline-flex;
}

.clsr-calc-popup__btn {
  color: #222222;
  @apply flex w-32 capitalize my-0 mx-auto text-18px py-0 px-20px bg-concrete rounded-3px text-center h-40px items-center justify-center no-underline font-bold leading-none border-none;
}

.clsr-calc-popup__btn:hover {
  background-color: #d1d1d1;
}

.clsr-calc-popup__btn:focus {
  @apply outline-none;
}

.clsr-calc-popup__container.opened {
  @apply opacity-100 visible;
}

@screen md {
  .clsr-guide-banner__container {
    max-width: 60em;
  }

  .clsr-banner-head__heading {
    @apply text-40px;
  }

  .clsr-calc-common__item {
    width: calc(96.5% / 2);
  }

  .clsr-calc-common__item:not(:nth-of-type(even)) {
    margin-right: 3.5%;
  }

  .clsr-calc-popup__heading {
    @apply text-40px leading-46px;
  }
}

@screen lg {
  .clsr-guide-banner__container {
    max-width: 71.25em;
    @apply mx-0 px-0;
  }

  .clsr-guide-body__wrapper {
    @apply mx-0 px-0;
  }

  .clsr-banner-main--head {
    @apply mb-0 flex-1/2 max-w-1/2 text-left;
  }

  .clsr-banner-main--img {
    @apply flex-1/2 max-w-1/2;
  }

  .clsr-guide-banner__main {
    @apply flex-row;
  }

  .clsr-banner-head__heading {
    @apply leading-none text-50px;
  }

  .clsr-calc__container {
    padding: 3.125rem 4.375rem;
  }

  .clsr-calc__heading {
    @apply text-50px;
  }

  .clsr-calc-main {
    @apply flex-row;
  }

  .clsr-calc-main__items {
    @apply flex-1/2 max-w-1/2;
  }

  .clsr-calc-main__form {
    @apply mb-0;
  }

  .clsr-calc-row__two--left {
    @apply flex-0-auto pr-1 mb-0;
  }

  .clsr-calc-row__two--right {
    @apply flex-0-auto;
  }

  .clsr-calc-form__row--custom {
    width: 9.375rem;
  }

  .clsr-calc-form__verification {
    width: 90%;
  }

  .clsr-calc-suggestions__item {
    width: calc(100% / 4);
  }

  .clsr-calc-common__item {
    width: calc(96.5% / 4);
  }

  .clsr-calc-common__item:not(:nth-of-type(4)) {
    margin-right: 1.167%;
  }

  .clsr-calc-popup__heading {
    @apply text-50px;
  }

  .clsr-calc-popup__desc {
    @apply w-1/2;
  }
}

@screen xl {
  .clsr-guide-banner__container {
    @apply max-w-full;
  }
}
