.home-banner {
  @apply bg-black-light text-white relative overflow-hidden z-0;
}

.home-banner:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  background: #222323;
  opacity: 0.9;
}

.ed-home-banner__background {
  content: url(https://img-cdn.manutantraders.com/image/upload/v1690974813/electrical/campaigns/Summer%20Sale/ED-BIG-SUMMER-SALE-Mobile.jpg);
  max-width: 2560px;
}

@screen md {
  .ed-home-banner__background {
    content: url(https://img-cdn.manutantraders.com/image/upload/v1690989974/electrical/campaigns/Summer%20Sale/ED-BIG-SUMMER-SALE-Tablet.jpg);
  }
}

@screen xl {
  .ed-home-banner__background {
    content: url(https://img-cdn.manutantraders.com/image/upload/v1690974813/electrical/campaigns/Summer%20Sale/ED-BIG-SUMMER-SALE-Smaller-Desktop.jpg);
  }
}

@screen 3xl {
  .ed-home-banner__background {
    content: url(https://img-cdn.manutantraders.com/image/upload/v1690974813/electrical/campaigns/Summer%20Sale/ED-BIG-SUMMER-SALE-Desktop.jpg);
  }
}

.home-banner .home-banner__inner .home-banner__content {
  max-width: 31.25rem;
  padding: 1.875rem 0 2.625rem;
  @apply relative z-10 text-18px flex flex-col;
}

.home-banner .banner--loading {
  height: 35.875rem;
  @apply rounded-none leading-loose;
}

.home-banner .home-banner__inner .home-banner__image {
  transform: translate(-50%, -50%);
  background-position: 50% 50%;
  width: 47.9375rem;
  height: 35.875rem;
  @apply absolute right-0 top-1/2 left-1/2 bg-contain bg-no-repeat;
}

.home-banner .home-banner__inner .home-banner__content h1 {
  order: 2;
  font-size: 2.125rem;
  letter-spacing: -0.00688em;
  line-height: 2.5rem;
  margin-bottom: 0.9375rem;
}

.theme-id .home-banner .home-banner__inner .home-banner__content h1 > strong {
  color: #efa847;
}

.theme-ed .home-banner .home-banner__inner .home-banner__content h1 > strong {
  color: #c7d303;
}

.home-banner .home-banner__inner .home-banner__content h2 {
  order: 1;
  letter-spacing: -0.00375em;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 0.3125rem;
}

.theme-id .home-banner .home-banner__inner .home-banner__content h2 {
  color: #efa847;
}

.theme-ed .home-banner .home-banner__inner .home-banner__content h2 {
  color: #c7d303;
}

.home-banner .home-banner__inner .home-banner__content p {
  order: 3;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.9375rem;
}

.home-banner .home-banner__inner .home-banner__content > a {
  text-decoration: none;
  position: relative;
  display: inline-block;
  transition: color 400ms;
}

.theme-id .home-banner .home-banner__inner .home-banner__content > a {
  color: #efa847;
}

.theme-ed .home-banner .home-banner__inner .home-banner__content > a {
  color: #c7d303;
}

.home-banner .home-banner__inner .home-banner__content > a:after {
  content: '';
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  height: 0.125rem;
  background: currentColor;
}

.home-banner .home-banner__inner .home-banner__content .hero__actions {
  order: 4;
}

@screen sm {
  .home-banner:before {
    background: linear-gradient(to right, #222323 40%, rgba(34, 35, 35, 0.5) 100%);
    opacity: 1;
  }

  .home-banner .home-banner__inner .home-banner__content {
    padding: 1.875rem 0;
  }

  .home-banner .home-banner__inner .home-banner__image {
    left: 70%;
    @apply bg-cover;
  }
}

@screen md {
  .home-banner:before {
    background: linear-gradient(to right, #222323 60%, rgba(31, 31, 31, 0) 100%);
  }

  .home-banner .home-banner__inner .home-banner__content {
    max-width: 29.0625rem;
  }

  .home-banner .home-banner__inner .home-banner__image {
    width: 40%;
    left: unset;
    @apply h-full transform-none top-0;
  }

  .home-banner .banner--loading {
    height: 558px;
  }

  .home-banner .home-banner__inner .home-banner__content h1 {
    line-height: 2.8125rem;
    letter-spacing: -0.00813em;
    margin-bottom: 0.78125rem;
  }

  .home-banner .home-banner__inner .home-banner__content h2 {
    font-size: 1.25rem;
    letter-spacing: -0.00438em;
    margin-bottom: 0;
  }

  .home-banner .home-banner__inner .home-banner__content p {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

@screen lg {
  .home-banner .home-banner__inner .home-banner__content {
    max-width: 40rem;
    padding: 3.125rem 0 3.1875rem;
    @apply text-20px leading-5;
  }
}

@screen xl {
  .home-banner:before {
    background: linear-gradient(to right, #222323 65%, rgba(31, 31, 31, 0) 100%);
  }

  .home-banner .home-banner__inner .home-banner__content {
    max-width: 750px;
    padding: 7.5rem 0;
    @apply text-22px leading-5;
  }

  .home-banner .home-banner__inner .home-banner__image {
    background-position: 0;
  }

  .home-banner .home-banner__inner .home-banner__content h1 {
    font-size: 3.75rem;
    line-height: 4.125rem;
    letter-spacing: -0.0125em;
    margin-bottom: 1.5625rem;
  }

  .home-banner .home-banner__inner .home-banner__content h2 {
    font-size: 1.5625rem;
    letter-spacing: -0.005em;
    margin-bottom: 0.9375rem;
  }

  .home-banner .home-banner__inner .home-banner__content p {
    font-size: 1.375rem;
    margin-bottom: 1.875rem;
  }
}

@screen xxl {
  .home-banner:before {
    background: linear-gradient(to right, #222323 55%, rgba(31, 31, 31, 0) 100%);
  }

  .home-banner .home-banner__inner .home-banner__image {
    @apply w-1/2;
  }
}

@media (hover: hover), (-ms-high-contrast: none) {
  .theme-id .home-banner .home-banner__inner .home-banner__content > a:hover {
    color: #eb9218;
  }

  .theme-ed .home-banner .home-banner__inner .home-banner__content > a:hover {
    color: #afba03;
  }
}
