.home-find {
  padding: 3.125rem 0 1.875rem;
}

.home-find .campaign {
  @apply h-full text-white;
}

.home-find .campaign .campaign__inner {
  border-radius: 14px;
  padding: 1.875rem 0.9375rem 2.3125rem 1.3125rem;
  @apply h-full overflow-hidden relative;
}

.home-find .campaign .campaign__inner .campaign__image {
  background-position: 50% 50%;
  @apply absolute top-0 left-0 w-full h-full pointer-events-none bg-no-repeat bg-cover;
}

.home-find .campaign .campaign__inner .campaign__content {
  line-height: 1.38;
  z-index: 2;
  @apply flex flex-col h-full relative text-16px;
}

.home-find .campaign .campaign__inner::after {
  content: "";
  background: linear-gradient(45deg,#1d1d1d,rgba(29,29,29,.45));
  @apply absolute top-0 left-0 w-full h-full pointer-events-none;
}

.home-find .campaign .campaign__inner .campaign__content .campaign__content-inner {
  margin-bottom: 1.0625rem;
  flex: auto;
}

.home-find .campaign .campaign__inner .campaign__content .campaign__content-inner p {
  margin-bottom: 0.125rem;
  @apply text-16px leading-5;
}

.home-find .campaign .campaign__inner .campaign__content .campaign__content-inner h2 {
  line-height: 1.17;
  @apply max-w-none text-30px;
}

.home-find .campaign .campaign__inner.campaign__inner--size1 .campaign__content .campaign__content-inner h2 {
  font-size: 2.9375rem;
  line-height: .9;
}

.home-find .campaign .campaign__inner .campaign__content .campaign__content-inner h2 span {
  font-size: 1.4375rem;
  line-height: 1.29;
  margin-top: 0.25rem;
  @apply block font-normal text-white;
}

.home-find .home-find__inner .carousel {
  @apply mt-0;
}

.home-find .home-find__inner .carousel .carousel__viewport .carousel__container {
  @apply flex-col;
}

.home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:not(:first-child):not(:last-child) {
  padding: 0.625rem 0;
}

.home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:first-child {
  padding: 0 0 0.625rem;
}

.home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:last-child {
  padding: 0.625rem 0 0;
}

.home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide {
  width: 100%;
  max-width: 430px;
}

.home-find .home-find__inner .carousel button {
  @apply hidden;
}

@screen md {
  .home-find {
    padding: 3.75rem 0;
  }

  .home-find .campaign .campaign__inner .campaign__content {
    line-height: 1.35;
    @apply text-20px;
  }

  .home-find .campaign .campaign__inner .campaign__content .campaign__content-inner {
    margin-bottom: 1.375rem;
  }

  .home-find .campaign .campaign__inner .campaign__content .campaign__content-inner p {
    margin-bottom: 0.3125rem;
  }

  .home-find .campaign .campaign__inner .campaign__content .campaign__content-inner h2 {
    max-width: 18.4375rem;
  }

  .home-find .campaign .campaign__inner .campaign__content .campaign__content-inner h2 span {
    font-size: 1.9375rem;
  }

  .home-find .campaign .campaign__inner--upsize .campaign__content .campaign__content-inner h2 {
    font-size: 3.5625rem;
  }

  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container {
    @apply flex-row;
  }

  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:not(:first-child):not(:last-child) {
    padding: 0 0.75rem;
  }
  
  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:first-child {
    padding: 0 0.75rem 0 0;
  }
  
  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide:last-child {
    padding: 0 0 0 0.75rem;
  }

  .home-find .home-find__inner .carousel button {
    @apply block;
  }

  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide {
    min-width: 430px;
  }
}

@screen lg {
  .home-find .home-find__inner .carousel button {
    @apply hidden;
  }

  .home-find .home-find__inner .carousel .carousel__viewport .carousel__container .carousel__slide {
    min-width: 0;
    max-width: 100%;
    flex: 1;
  }
}

@screen xl {
  .home-find {
    padding: 5rem 0 4.9375rem;
  }
}