.m--basket-table-col {
  position: relative;
  display: flex;
  height: 100%;
  padding: 0 5px;
  min-height: 80px;
  text-align: center;
  justify-content: center;
}

.m--basket-table-col-details-unit,
.m--oos-table-col-details-unit {
  margin: 10px 0;
  font-size: 14px;
}

.m--basket-table-col-details-unit-sale,
.m--oos-table-col-details-unit-sale {
  font-size: inherit;
  color: #d32f2f;
  font-weight: 700;
}

.m--basket-table-col-details-unit-price,
.m--oos-table-col-details-unit-price {
  font-size: inherit;
  color: #222222;
}

.m--basket-table-col-details-unit-price-init,
.m--oos-table-col-details-unit-price-init {
  position: relative;
  margin-right: 10px;
  font-size: inherit;
  color: #9b9b9b;
}

.m--basket-table-col-details-unit-price-init::before,
.m--oos-table-col-details-unit-price-init::before {
  content: ' ';
  display: block;
  width: 100%;
  border-top: 2px solid rgba(155, 155, 155, 0.8);
  height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
}

.m--basket-table-col-details-unit-savings,
.m--oos-table-col-details-unit-savings {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 100px;
  background-color: #d32f2f;
  text-transform: uppercase;
  margin-left: 30px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
}

.m--basket-table-col-details-unit-total,
.m--oos-table-col-details-unit-total {
  margin-left: 35px;
  font-size: 16px;
  color: #222222;
  font-weight: 700;
}

.m--basket-qty-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #d3d3d7;
  overflow: hidden;
  background-color: #e6e6e6;
  width: 99px;
  height: 29px;
  font-size: 12px;
  font-weight: 500;
}

.m--basket-qty-input-field {
  border: none;
  background-color: #ffffff;
  height: 100%;
  width: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  -moz-appearance: textfield;
}

.m--basket-qty-input-field::-webkit-outer-spin-button,
.m--basket-qty-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m--basket-qty-input-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  width: 25%;
  text-align: center;
  border: none;
  cursor: pointer;
}

.m--basket-qty-input-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.m--basket-delete-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: #d32f2f;
}

.m--basket-table-active .m--basket-table-row::after,
.m--oos-table-active .m--basket-table-row::after {
  content: '';
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(230, 230, 230, 0.6);
}

.m--basket-table-active .m--basket-table-row-active::after,
.m--oos-table-active .m--basket-table-row-active::after {
  display: none;
}

.m--basket-qty-confirm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0 0;
  color: #9b9b9b;
  font-weight: 700;
}

.m--basket-qty-confirm button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #9b9b9b;
  font-weight: 700;
}

.m--basket-qty-confirm button:last-child {
  color: #152f90;
}

.m--basket-qty-confirm span {
  margin: 0 8px;
}

.m--basket-page-cards-accepted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 0;
}

.m--basket-page-cards-accepted img {
  width: 50px;
}

.m--basket-page-cards-accepted img:nth-child(2) {
  margin: 0 20px;
}

.m--basket-summary-table {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  /* border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6; */
}

.m--basket-summary-table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  font-size: 14px;
}

.m--basket-summary-table-row-sale {
  color: #d32f2f;
  font-weight: 700;
  text-transform: uppercase;
}

.m--basket-summary-table-row-total {
  padding: 15px 15px 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.m--basket-summary-table-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.m--basket-summary-table-col:first-child {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.m--basket-summary-table-col:last-child {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.m--basket-summary-table-col-subtotal {
  font-weight: 700;
}

.m--basket-summary-table-col-free {
  color: #368200;
  font-weight: 700;
  text-transform: uppercase;
}

.m--basket-summary-table-col-delivery {
  color: #d32f2f;
  font-weight: 700;
  text-transform: uppercase;
}

.m--basket-delivery-status {
  background-color: #e6e6e6;
  width: 100%;
  padding: 12px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.m--basket-delivery-status-free .m--basket-delivery-status-progress-bar-inner {
  background-color: #368200;
}

.m--basket-delivery-status-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.m--basket-delivery-status-progress span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.m--basket-delivery-status-progress-bar {
  position: relative;
  width: 80%;
  margin: 0 4px;
  height: 15px;
  border-radius: 100px;
  background-color: #ffffff;
  overflow: hidden;
}

.m--basket-delivery-status-progress-bar-inner {
  position: absolute;
  background-color: #152f90;
  border-radius: 100px;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transition: width 2s cubic-bezier(0.76, 0, 0.24, 1);
  transition: width 2s cubic-bezier(0.76, 0, 0.24, 1);
}
