.basicInput ~ label {
  @apply pointer-events-none top-13px;
}

.basicInput--alt ~ label {
  @apply pointer-events-none top-0;
}

.basicInput--alt:focus ~ label,
.basicInput--alt.active ~ label,
.basicInput:focus ~ label,
.basicInput.active ~ label {
  @apply text-sm -top-4;
}