.m--error-container {
  @apply flex flex-col items-center justify-center my-60px mx-0;
}

.m--error-context {
  @apply mb-40px text-center;
}

.m--error-context-heading {
  @apply text-26px text-black-lighter;
}

.m--error-context-subheading {
  @apply text-18px text-black-lighter;
}

.m--error-context-help {
  @apply inline-flex text-left my-30px mx-0;
}

.m--error-context-help-title {
  @apply mr-50px text-14px;
}

.m--error-context-help-list {
  @apply list-disc text-14px;
}

.m--error-context-help-list li {
  @apply list-disc;
}

.m--error-context-help-contact {
  @apply mt-20px text-center;
}

@screen lg {
  .m--error-context-heading {
    @apply text-50px text-black-lighter;
  }

  .m--error-context-subheading {
    @apply text-20px text-black-lighter;
  }
}