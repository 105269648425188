.fi-slot--plp-main-mobile {
  display: none;
}

.fi-slot--plp-main-mobile-duplicate {
  display: none;
}

.fi-slot--plp-main {
  display: block;
  clear: both;
  margin: 25px 0 40px 0;
  max-width: 990px;
}

div.search_page .fi-slot--plp-main {
  margin: 30px auto 20px auto;
}

.fi-plp-main__nav {
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fi-plp-main__list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 180px;
  overflow: hidden;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.fi-plp-main__item {
  display: inline-block;
  text-align: center;
  box-sizing: border-box !important;
  min-width: calc(100% / 6);
  max-width: calc(100% / 6);
  padding: 0 7px;
}

.fi-plp-main__link {
  display: inline-block;
  position: relative;
  -webkit-user-drag: none;
}

.fi-plp-main__image-crop {
  display: inline-block;
  border: 3px solid #ddd;
  border-radius: 50%;
  overflow: hidden;
  height: 110px;
  width: 110px;
}

.theme-id .fi-plp-main__image-crop:hover {
  @apply border-orange;
}

.theme-ed .fi-plp-main__image-crop:hover {
  @apply border-green;
}

.fi-plp-main__image {
  display: block;
  margin: auto;
  width: 100%;
  -webkit-user-drag: none;
}

.fi-plp-main__text {
  display: block;
  margin-top: 10px;
  max-height: 50px;
  overflow: hidden;
  width: 100%;
  font-size: 14.4px;
  line-height: 16px;
  padding-bottom: 3px;
  @apply text-white;
}

.m--foundit--duplicate .fi-plp-main__text {
  @apply text-black-lighter;
}

.fi-arrow {
  position: absolute;
  display: inline-block;
  height: 16px;
  width: 16px;
  top: 35%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  visibility: hidden;
}

.theme-id .fi-arrow-right:before {
  @apply bg-orange;
}

.theme-id .fi-arrow-left:before {
  @apply bg-orange;
}

.theme-ed .fi-arrow-right:before {
  @apply bg-green;
}

.theme-ed .fi-arrow-left:before {
  @apply bg-green;
}

.fi-arrow-right:before {
  content: url(public/svgs/ico-arrow-right.svg);
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  align-items: center;
  border-radius: 16px;
}

.fi-arrow-left:before {
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  align-items: center;
  content: url(public/svgs/ico-arrow-left.svg);
  border-radius: 16px;
}

.fi-arrow-left {
  left: -35px;
}

.fi-arrow-right {
  right: -15px;
}

.fi-arrow-active {
  cursor: pointer;
  visibility: visible;
}

@media only screen and (max-width: 1024px) {
  .fi-slot--plp-main {
    margin: 15px auto;
  }
  .fi-plp-main__nav {
    height: 100%;
    overflow-y: hidden;
    padding: 0 30px;
  }
  .fi-plp-main__list {
    height: 200px;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 989px) {
  .fi-slot--plp-main {
    margin: 15px 0 0 0;
    padding: 0;
  }
  div.search_page .fi-slot--plp-main {
    margin: 30px auto 20px auto;
  }
  .fi-plp-main__nav {
    padding: 0px;
  }
  .fi-plp-main__item {
    min-width: 18.5%;
    max-width: 18.5%;
  }
  .fi-plp-main__image-crop {
    height: 95px;
    width: 95px;
  }
  .fi-arrow {
    display: none;
  }
}

@media only screen and (max-width: 549px) {
  .fi-slot--plp-main {
    display: none;
  }
  .fi-slot--plp-main-duplicate {
    display: none;
  }
  .fi-slot--plp-main-mobile {
    display: block;
    padding: 25px 0 0 0;
  }
  .fi-slot--plp-main-mobile-duplicate {
    display: block;
    padding: 25px 0 0 0;
  }
  .fi-plp-main__nav {
    height: 100%;
  }
  .fi-plp-main__item {
    min-width: 37%;
    max-width: 37%;
  }
  .fi-plp-main__image-crop {
    height: 85px;
    width: 85px;
  }
}

#fi-pdp-mobile {
  display: none;
}

#fi-pdp {
  display: block;
  clear: both;
  margin: 1.5rem auto;
}

#fi-pdp .fi-plp-main__list {
  box-sizing: border-box;
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 989px) {
  #fi-pdp {
    padding: 25px 0 10px 0;
  }
}

@media only screen and (max-width: 549px) {
  #fi-pdp {
    display: none;
  }
  #fi-pdp-mobile {
    display: block;
    padding: 25px 0 10px 0;
  }
}
