.home-brands {
  overflow: hidden;
  padding: 2.25rem 0 1.8125rem;
}

.theme-id .home-brands {
  @apply bg-orange;
}

.theme-ed .home-brands {
  background-color: #e8ed9a;
}

.home-brands .home-brands__inner {
  display: block;
  margin-right: 0;
}

.home-brands .home-brands__inner .home-brands__content {
  margin: 0 0 2.375rem;
  max-width: none;
  flex: 0 0 17.875rem;
}

.home-brands .home-brands__inner .home-brands__content h2 {
  @apply text-black-lighter;
}

.home-brands .home-brands__inner .home-brands__logos {
  flex: 1 1;
  width: 100%;
  max-width: none;
}

.home-brands .home-brands__inner .home-brands__logos .logos__list {
  -ms-overflow-style: none;
  @apply flex items-center overflow-x-scroll;
}

.home-brands .home-brands__inner .home-brands__logos .logos__list::-webkit-scrollbar {
  display: none;
}

.home-brands .home-brands__inner .home-brands__logos .logos__list li {
  min-width: 75px;
  @apply my-0 ml-0 mr-7;
}

@screen sm {
  .home-brands {
    padding: 2.375rem 0 1.8125rem;
  }

  .home-brands .home-brands__inner .home-brands__content {
    margin: 0 0 2.5rem;
  }
}

@screen md {
  .home-brands {
    padding: 2.875rem 0 2.5rem;
  }

  .home-brands .home-brands__inner {
    @apply flex flex-wrap items-center;
  }

  .home-brands .home-brands__inner .home-brands__content {
    max-width: 286px;
    margin-right: 2.0625rem;
  }

  .home-brands .home-brands__inner .home-brands__logos {
    max-width: 34rem;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: -ms-grid;
    -ms-grid-row-align: center;
    @apply grid items-center;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li {
    @apply m-4 min-w-0;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:nth-child(4n + 1) {
    -ms-grid-column: 1;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:nth-child(4n + 2) {
    -ms-grid-column: 2;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:nth-child(4n + 3) {
    -ms-grid-column: 3;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:nth-child(4n + 4) {
    -ms-grid-column: 4;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:not(:nth-child(n+5)) {
    -ms-grid-row: 1;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li:nth-child(n+5) {
    -ms-grid-row: 2;
  }
}

@screen lg {
  .home-brands {
    padding: 2.875rem 0 1.6875rem;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li {
    @apply m-5;
  }
}

@screen xl {
  .home-brands {
    padding: 5.625rem 0 4.3125rem;
  }

  .home-brands .home-brands__inner {
    @apply mr-0;
  }

  .home-brands .home-brands__inner .home-brands__content {
    margin-right: 4.5625rem;
    max-width: 23.75rem;
    flex: 0 0 23.75rem;
  }

  .home-brands .home-brands__inner .home-brands__logos {
    max-width: 37rem;
  }

  .home-brands .home-brands__inner .home-brands__logos .logos__list li {
    @apply m-6;
  }
}

@screen xxl {
  .home-brands {
    padding: 6.25rem 0 4.4375rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .home-brands .home-brands__inner .home-brands__logos .logos__list li {
    width: 7vw;
  }
}