.login-header__wrapper {
  @apply h-auto;
}

.login-header__main {
  @apply w-full py-10px px-10px;
}

.login-header__inner {
  @apply flex items-center justify-between;
}

.login-header__basket {
  @apply inline-block py-1.5;
}

.login-basket__anchor {
  color: #333;
  @apply block text-center relative no-underline;
}

.login-basket__svg {
  @apply overflow-visible h-6 w-6 mb-1 text-center inline-block;
}

.login-basket__qty--id {
  @apply absolute block text-12px font-bold text-black-lighter right-0 w-5 rounded-full text-center leading-5 bg-orange bottom-0;
}

.login-basket__qty--ed {
  @apply absolute block text-12px font-bold text-black-lighter right-0 w-5 rounded-full text-center leading-5 bg-green bottom-0;
}

.login-basket__title {
  @apply block m-0 font-bold text-16px leading-5;
}

.login-nav__main {
  @apply py-20px px-0 border-t-1 border-b-1 border-solid border-grey-mono;
}

.login-nav__inner {
  @apply flex items-center;
}

.login-nav__anchor {
  color: #222;
  @apply text-16px;
}

.login-nav__svg {
  vertical-align: -0.125em;
  @apply w-2.5 overflow-visible mr-2 inline-block;
}

.login-form__container {
  @apply my-20px mx-0;
}

.login-form__main {
  @apply flex flex-col;
}

.login-form__item {
  @apply flex-full max-w-full;
}

.login-form__login--wrapper {
  @apply border-r-0 border-b-0 border-solid border-transparent pb-0 pr-0 flex flex-col h-full justify-center;
}

.login-form__login--heading {
  color: #222;
  @apply text-30px text-center mb-4 leading-10;
}

.login-form__login--row {
  @apply mb-6;
}

.login-form-row__item {
  @apply flex-full max-w-full px-1.5 relative w-full;
}

.login-form-row__item p {
  color: #222;
  @apply block w-full text-14px font-bold mb-10px;
}

.login-form-row__item input {
  color: #495057;
  @apply flex items-center h-12 bg-concrete border-2 border-solid border-concrete w-full py-1.5 px-3 text-16px font-normal leading-normal rounded-md transition-all duration-150 ease-in;
}

.login-form-row__item input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.login-register__container {
  @apply p-0 flex h-full items-center justify-center flex-col;
}

.login-register__heading {
  color: #222;
  @apply text-30px text-center mb-4 leading-10;
}

.login-footer__wrapper {
  background-color: #222;
  @apply w-full py-40px px-0;
}

.login-footer__main {
  @apply flex flex-wrap;
}

.login-footer__item {
  flex: 0 0 50%;
  max-width: 50%;
  @apply px-15px;
}

.login-footer__item svg {
  height: 1em;
  vertical-align: -0.125em;
  width: 1.25em;
  transform: scale(-1, 1);
  @apply overflow-visible text-50px inline-block;
}

.login-footer__item > .footer--heading {
  @apply uppercase my-10px mx-0 text-12px text-white font-bold leading-5;
}

.login-footer__item > .footer--text {
  @apply text-12px text-white leading-5 mb-4;
}

.login-form-verification__wrapper {
  width: 90%;
  @apply flex items-center rounded-5px p-0 mt-10px mx-auto mb-0;
}

.login-form-verification__wrapper .icon {
  width: 15%;
  @apply text-center;
}

.login-form-verification__wrapper .description {
  width: 85%;
  @apply text-12px text-white font-bold py-1.5 px-20px my-2 mx-0 border-l-1 border-solid border-white;
}

.login-form-verification__wrapper .icon svg {
  width: 0.6875em;
  height: 1em;
  @apply overflow-visible flex items-center justify-center my-0 mx-auto text-22px text-white;
}

@screen md {
  .login-header__basket {
    @apply relative;
  }

  .login-nav__anchor {
    @apply text-18px;
  }

  .login-form__container {
    @apply my-12;
  }

  .login-form__login--heading {
    line-height: 4.375rem;
    @apply text-left mb-12 text-50px;
  }

  .login-form-row__item input {
    @apply h-14;
  }

  .login-form__login--wrapper {
    @apply border-b-1 border-solid border-grey-mono pb-15px;
  }

  .login-register__container {
    @apply pt-40px;
  }

  .login-register__heading {
    line-height: 4.375rem;
    @apply mb-6 text-50px;
  }

  .login-footer__item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@screen lg {
  .login-header__main {
    @apply py-20px px-0;
  }

  .login-header__basket {
    @apply py-10px;
  }

  .login-form__container {
    @apply my-30px;
  }

  .login-form__item {
    @apply flex-1/2 max-w-1/2;
  }

  .login-form__main {
    @apply flex-row;
  }

  .login-form__login--wrapper {
    @apply border-r-1 border-b-0 border-solid border-grey-mono pr-50px;
  }

  .login-register__container {
    @apply px-40px pt-0;
  }
}
