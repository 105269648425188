.alice-carousel__next-btn {
  position: absolute;
  border-radius: 50%;
  width: 35px !important ;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.alice-carousel__next-btn-item {
  background: rgba(255, 255, 255, 0.685);
  box-shadow: 1px 1px 5px black;
  border-radius: 50%;
  width: 35px !important ;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.alice-carousel__next-btn-item--id {
  background: orange;
}

.alice-carousel__next-btn-item--ed {
  background: green;
}

.alice-carousel__prev-btn {
  border-radius: 50%;
  width: 35px !important ;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}

.alice-carousel__prev-btn-item {
  background: rgba(255, 255, 255, 0.685);
  box-shadow: 1px 1px 5px black;
  border-radius: 50%;
  width: 35px !important ;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.alice-carousel__prev-btn-item:hover {
  color: black !important;
}

.alice-carousel__next-btn-item:hover {
  color: black !important;
}

.alice-carousel__prev-btn-item--id {
  background: orange;
}

.alice-carousel__prev-btn-item--ed {
  background: green;
}

.alice-carousel__next-btn-item.__inactive, .alice-carousel__prev-btn-item.__inactive {
  display: none !important;
}