.blog__featured {
  @apply bg-gradient-to-b from-blog-black-85 to-blog-transparent-15;
}

.blog-featured__container {
  @apply w-full relative;
}

.blog-featured__main {
  height: 43.4375rem;
  @apply relative w-full;
}

.blog-ftrd-title--primary {
  @apply text-white font-bold pt-45px;
}

.blog-featured__wrapper {
  @apply absolute flex bottom-0 w-full justify-center flex-wrap;
}

.blog-featured__posts {
  border: 10px solid #323232;
  border-radius: 12px;
  height: 14.0625rem;
  @apply object-cover w-full bg-center bg-cover my-10px bg-grey-lightestx4;
}

.blog-posts__inner {
  @apply h-full relative;
}

.blog-ftrd__text {
  @apply absolute bottom-0 text-white transition duration-200 my-1 mx-15px;
}

.blog-ftrd__text--date {
  @apply text-16px;
}

.blog-ftrd__text--title {
  line-height: 1.625rem;
  @apply font-bold text-xl;
}

.blog__latest {
  margin-top: 6.25rem;
}

.blog__latest--title {
  @apply mb-6;
}

.blog__latest--wrapper {
  @apply grid;
  display: -ms-grid; /* For IE 11*/
  grid-template-columns: 1fr;
}

.blog__latest--inner {
  @apply border-solid border-1 border-grey-lightestx4 bg-grey-lightestx4 rounded-10px mb-20px;
}

.blog__posts--link {
  @apply no-underline transition duration-200;
}

.blog__posts--link--id:hover {
  @apply no-underline transition duration-200 text-orange;
}

.blog__posts--link--ed:hover {
  @apply no-underline transition duration-200 text-green;
}

.blog__posts--link--id .blog-latest__a--prev,
.blog__posts--link--ed .blog-latest__a--prev {
  @apply pointer-events-none rounded-3xl bg-transparent py-1.5 px-5 border-1 border-solid border-black-light w-32;
}

.blog__posts--link--id .blog-latest__a--prev:focus,
.blog__posts--link--ed .blog-latest__a--prev:focus {
  @apply outline-none;
}

.blog__posts--link--id:hover .blog-latest__a--prev {
  transition: border-color 0.4s;
  @apply border-orange;
}

.blog__posts--link--ed:hover .blog-latest__a--prev {
  transition: border-color 0.4s;
  @apply border-green;
}

.blog__posts {
  @apply h-full;
}

.blog-posts__image {
  height: 14rem;
  @apply rounded-t-10px object-cover bg-cover w-full bg-no-repeat bg-center;
}

.blog-posts__texts {
  max-width: fit-content;
  @apply p-30px;
}

.blog-posts__texts--date {
  @apply text-18px;
}

.blog-posts__texts--title {
  @apply font-bold leading-6 text-1xl;
}

.blog-posts__texts--preview {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @apply text-16px;
}

.blog-latest__btn {
  border-radius: 20px;
  background: transparent;
  padding: 0.3125rem 1.25rem;
  @apply bg-transparent border-1 border-solid border-grey-primary;
}

.blog-latest__btn:hover {
  background: #dcdcdb;
}

.blog-latest__a--previous {
  @apply mr-10px mb-10px no-underline;
}

.blog-latest__a--previous--disabled {
  opacity: 0.3;
  @apply pointer-events-none cursor-default;
}

.blog-latest__a--previous:hover {
  @apply no-underline;
}

.blog-latest__a--next {
  @apply ml-10px no-underline;
}

.blog-latest__a--next--disabled {
  opacity: 0.3;
  @apply pointer-events-none cursor-default;
}

.blog-latest__a--next:hover {
  @apply no-underline;
}

.blog__latest--pagination {
  margin-bottom: 6.25rem;
  @apply flex flex-wrap items-center;
}

.blog__header {
  min-height: 21.25rem;
  @apply bg-black-light relative h-full;
}

.blog__header__inner {
  @apply grid;
}

.blog__header__main {
  @apply block relative z-1;
}

.blog__header__breadcrumb {
  @apply flex items-center pt-6/25;
}

.blog__header__breadcrumb--anchor {
  @apply text-white mr-15px;
}

.blog__header__breadcrumb--anchor--id:hover {
  @apply text-orange no-underline;
}

.blog__header__breadcrumb--chevron--id {
  @apply mr-15px text-orange h-15px;
}

.blog__header__breadcrumb--title--id {
  @apply text-orange mb-0;
}

.blog__header__breadcrumb--anchor--ed:hover {
  @apply text-green no-underline;
}

.blog__header__breadcrumb--chevron--ed {
  @apply mr-15px text-green h-15px;
}

.blog__header__breadcrumb--title--ed {
  @apply text-green mb-0;
}

.blog__header__details {
  @apply text-white w-full mt-45px;
}

.blog__header__details--date {
  @apply font-normal mb-6/25;
}

.blog__header__bgImage {
  @apply absolute h-inherit w-full right-0 top-0 bg-cover bg-center;
}

.blog__preview--wrapper {
  @apply bg-orange-alt;
}

.blog__preview--wrapper--ed {
  @apply bg-green-alt;
}

.blog__preview--content {
  @apply py-50px px-0 m-0 relative;
}

.blog__content--header {
  border-bottom: 1px solid #ececec;
  @apply flex items-center justify-between mb-30px pb-20px;
}

.blog__header--inner {
  @apply flex items-center;
}

.blog__header--text {
  @apply ml-6/25;
}

.blog__header--company {
  @apply font-bold text-xl mb-1.5;
}

.blog__header--date {
  @apply mb-0;
}

.blog__header--dateMD {
  @apply flex items-center;
}

.blog__header--clock {
  @apply h-20px w-20px mr-1.5;
}

.blog__header--dot {
  height: 0.1875rem;
  width: 0.1875rem;
  @apply my-0 mx-1.5;
}

.blog__icon--wrapper {
  position: unset;
  display: flex;
}

.blog__icon--share:hover {
  @apply cursor-pointer;
}

.blog__icons {
  @apply cursor-pointer mb-6 mr-6;
}

.blog__article--wrapper img {
  @apply py-45px px-0;
}

.blog__article--wrapper ol {
  @apply mt-30px;
}

.blog__article--wrapper ol li {
  list-style: auto;
  @apply ml-30px mb-15px leading-6;
}

.blog__article--wrapper ol li strong {
  @apply block;
}

.blog__article--wrapper--id a {
  @apply no-underline text-orange;
}

.blog__article--wrapper--id a:hover {
  @apply no-underline text-orange-dark;
}

.blog__article--wrapper--ed a {
  @apply no-underline text-green;
}

.blog__article--wrapper--ed a:hover {
  @apply no-underline text-green-dark;
}

.blog__related--slider {
  @apply mb-6/25;
}

.tooltip {
  @apply relative inline-block h-full;
}

.tooltip .tooltiptext {
  width: 7.5rem;
  top: 130%;
  left: -245%;
  margin-left: -3.75rem;
  @apply invisible bg-black-light text-white text-center rounded-md py-1.5 px-0 absolute z-1;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 86%;
  margin-left: rem(-5px);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #222323 transparent;
}

.sticky-top-ie {
  @apply fixed;
}

.blog__hidden {
  @apply invisible;
}

.blog__theme--ed {
  @apply text-green;
}

.blog-posts__inner:hover .blog-ftrd__text {
  @apply text-orange;
}

.blog-posts__inner:hover .blog__theme__hover--ed {
  @apply text-green;
}

@screen sm {
  .blog__latest--wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .blog__latest--inner:nth-child(2n + 1) {
    @apply mb-10px;
    -ms-grid-column: 1;
  }

  .blog__latest--inner:nth-child(2n + 2) {
    @apply mb-10px;
    -ms-grid-column: 2;
  }

  .blog__latest--inner:nth-child(n + 3):nth-child(-n + 4) {
    -ms-grid-row: 2;
  }

  .blog__latest--inner:nth-child(n + 5):nth-child(-n + 6) {
    -ms-grid-row: 3;
  }

  .blog__latest--inner:nth-child(n + 7):nth-child(-n + 8) {
    -ms-grid-row: 4;
  }

  .blog__latest--inner:nth-child(n + 9):nth-child(-n + 10) {
    -ms-grid-row: 5;
  }

  .blog__latest--inner:nth-child(n + 11):nth-child(-n + 12) {
    -ms-grid-row: 6;
  }

  .blog__latest--inner:nth-child(n + 13):nth-child(-n + 14) {
    -ms-grid-row: 7;
  }

  .blog__latest--inner:nth-child(n + 15):nth-child(-n + 16) {
    -ms-grid-row: 8;
  }

  .blog__latest--inner:nth-child(n + 17):nth-child(-n + 18) {
    -ms-grid-row: 9;
  }

  .blog__latest--inner:nth-child(n + 19):nth-child(-n + 20) {
    -ms-grid-row: 10;
  }
}

@screen md {
  .blog__featured {
    @apply bg-gradient-to-b from-blog-black-68 to-blog-transparent-32;
  }

  .blog-featured__main {
    height: 32.5rem;
  }

  .blog-featured__wrapper {
    @apply flex-no-wrap;
  }

  .blog-featured__posts {
    height: 21.3125rem;
    @apply mr-15px;
  }

  .blog-featured__posts--left {
    @apply ml-15px mr-0;
  }

  .blog__latest--title {
    @apply mb-7;
  }

  .blog-posts__texts--title {
    line-height: 1.625rem;
  }

  .blog__header {
    min-height: 22.0625rem;
  }

  .blog__header__breadcrumb {
    @apply pt-10;
  }

  .blog__header__details {
    width: 70%;
    @apply mt-50px;
  }

  .blog__header__details--date {
    @apply mb-0;
  }

  .blog__header__bgImage {
    width: 51%;
  }

  .blog__preview--content {
    max-width: 43.75rem;
    @apply m-auto;
  }

  .blog__header--dot {
    @apply my-0 mx-10px;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .blog__header--content {
    max-width: 42.75rem;
    @apply p-0 justify-start;
  }
}

@screen lg {
  .blog__latest--wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .blog__latest--inner {
    @apply mb-50px;
  }

  .blog__latest--inner:nth-child(3n + 1) {
    margin-right: 1.4375rem;
    -ms-grid-column: 1;
  }

  .blog__latest--inner:nth-child(3n + 2) {
    margin: 0 0.71875rem 3.125rem;
    -ms-grid-column: 2;
  }

  .blog__latest--inner:nth-child(3n + 3) {
    margin-left: 1.4375rem;
    -ms-grid-column: 3;
  }

  .blog__latest--inner:nth-child(n + 4):nth-child(-n + 6) {
    -ms-grid-row: 2;
  }

  .blog__latest--inner:nth-child(n + 7):nth-child(-n + 9) {
    -ms-grid-row: 3;
  }

  .blog__latest--inner:nth-child(n + 10):nth-child(-n + 12) {
    -ms-grid-row: 4;
  }

  .blog__latest--inner:nth-child(n + 13):nth-child(-n + 15) {
    -ms-grid-row: 5;
  }

  .blog__latest--inner:nth-child(n + 16):nth-child(-n + 18) {
    -ms-grid-row: 6;
  }

  .blog__latest--inner:nth-child(n + 19):nth-child(-n + 21) {
    -ms-grid-row: 7;
  }

  .blog-posts__texts--title {
    @apply leading-7;
  }
}

@screen xl {
  .blog-featured__main {
    height: 41.25rem;
  }

  .blog-ftrd-title--primary {
    padding-top: 5rem;
  }

  .blog-featured__posts {
    height: 25.9375rem;
  }

  .blog-ftrd__text {
    @apply my-6 mx-40px;
  }

  .blog-ftrd__text--title {
    @apply text-3xl leading-9;
  }

  .blog__latest--title {
    @apply mb-12;
  }

  .blog__header {
    min-height: 28rem;
  }

  .blog__header__breadcrumb {
    padding-top: 4.375rem;
  }

  .blog__header__details {
    @apply mt-20;
  }

  .blog__preview--content {
    max-width: 50rem;
  }

  .blog__icon--sticky {
    top: 13.125rem;
    margin-bottom: 6.875rem;
    @apply sticky;
  }

  .blog__article--wrapper {
    margin-top: -5rem;
  }

  .blog__icon--wrapper {
    position: absolute;
    display: block;
    top: -6.25rem;
    left: -6.25rem;
  }
}

@media screen and (min-width: 1280px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blog__article--wrapper {
    /* IE10+ CSS */
    margin-top: 0;
  }

  .blog__icon--ie {
    /* IE10+ CSS */
    @apply absolute top-auto;
    bottom: 19.6875rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blog__icon--sticky {
    /* IE10+ CSS */
    top: 3.125rem;
    @apply mb-0;
  }

  .blog__icon--wrapper {
    /* IE10+ CSS */
    top: 3.125rem;
  }
}

@supports (-ms-accelerator: true) {
  /* Edge 12+ CSS */
  .blog__icon--sticky {
    top: 3.125rem;
    @apply mb-0;
  }

  .blog__article--wrapper {
    @apply mt-0;
  }

  .blog__icon--ie {
    /* IE10+ CSS */
    @apply absolute top-auto;
    bottom: 19.6875rem;
  }

  .blog__icon--wrapper {
    /* Edge 12+ CSS */
    top: 3.125rem;
  }
}

@supports (-ms-ime-align: auto) {
  /* Edge 16+ CSS */
  .blog__icon--sticky {
    top: 3.125rem;
    @apply mb-0;
  }

  .blog__article--wrapper {
    @apply mt-0;
  }

  .blog__icon--ie {
    /* IE10+ CSS */
    @apply absolute top-auto;
    bottom: 19.6875rem;
  }

  .blog__icon--wrapper {
    /* Edge 16+ CSS */
    top: 3.125rem;
  }
}
