.register-page__container {
  @apply my-20px w-full;
}

.register-page__container .register-page__main {
  @apply flex flex-wrap;
}

.register-page__container .register-page__main .register-page__inner {
  @apply justify-center flex-full max-w-full mx-auto;
}

.register-page__container .register-page__main .register-page__inner .form-row {
  @apply mb-6 flex flex-wrap;
}

/* For Title */
.register-page__container .register-page__main .register-page__inner .form-row .title {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  @apply px-1.5;
}

.register-page__container .register-page__main .register-page__inner .form-row .title .form-label {
  color: #222;
  @apply block w-full text-14px font-bold mb-10px;
}

.register-page__container .register-page__main .register-page__inner .form-row .title select {
  padding: .375rem .75rem;
  color: #495057;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @apply flex items-center h-14 bg-concrete border-2 border-solid border-concrete w-full text-16px font-normal leading-normal;
}

.register-page__container .register-page__main .register-page__inner .form-row .title select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

/* For Name */
.register-page__container .register-page__main .register-page__inner .form-row .name {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  @apply px-1.5;
}

.register-page__container .register-page__main .register-page__inner .form-row .name--alt {
  flex: 0 0 100%;
  max-width: 100%;
  @apply px-1.5;
}

.register-page__container .register-page__main .register-page__inner .form-row .name .form-label,
.register-page__container .register-page__main .register-page__inner .form-row .name--alt .form-label {
  color: #222;
  @apply block w-full text-14px font-bold mb-10px;
}

.register-page__container .register-page__main .register-page__inner .form-row .name input,
.register-page__container .register-page__main .register-page__inner .form-row .name--alt input {
  padding: .375rem .75rem;
  color: #495057;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @apply flex items-center h-14 bg-concrete border-2 border-solid border-concrete w-full text-16px font-normal leading-normal;
}

.register-page__container .register-page__main .register-page__inner .form-row .name input.invalid,
.register-page__container .register-page__main .register-page__inner .form-row .name--alt input.invalid {
  background: #e6e6e6 url(/Images/invalid.svg) no-repeat 95% center;
  background-size: 10px;
  @apply border-2 border-solid border-red;
}

.register-page__container .register-page__main .register-page__inner .form-row .name input.valid,
.register-page__container .register-page__main .register-page__inner .form-row .name--alt input.valid {
  background: #e6e6e6 url(/Images/valid.svg) no-repeat 95% center;
  background-size: 10px;
  @apply border-2 border-solid border-green-pitch;
}

.register-page__container .register-page__main .register-page__inner .form-row .name input:focus,
.register-page__container .register-page__main .register-page__inner .form-row .name--alt input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  background: #e6e6e6;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages {
  @apply px-1.5 flex-full max-w-full w-full;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification {
  width: 90%;
  @apply bg-error flex items-center rounded-5px p-0 mt-10px mx-auto mb-0;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--info {
  width: 90%;
  @apply bg-blue-link flex items-center rounded-5px p-0 mt-10px mx-auto mb-0;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--valid {
  width: 90%;
  @apply bg-green-pitch flex items-center rounded-5px p-0 mt-10px mx-auto mb-0;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--warning {
  width: 90%;
  background-color: #ffc324;
  @apply flex items-center rounded-5px p-0 mt-10px mx-auto mb-0;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--warning .icon svg {
  width: .6875em;
  color: #222;
  height: 1em;
  @apply overflow-visible flex items-center justify-center my-0 mx-auto text-22px;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--warning .description {
  width: 85%;
  color: #222;
  border-left: 1px solid #222;
  @apply text-12px font-bold py-1.5 px-20px my-2 mx-0;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification .icon,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--info .icon,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--valid .icon,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--warning .icon {
  width: 15%;
  @apply text-center;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification .icon svg,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--info .icon svg,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--valid .icon svg {
  width: .6875em;
  height: 1em;
  @apply overflow-visible flex items-center justify-center my-0 mx-auto text-22px text-white;
}

.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification .description,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--info .description,
.register-page__container .register-page__main .register-page__inner .form-row .messages .form-verification--valid .description {
  width: 85%;
  @apply text-12px text-white font-bold py-1.5 px-20px my-2 mx-0 border-l-1 border-solid border-white;
}

.register-page__container .register-page__main .register-page__inner .form-row .details {
  flex: 0 0 100%;
  max-width: 100%;
  @apply px-1.5;
}

.register-page__container .register-page__main .register-page__inner .form-row .details .form-label {
  color: #222;
  @apply block w-full text-14px font-bold mb-10px;
}

.register-page__container .register-page__main .register-page__inner .form-row .details .form-label .form-checkbox {
  @apply bg-concrete rounded-3px w-20px h-20px p-0 border-2 border-solid border-transparent;
}

.register-page__container .register-page__main .register-page__inner .form-row .details .form-label .form-checkbox.checked {
  background: #e6e6e6 url(/Images/valid.svg) no-repeat 50% center;
  background-size: 10px;
  @apply border-2 border-solid border-green-pitch;
}

.register-page__container .register-page__main .register-page__inner .form-row .details .form-btn {
  @apply h-12 w-full mb-10px bg-concrete flex items-center justify-center rounded-3px text-14px;
}

.register-page__container .register-page__main .register-page__inner .form-row .details .form-btn.checked {
  background: #e6e6e6 url(/Images/valid.svg) no-repeat 90% center;
  background-size: 10px;
  @apply border-2 border-solid border-green-pitch;
}

.register-page__container .register-page__main .register-page__inner .form-row .details input {
  padding: .375rem .75rem;
  color: #495057;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @apply flex items-center h-14 bg-concrete border-2 border-solid border-concrete w-full text-16px font-normal leading-normal;
}

.register-page__container .register-page__main .register-page__inner .form-row .details input.invalid {
  background: #e6e6e6 url(/Images/invalid.svg) no-repeat 95% center;
  background-size: 10px;
  @apply border-2 border-solid border-red;
}

.register-page__container .register-page__main .register-page__inner .form-row .details input.valid {
  background: #e6e6e6 url(/Images/valid.svg) no-repeat 95% center;
  background-size: 10px;
  @apply border-2 border-solid border-green-pitch;
}

.register-page__container .register-page__main .register-page__inner .form-row .details input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  background: #e6e6e6;
}

@screen md {
  .register-page__container {
    @apply mt-6;
  }

  .register-page__container .register-page__main .register-page__inner .form-row .title {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .register-page__container .register-page__main .register-page__inner .form-row .name,
  .register-page__container .register-page__main .register-page__inner .form-row .name--alt {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .register-page__container .register-page__main .register-page__inner .form-row .details .form-btn {
    @apply h-14 w-1/2 float-left px-1.5 mr-20px;
  }
}

@screen lg {
  .register-page__container {
    @apply my-30px;
  }

  .register-page__container .register-page__main .register-page__inner {
    @apply max-w-1/2;
  }

  .register-page__container .register-page__main .register-page__inner .form-row .details .form-btn {
    width: 11.25rem;
    @apply float-left px-0 align-top;
  }
}