/* .firerated a {
  @apply text-blackBG;
} */

.firerated a:hover,
.firerated a[href^='tel'] {
  color: #222;
  @apply no-underline;
}

.firerated h1,
.firerated h2,
.firerated h3,
.firerated h4,
.firerated h5,
.firerated h6 {
  @apply mb-15px;
}

.firerated h1:last-child,
.firerated h2:last-child,
.firerated h3:last-child,
.firerated h4:last-child,
.firerated h5:last-child,
.firerated h6:last-child {
  @apply mb-0;
}

.firerated h1 {
  @apply text-50px leading-none;
}

.firerated h1 strong {
  color: #ffc324;
}

.firerated h2 {
  @apply text-26px;
}

.firerated h3 {
  @apply text-20px;
}

.firerated h4 {
  @apply text-16px;
}

.firerated h5 {
  @apply text-14px;
}

.firerated h6 {
  @apply text-12px;
}

.firerated p,
.firerated ul,
.firerated ol {
  @apply text-20px;
}

.firerated p:last-child,
.firerated ul:last-child,
.firerated ol:last-child {
  @apply mb-0;
}

.firerated .clear {
  content: '';
  @apply table clear-both leading-none;
}

.firerated .notext {
  text-shadow: none;
  @apply text-transparent bg-transparent border-0;
}

.firerated .alignleft {
  @apply float-left;
}

.firerated .alignright {
  @apply float-right;
}

.firerated [disabled],
.firerated .disabled {
  @apply cursor-default;
}

.firerated .col {
  flex: 1;
  @apply max-w-full;
}

.firerated .cols {
  flex-flow: row wrap;
  @apply flex;
}

.firerated .col--1of2 {
  @apply max-w-1/2 flex-1/2;
}

.firerated .visible-xs-block,
.firerated .visible-xs-inline,
.firerated .visible-xs-inline-block,
.firerated .visible-sm-block,
.firerated .visible-sm-inline,
.firerated .visible-sm-inline-block,
.firerated .visible-md-block,
.firerated .visible-md-inline,
.firerated .visible-md-inline-block,
.firerated .visible-lg-block,
.firerated .visible-lg-inline,
.firerated .visible-lg-inline-block {
  @apply hidden;
}

.firerated .shell {
  max-width: 1216px;
  @apply m-auto px-4.5;
}

.firerated .shell--fluid {
  @apply max-w-none;
}

.firerated .button {
  margin-top: 35px;
  transition: all 0.25s linear;
  height: 38px;
  @apply w-full text-black-lighter text-center inline-flex text-10px leading-none font-bold uppercase self-start items-center justify-center no-underline cursor-pointer;
}

.firerated .button.btn-pill {
  letter-spacing: 0.84px;
}

.firerated .button.btn-rounded {
  background-color: #ffc324;
  border: 2px solid #ffc324;
  letter-spacing: 0.14px;
  @apply text-blue-link;
}

.firerated .button.btn-rounded:hover {
  color: #ffc324;
  @apply bg-transparent;
}

.firerated [class^='list-'] {
  list-style: none outside none;
}

.firerated .list-bullets li {
  padding-left: 25px;
  @apply relative mb-20px;
}

.firerated .list-bullets li svg {
  top: 2px;
  width: 14px;
  @apply text-green-pitch absolute left-0 text-14px;
}

.firerated .list-bullets li:last-child {
  @apply mb-0;
}

.firerated .nav-secondary ul {
  margin: 0 -23px;
  @apply flex flex-no-wrap items-center justify-center;
}

.firerated .nav-secondary li {
  padding: 0 23px;
  color: #222;
  @apply text-18px leading-none;
}

.firerated .nav-secondary button {
  @apply leading-24px;
}
.firerated .nav-secondary a,
.firerated .nav-secondary button {
  @apply relative no-underline;
}

.firerated .nav-secondary a:after,
.firerated .nav-secondary button:after {
  content: '';
  transition: width 0.3s;
  @apply bg-current absolute left-0 bottom-0 w-0;
}

.firerated .nav-secondary a:hover:after,
.firerated .nav-secondary button:hover:after {
  @apply w-full;
}

.firerated .section {
  padding: 40px 0;
}

.firerated .section__head {
  margin-bottom: 26px;
  text-align: center;
}

.firerated .section__head h2 {
  @apply font-normal;
}

.firerated .section-intro {
  height: 377px;
  @apply table relative w-full;
}

.firerated .section-intro .section__image {
  background-position: center center;
  @apply absolute top-0 right-0 left-0 bottom-0 bg-cover bg-no-repeat;
}

.firerated .section-intro .section__image:after {
  content: '';
  background-color: rgba(34, 34, 34, 0.9);
  @apply absolute top-0 right-0 bottom-0 left-0;
}

.firerated .section-intro .section__body {
  line-height: 1.15;
  @apply z-1 relative table-cell align-middle py-20px px-0 text-26px text-white;
}

.firerated .section-intro .section__body h1 {
  margin-bottom: 19px;
}

.firerated .section-intro .section__body .btn-down {
  bottom: 10px;
  transform: translateX(-50%);
  @apply absolute left-1/2;
}

.firerated .section-fullwidth {
  min-height: 700vh;
  @apply relative;
}

.firerated .section-fullwidth .section__body.fixed {
  @apply fixed top-0 left-0 right-0 bottom-0;
}

.firerated .section-fullwidth .section__body.bottom {
  @apply absolute top-auto left-0 right-0 bottom-0;
}

.firerated .slider--vertical {
  @apply h-screen overflow-hidden relative;
}

.firerated .slider--vertical .slider__slide {
  transition: opacity 0.3s, visibility 0.3s;
  @apply absolute top-0 left-0 w-full h-screen flex items-center opacity-0 invisible;
}

.firerated .slider--vertical .slider__slide-image {
  flex: 0 1 50%;
  @apply order-2 max-w-1/2 text-left pl-30px;
}

.firerated .slider--vertical .slider__slide-content {
  flex: 0 1 50%;
  @apply flex flex-wrap items-center order-1 max-w-1/2 pr-30px bg-white;
}

.firerated .slider--vertical .slider__slide .entry {
  transform: translateY(100vh);
  transition: transform 0.7s;
}

.firerated .slider--vertical .slider__slide .entry__head {
  transform: translateY(100vh);
  transition: transform 0.7s;
  transition-delay: 0.6s;
}

.firerated .slider--vertical .slider__slide .entry__body {
  transform: translateY(100vh);
  transition: transform 0.7s;
  transition-delay: 0.8s;
}

.firerated .slider--vertical .slider__slide .entry .list-bullets li:after {
  transform: translateY(100px);
  transition-delay: 10s;
}

.firerated .slider--vertical .slider__slide .entry .btn {
  transform: translateY(100vh);
  transition: transform 0.7s;
  transition-delay: 0.6s;
}

.firerated .slider--vertical .slider__slide .entry .btn span {
  transform: translateY(100vh);
  transition: transform 0.7s;
  transition-delay: 0.8s;
}

.firerated .slider--vertical .slider__slide.active {
  @apply opacity-100 visible;
}

.firerated .slider--vertical .slider__slide.active .entry {
  transform: translateY(0);
}

.firerated .slider--vertical .slider__slide.active .entry__head,
.firerated .slider--vertical .slider__slide.active .entry__body {
  transform: translateY(0);
}

.firerated .slider--vertical .slider__slide.active .entry .btn {
  transform: translateY(0);
}

.firerated .slider--vertical .slider__slide.active .entry .btn span {
  transform: translateY(0);
}

.firerated .slider--vertical .slider__slide.active .entry .list-bullets li:after {
  transform: translateY(0);
}

.firerated .slider--vertical .owl-prev,
.firerated .slider--vertical .owl-next {
  top: 140px;
  @apply absolute w-30px h-30px text-center cursor-pointer;
}

.firerated .slider--vertical .owl-prev span,
.firerated .slider--vertical .owl-next span {
  font-size: 0;
  border-width: 2px 2px 0 0;
  @apply inline-block align-middle w-10px h-10px border-solid border-black pointer-events-none;
}

.firerated .slider--vertical .owl-prev {
  @apply left-0;
}

.firerated .slider--vertical .owl-prev span {
  transform: rotate(-135deg);
}

.firerated .slider--vertical .owl-next {
  @apply right-0;
}

.firerated .slider--vertical .owl-next span {
  transform: rotate(45deg);
}

.firerated .slider--vertical .owl-dots,
.firerated .slider--vertical .slider__dots {
  left: 505px;
  transform: translateY(-50%);
  @apply absolute top-1/2 list-none;
}

.firerated .slider--vertical .owl-dots .owl-dot,
.firerated .slider--vertical .slider__dots .owl-dot,
.firerated .slider--vertical .owl-dots .m-owl-dot,
.firerated .slider--vertical .slider__dots .m-owl-dot {
  padding: 7px;
}

.firerated .slider--vertical .owl-dots .owl-dot button,
.firerated .slider--vertical .slider__dots .owl-dot button,
.firerated .slider--vertical .slider__dots .owl-dot button,
.firerated .slider--vertical .owl-dots .m-owl-dot button,
.firerated .slider--vertical .slider__dots .m-owl-dot button,
.firerated .slider--vertical .slider__dots .m-owl-dot button {
  border-radius: 50%;
  background: #d8d8d8;
  @apply block w-10px h-10px cursor-pointer;
}

.firerated .slider--vertical .owl-dots .owl-dot.active button,
.firerated .slider--vertical .slider__dots .owl-dot.active button,
.firerated .slider--vertical .owl-dots .m-owl-dot.active button,
.firerated .slider--vertical .slider__dots .m-owl-dot.active button {
  background: #222;
}
.firerated .slider--vertical.scrolling-down .slider__slide.old .entry {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.old .entry__head,
.firerated .slider--vertical.scrolling-down .slider__slide.old .entry__body {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.old .entry .btn {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.old .entry .btn span {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.old .entry .list-bullets li:after {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.new .entry {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.new .entry__head,
.firerated .slider--vertical.scrolling-down .slider__slide.new .entry__body {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.new .entry .btn {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.new .entry .btn span {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-down .slider__slide.new .entry .list-bullets li:after {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.old .entry {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.old .entry__head,
.firerated .slider--vertical.scrolling-up .slider__slide.old .entry__body {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.old .entry .btn {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.old .entry .btn span {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.old .entry .list-bullets li:after {
  transform: translateY(100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.new .entry {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.new .entry__head,
.firerated .slider--vertical.scrolling-up .slider__slide.new .entry__body {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.new .entry .btn {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.new .entry .btn span {
  transform: translateY(-100vh);
}

.firerated .slider--vertical.scrolling-up .slider__slide.new .entry .list-bullets li:after {
  transform: translateY(-100vh);
}

.firerated .carousel button.owl-dot,
.firerated .carousel button.m-owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.firerated .slider--vertical .owl-dots .owl-dot span,
.firerated .slider--vertical .owl-dots .m-owl-dot span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d8d8d8;
  @apply block cursor-pointer;
}

.firerated .slider--vertical .owl-dots .owl-dot.active span,
.firerated .slider--vertical .owl-dots .m-owl-dot.active span {
  background: #222;
}

.firerated .owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.firerated .bar {
  padding: 20px 0;
  @apply text-center bg-white-darker;
}

.firerated .callout {
  padding: 0 80px 65px 80px;
  @apply bg-blue-link relative my-0 mx-auto text-14px text-white overflow-hidden;
}

.firerated .callout__image {
  top: -61px;
  right: -30px;
  width: 406px;
  height: 500px;
  background-position: center center;
  @apply absolute bg-cover bg-no-repeat;
}

.firerated .callout__body {
  @apply max-w-full;
}

.firerated .callout__body h1 {
  @apply font-black mb-0;
}

.firerated .callout__body .btn {
  margin-top: 45px;
}

.firerated .callout__body .button {
  height: 38px;
  @apply text-center inline-flex text-10px font-bold leading-none w-full self-start items-center justify-center no-underline cursor-pointer rounded-3px uppercase;
}

.firerated .categories {
  padding-top: 20px;
  padding-bottom: 80px;
  @apply w-full px-0;
}

.firerated .categories--heading {
  letter-spacing: 0.21px;
  margin-top: 80px;
  margin-bottom: 35px;
  color: #222;
  @apply text-36px;
}

.firerated .categories-inner {
  @apply flex items-center flex-wrap w-full;
}

.firerated .categories--item {
  width: calc(96% / 2);
  border-radius: 10px;
  @apply border-1 border-concrete;
}

.firerated .categories--item:hover .categories--item-content {
  @apply text-orange;
}

.firerated .categories--item-link {
  @apply block w-full h-full rounded-10px overflow-hidden bg-concrete;
}

.firerated .categories--item-link:hover {
  @apply no-underline;
}

.firerated .categories--item-img {
  background-color: white;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  @apply block bg-no-repeat;
}

.firerated .categories--item-content {
  @apply p-5;
}

.firerated .categories--item-title {
  color: #222;
  @apply font-bold text-18px leading-6 mb-2;
}

.firerated .entry {
  max-width: 500px;
  padding: 25px 60px 35px 25px;
  line-height: 1.43;
  @apply rounded-3px bg-white-darker;
}

.firerated .entry h2 {
  padding-right: 33px;
  letter-spacing: -0.02em;
  @apply inline-block relative leading-none;
}

.firerated .entry h2 strong {
  bottom: 2px;
  padding: 0 2px;
  letter-spacing: 0.17em;
  @apply hidden absolute right-0 text-12px font-normal text-grey-mono;
}

.firerated .entry ul {
  @apply mb-0;
}

.firerated .entry__head {
  @apply mb-20px;
}

.firerated .entry .btn {
  margin-top: 35px;
}

.firerated .entry .btn + .btn {
  @apply mt-15px;
}

.firerated .entry--block {
  @apply hidden;
}

.entry__go-down {
  width: 14px;
  color: #222;
  animation: go__down 1s infinite;
  @apply block text-16px;
}

.entry__go-down-text {
  margin: 20px 0 10px;
  letter-spacing: 2px;
  color: #222;
  @apply block text-12px font-bold uppercase;
}

@keyframes go__down {
  from {
    transform: translateY(0);
    @apply opacity-0;
  }
  to {
    transform: translateY(10px);
    @apply opacity-100;
  }
}

.hero-header {
  padding: 92px 0 112px;
  background-position: center center;
  @apply relative w-full box-border bg-blackBG bg-cover bg-no-repeat;
}

.hero-header .hero-header-inner {
  @apply flex items-center;
}

.hero-header .hero-header-inner .left-heading {
  @apply flex-1/2 max-w-1/2;
}

.hero-header .hero-header-inner .left-heading h1 {
  line-height: 1.07;
  @apply text-50px text-white font-bold;
}

.hero-header .hero-header-inner .left-heading h1 span {
  color: #ffc324;
  @apply block;
}

.hero-header .hero-header-inner .left-heading p {
  letter-spacing: 0.4px;
  @apply text-22px text-white font-normal;
}

.hero-header .hero-header-inner .right-heading {
  backface-visibility: hidden;
  @apply flex-1/2 max-w-1/2 text-center;
}

.hero-header .hero-header-inner .right-heading a {
  @apply block;
}

.hero-header .hero-header-inner .right-heading a img {
  width: 160px;
}

.hero-header .hero-header-inner .right-heading a p {
  font-size: 21px;
  @apply text-white font-bold uppercase mt-10px;
}

.hero-header .hero-header-inner .right-heading a p span {
  font-size: 15px;
  @apply text-white block font-light no-underline;
}

.hero-header .hero-header-go-down {
  @apply absolute left-0 bottom-0 w-full py-15px px-0 text-center;
}

.hero-header .hero-header-go-down a {
  @apply inline-block;
}

.hero-header .hero-header-go-down a svg {
  fill: #fff;
  color: #fff;
  font-size: 25px;
}

.ed .hero-header {
  padding: 92px 0 112px;
  background-position: center center;
  @apply relative w-full box-border bg-blackBG bg-no-repeat bg-cover;
}

.ed .hero-header .hero-header-inner {
  @apply flex items-center;
}

.ed .hero-header .hero-header-inner .left-heading {
  @apply flex-1/2 max-w-1/2;
}

.ed .hero-header .hero-header-inner .left-heading h1 {
  line-height: 1.07;
  @apply text-50px font-bold text-white;
}

.ed .hero-header .hero-header-inner .left-heading h1 span {
  color: #8cc747;
  @apply block;
}

.ed .hero-header .hero-header-inner .left-heading p {
  letter-spacing: 0.4px;
  @apply text-22px text-white font-normal;
}

.ed .hero-header .hero-header-inner .right-heading {
  backface-visibility: hidden;
  @apply flex-1/2 max-w-1/2 text-center;
}

.ed .hero-header .hero-header-inner .right-heading a {
  @apply block;
}

.ed .hero-header .hero-header-inner .right-heading a img {
  width: 160px;
}

.ed .hero-header .hero-header-inner .right-heading a p {
  font-size: 21px;
  @apply text-white font-bold uppercase mt-10px;
}

.ed .hero-header .hero-header-inner .right-heading a p span {
  font-size: 15px;
  @apply block font-light text-white no-underline;
}

.ed .hero-header .hero-header-go-down {
  @apply absolute left-0 bottom-0 w-full py-15px px-0 text-center;
}

.ed .hero-header .hero-header-go-down a {
  @apply inline-block;
}

.ed .hero-header .hero-header-go-down a svg {
  fill: #fff;
  color: #fff;
  font-size: 25px;
}

@media (max-width: 767px) {
  .firerated h1 {
    @apply text-30px;
  }
  .firerated .hidden-xs {
    @apply hidden;
  }
  .firerated .visible-xs-block {
    @apply block;
  }
  .firerated .visible-xs-inline {
    @apply inline;
  }
  .firerated .visible-xs-inline-block {
    @apply inline-block;
  }
  .firerated .section {
    padding: 24px 0 50px;
  }
  .firerated .section__head {
    margin-bottom: 20px;
  }
  .firerated .section--callout {
    padding: 0;
  }
  .firerated .section--callout .callout {
    max-width: calc(100% + 36px);
    margin: 0 -18px;
  }
  .firerated .section-intro {
    height: 247px;
  }
  .firerated .section-intro .section__body {
    padding: 20px 0 50px;
    @apply text-20px text-center leading-tight;
  }
  .firerated .section-intro .section__body h1 {
    margin-bottom: 7px;
    letter-spacing: -0.02em;
  }
  .firerated .section-intro .section__body h1 br {
    @apply hidden;
  }
  .firerated .section-fullwidth {
    min-height: auto;
  }
  .firerated .section-fullwidth .section__body .entry--block {
    max-width: calc(100% + 36px);
    margin: 0 -18px;
  }
  .firerated .slider--vertical {
    @apply h-auto border-b-1 border-solid border-grey-mono;
  }
  .firerated .slider--vertical .slider__slide {
    @apply relative flex flex-wrap items-start justify-center h-auto py-20px px-0 opacity-100 visible;
  }
  .firerated .slider--vertical .slider__slide-image {
    flex: 0 1 183px;
    max-width: 183px;
    @apply order-1 text-center pl-0;
  }
  .firerated .slider--vertical .slider__slide-content {
    flex: 0 1 100%;
    @apply block items-start order-2 max-w-full pr-0;
  }
  .firerated .slider--vertical .slider__slide-content .entry {
    transform: translateY(0);
    background: transparent;
  }
  .firerated .slider--vertical .slider__slide-content .entry__head {
    transform: translateY(0);
  }
  .firerated .slider--vertical .slider__slide-content .entry__body {
    transform: translateY(0);
  }
  .firerated .slider--vertical .slider__slide-content .entry .list-bullets li:after {
    transform: translateY(100px);
  }
  .firerated .slider--vertical .slider__slide-content .entry .btn {
    transform: translateY(0);
  }
  .firerated .slider--vertical .slider__slide-content .entry .btn span {
    transform: translateY(0);
  }
  .firerated .slider--vertical .slider__dots {
    display: none;
  }
  .firerated .slider--vertical .owl-dots {
    top: 258px;
    transform: translateY(0);
    font-size: 0;
    line-height: 0;
    @apply absolute left-0 right-0 z-1 text-center;
  }
  .firerated .slider--vertical .owl-dots .owl-dot,
  .firerated .slider--vertical .owl-dots .m-owl-dot {
    padding: 5px !important;
    @apply inline-block align-middle;
  }
  .firerated .slider--vertical .owl-dots .owl-dot button,
  .firerated .slider--vertical .owl-dots .m-owl-dot button {
    width: 6px;
    height: 6px;
  }
  .firerated .bar {
    @apply hidden;
  }
  .firerated .callout {
    padding: 45px 33px;
    @apply max-w-full rounded-none text-14px text-center;
  }
  .firerated .callout__image {
    @apply hidden;
  }
  .firerated .callout__body h1 {
    @apply text-26px;
  }
  .firerated .callout__body .btn {
    @apply mt-20px;
  }
  .firerated .categories--item:not(:nth-child(even)) {
    margin-right: 4%;
  }
  .firerated .categories--item:not(:nth-child(n + 7)) {
    margin-bottom: 15px;
  }
  .firerated .entry {
    padding: 35px 5px 12px;
    @apply max-w-full;
  }
  .firerated .entry h2 strong {
    @apply inline-block;
  }
  .firerated .entry__head {
    margin-bottom: 25px;
  }
  .firerated .entry--block {
    padding: 24px 20px 27px;
    @apply max-w-full block text-center;
  }
  .firerated .entry--block h2 {
    @apply mb-10px;
  }
  .hero-header .hero-header-inner .left-heading h1 {
    @apply text-36px;
  }
  .ed .hero-header .hero-header-inner .left-heading h1 {
    @apply text-36px;
  }
}

@media (max-width: 991px) {
  .firerated .nav-secondary li {
    font-size: 16px;
  }
  .firerated .bar {
    padding: 21px 0 16px;
  }
  .firerated .callout {
    padding: 45px 40px;
  }
  .firerated .callout__image {
    width: 356px;
    height: 457px;
  }
  .firerated .callout__body h1 {
    font-size: 45px;
  }
  .firerated .entry {
    padding: 25px 25px 35px;
  }
  .hero-header {
    padding: 40px 0 50px;
  }
  .hero-header .hero-header-inner {
    @apply flex-col;
  }
  .hero-header .hero-header-inner .left-heading {
    @apply mb-40px;
  }
  .hero-header .hero-header-inner .left-heading h1 {
    @apply text-40px;
  }
  .hero-header .hero-header-inner .left-heading,
  .hero-header .hero-header-inner .right-heading {
    @apply max-w-full w-full text-center;
  }
  .hero-header-img {
    max-width: 300px;
    @apply w-full;
  }
  .ed .hero-header {
    padding: 40px 0 50px;
  }
  .ed .hero-header .hero-header-inner {
    @apply flex-col;
  }
  .ed .hero-header .hero-header-inner .left-heading {
    @apply mb-40px;
  }
  .ed .hero-header .hero-header-inner .left-heading h1 {
    @apply text-40px;
  }
  .ed .hero-header .hero-header-inner .left-heading,
  .ed .hero-header .hero-header-inner .right-heading {
    @apply max-w-full w-full text-center;
  }
  .ed .hero-header-img {
    max-width: 300px;
    @apply w-full;
  }
}

@media (min-width: 768px) {
  .firerated .button {
    @apply w-auto text-14px;
  }
  .firerated .callout__body .button {
    @apply text-12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .firerated .slider--vertical .slider__slide-image img {
    @apply w-full;
  }
}

@media (min-width: 768px) {
  .firerated .categories--item {
    width: calc(94% / 3);
  }
  .firerated .categories--item:not(:nth-child(3)):not(:nth-child(6)):not(:nth-child(9)) {
    margin-right: 2%;
  }
  .firerated .categories--item {
    margin-bottom: 36px;
  }
  /* .firerated .categories--item-img {
    height: 130px;
  } */
}

@media (min-width: 768px) and (max-width: 1023px) {
  .firerated .hidden-sm {
    @apply hidden;
  }
  .firerated .visible-sm-block {
    @apply block;
  }
  .firerated .visible-sm-inline {
    @apply inline;
  }
  .firerated .visible-sm-inline-block {
    @apply inline-block;
  }
}

@media (min-width: 992px) {
  .firerated .callout__body .button {
    @apply w-auto py-0 px-20px my-0 mx-auto text-14px;
  }
  .firerated .button {
    min-width: 149px;
  }
  .firerated .slider--vertical .slider__slide-image img {
    height: 600px;
  }
  /* .firerated .categories--item {
    width: calc(94% / 4);
  }
  .firerated .categories--item:not(:nth-child(4)):not(:nth-child(8)) {
    margin-right: 2%;
  }
  .firerated .categories--item:not(:nth-child(n + 5)) {
    margin-bottom: 20px;
  } */
}

@media (max-width: 1023px) {
  .firerated .nav-secondary ul {
    margin: 0 -6px;
  }
  .firerated .nav-secondary li {
    padding: 0 6px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .firerated .hidden-md {
    @apply hidden;
  }
  .firerated .visible-md-block {
    @apply block;
  }
  .firerated .visible-md-inline {
    @apply inline;
  }
  .firerated .visible-md-inline-block {
    @apply inline-block;
  }
}

@media (max-width: 1200px) {
  .firerated .nav-secondary ul {
    margin: 0 -10px;
  }
  .firerated .nav-secondary li {
    padding: 0 10px;
  }
  .firerated .slider--vertical .slider__dots {
    left: calc((100vw / 2) - 40px);
  }
}

@media (min-width: 1201px) {
  .firerated .hidden-lg {
    @apply hidden;
  }
  .firerated .visible-lg-block {
    @apply block;
  }
  .firerated .visible-lg-inline {
    @apply inline;
  }
  .firerated .visible-lg-inline-block {
    @apply inline-block;
  }
}

@media (max-width: 1250px) {
  .firerated .nav-secondary ul {
    margin: 0 -15px;
  }
  .firerated .nav-secondary li {
    padding: 0 15px;
  }
}
