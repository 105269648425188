#nprogress .bar {
  top: 0px !important;
  height: 5px !important;
  /* temporary */
  /* top: 190px !important; */
}

#nprogress .peg {
  -webkit-transform: rotate(0deg) translate(0px, 0px) !important;
  -ms-transform: rotate(0deg) translate(0px, 0px) !important;
  transform: rotate(0deg) translate(0px, 0px) !important;
}

/* @screen md {
  #nprogress .bar {
    top: 91px !important;
    temporary
    top: 135px !important;
  }
} */

/* @screen xl {
#nprogress .bar {
top: 205px !important;
temporary
top: 135px !important;
 }
} */
