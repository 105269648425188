.detailsAddress {
  top: 1.5625rem;
  left: 1.5625rem;
}

.contact__header {
  height: 15.625rem;
  width: 100%;
  background-color: #222323;
  position: relative;
  z-index: 0;
}

@media (min-width: 48em) {
  .contact__header {
    height: 18.875rem;
  }
}

.contact__header .content-header__wrapper {
  padding-top: 2.5rem;
  width: 100%;
}

@media (min-width: 36em) {
  .contact__header .content-header__wrapper {
    padding-top: 3.4375rem;
    width: 55%;
  }
}

@media (min-width: 48em) {
  .contact__header .content-header__wrapper {
    padding-top: 4.375rem;
    width: 45%;
  }
}

@media (min-width: 64em) {
  .contact__header .content-header__wrapper {
    width: 65%;
  }
}

@media (min-width: 90em) {
  .contact__header .content-header__wrapper {
    width: 45%;
  }
}

.contact__header .content-header__wrapper .content__header {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.875rem;
}

.contact__header .content-header__wrapper .content__header {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.875rem;
}

@media (min-width: 48em) {
  .contact__header .content-header__wrapper .content__header {
    font-size: 3.75rem;
    line-height: 4.125rem;
  }
}

.contact__header .content-header__wrapper .content__subHeader {
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.contact__header .contact__img {
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  position: absolute;
}

@media (min-width: 48em) {
  .contact__header .contact__img {
    width: 76%;
  }
}

@media (min-width: 62em) {
  .contact__header .contact__img {
    width: 51%;
  }
}

/* label[for="enquiryType"] {
  margin: 10px;
} */
