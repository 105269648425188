@import url('./components/main.css');
@import url('./pages/main.css');
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}
.fa-layers svg.svg-inline--fa {
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.fa-layers-text,
.fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.fa-layers-counter {
  background-color: #ff253a;
  border-radius: 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  height: 1.5em;
  line-height: 1;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: 0.25em;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: 0;
  right: 0;
  top: auto;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.fa-layers-top-right {
  right: 0;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.fa-layers-top-left {
  left: 0;
  right: auto;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

.fa-inverse {
  color: #fff;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black;
}

.fad.fa-inverse {
  color: #fff;
}

@tailwind base;
@tailwind components;

@font-face {
  font-family: 'SofiaPro';
  font-weight: 400;
  src: url('/fonts/sofiapro-regular-webfont.woff2') format('woff2'),
    url('/fonts/sofiapro-regular-webfont.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SofiaPro';
  font-weight: 700;
  src: url('/fonts/sofiapro-bold-webfont.woff2') format('woff2'),
    url('/fonts/sofiapro-bold-webfont.woff') format('woff');
  font-display: swap;
}

.mapboxgl-map {
  border-radius: 12px;
}

/* focus-visible > input:focus ~ label,
input:not(:-ms-input-placeholder) ~ label {
  transform: scaleX(.75) scaleY(.75) translateY(-2rem) translateX(-0.15rem);
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  transform: scaleX(.75) scaleY(.75) translateY(-2rem) translateX(-0.15rem);
} */

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate--two {
  -webkit-line-clamp: 2;
}

.truncate--four {
  -webkit-line-clamp: 4;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .summaryOpen {
    transform: translateX(-29.375rem);
  }
  .summaryClosed {
    transform: translateX(29.375rem);
  }

  footer {
    display: inline-table;
  }
}

@tailwind utilities;

/* Default style for text is 16px Size and #242424 Color */
@layer base {
  body {
    @apply text-black-lighter text-16px relative;
  }

  h1 {
    @apply font-bold text-34px leading-40px;
  }

  h2 {
    @apply font-bold text-30px leading-36px;
  }

  h3 {
    @apply font-bold text-20px leading-26px;
  }

  h4 {
    @apply font-bold text-18px leading-24px;
  }

  @screen md {
    h1 {
      @apply text-40px leading-50px;
    }

    h2 {
      @apply text-30px leading-36px;
    }

    h3 {
      @apply text-24px leading-30px;
    }

    h4 {
      @apply text-20px leading-26px;
    }
  }

  @screen xl {
    h1 {
      @apply text-60px leading-70px;
    }

    h2 {
      @apply text-40px leading-46px;
    }

    h3 {
      @apply text-22px leading-30px;
    }

    h4 {
      @apply text-18px leading-24px;
    }
  }

  .theme-id {
    --color-primary: #efa847;
    --color-primary-dark: #ec9a2a;
  }

  .theme-ed {
    --color-primary: #c7d402;
    --color-primary-dark: #b3be01;
  }

  input:focus {
    @apply outline-none;
  }

  button:focus {
    @apply outline-none;
  }
}

/* Wrapper Component For all Pages for uniformity of spacing */
@layer components {
  .root-container {
    @apply max-w-pageContent my-0 mx-auto py-0 px-20px box-border;
  }

  .root-aside {
    @apply hidden m-0 h-24 self-end justify-center items-center;
    /* For IE */
    -ms-grid-column: 1;
    grid-column: 1;
    grid-row: 1;
    -ms-grid-row: 1;
    align-self: end;
    -ms-grid-row-align: end;
    pointer-events: all;
    -ms-grid-row-align: center;
    align-self: center;
  }

  @screen md {
    .root-container {
      @apply py-0 px-30px;
    }
  }

  @screen lg {
    .root-container {
      @apply py-0 px-50px;
    }
  }

  @screen 1192px {
    .root-aside {
      @apply flex;
    }
  }
}
