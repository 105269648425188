.about-main {
  @apply px-0 flex-content ml-0;
}

.about-main__desc {
  @apply mb-20px font-normal text-14px leading-5;
}

.about-video {
  @apply mx-auto max-w-full;
}

.about-blockquote {
  padding: 2% 2% 2% 42%;
  margin: 40px 0;
  background: url(https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/v1634116706/Dominick_Sandford.png)
    #eee no-repeat bottom left;
  background-size: 150px;
  position: relative;
  overflow: hidden;
}

.about-blockquote p.quote,
.about-blockquote p.by {
  margin: 15px 0;
}

.about-blockquote p.quote {
  font-size: 14px;
  color: #222222;
  font-weight: 700;
}

.about-blockquote p.by {
  font-size: 12px;
}

.manutan-family {
  /* border-top: 2px solid #d1d1d1; */
  /* margin-top: 40px; */
  padding-top: 40px;
}

.manutan-family svg {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.manutan-family strong {
  font-size: 18px;
}

.manutan-family ul {
  width: 100%;
}

.manutan-family ul li {
  width: calc(100% / 2);
  float: left;
}

.manutan-family ul li a {
  font-size: 18px;
}

@screen md {
  .about-main {
    @apply px-15px;
  }

  .about-blockquote {
    padding: 2% 8% 2% 34%;
  }
}

@screen lg {
  .about-main {
    @apply ml-auto;
  }
}

@media (min-width: 992px) {
  .about-blockquote {
    padding: 2% 22% 2% 22%;
  }

  .manutan-family ul li {
    width: calc(100% / 4);
  }
}
