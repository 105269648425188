.notfound__wrapper {
  @apply max-w-5xl my-0 mx-auto;
}

.notfound__wrapper .notfound__main {
  @apply w-full my-20px mx-auto;
}

.notfound__wrapper .notfound__main img {
  @apply my-0 mx-auto block float-none w-180px h-auto max-w-full align-middle;
}

.notfound__wrapper .notfound__main .error-text {
  margin: 2.5%;
  width: 90%;
  @apply text-center;
}

.notfound__wrapper .notfound__main .error-text h1 {
  font-size: 300%;
  @apply uppercase mt-20px mx-0 mb-0 clear-both block font-bold;
}

.notfound__wrapper .notfound__main .error-text h2 {
  font-size: 160%;
  @apply text-error uppercase mb-20px mx-0 mt-0 clear-both inline-block font-bold;
}

.notfound__wrapper .notfound__main .error-text p {
  @apply my-20px mx-0 text-16px leading-20px;
}

.notfound__wrapper .notfound__main .error-text ul {
  @apply mt-20px text-22px;
}

.notfound__wrapper .notfound__main .error-text ul li {
  @apply my-3.25 mx-0 p-0;
}

.notfound__wrapper .notfound__main .error-text ul li button,
.notfound__wrapper .notfound__main .error-text ul li a {
  @apply text-blue-link font-bold no-underline bg-transparent;
}

.notfound__wrapper .notfound__main .error-text ul li button:hover,
.notfound__wrapper .notfound__main .error-text ul li a:hover {
  @apply text-blue-darker;
}
