@media (max-width: 768px) {
  .search-bar-hero {
    background-position: right center;
    height: auto !important;
    background: linear-gradient(90deg, rgba(38, 38, 38, 0.54) 0%, #000000 80.47%),
      url(https://cfactory-img.s3.amazonaws.com/IRM/4-1/IRM-4-1-hero-bg-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 20px;
  }
  .irm-1-5-review {
    padding: 30px 29px;
    background: #ffffff;
    border-radius: 7px;
    position: relative;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .irm-1-5-review svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .irm-1-5-review__client {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  .irm-1-5-review__client__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #e3effb;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    color: #1b1b21;
  }
  .irm-1-5-review__client__name {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }
  .irm-1-5-review__client__name span {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-top: 12px;
    color: #c1c1c1;
  }
  .irm-1-5-review__sum {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.002em;
    color: #000000;
    margin-bottom: 12px;
  }
  .irm-1-5-review__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.002em;
    color: #484848;
  }
  .cv-trustpilot {
    display: block;
    width: max-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
  }
  .cv-trustpilot span {
    text-decoration: underline;
  }
  .cv-trustpilot svg {
    margin: 0 auto;
    margin-top: 13px;
  }
}
