/* ------------------------------------------------------------ *\
	Btn Green
\* ------------------------------------------------------------ */

.btn-green {
  height: 2.813rem;
  font-size: 1rem;
  line-height: 43px;
  padding: 0 1.25rem;
  border-radius: 2.25rem;
  background: #c7d303;
  font-weight: 700;
  color: #242424;
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.btn-green:hover {
  color: #c7d303;
  border-color: #c7d303;
  background: #fff;
}

.btn-green--lg {
  height: 2rem;
  font-size: 1.125rem;
  line-height: 30px;
  border-radius: 76px;
  min-width: initial;
  width: 100%;
}

.btn-dark {
  height: 2.813rem;
  font-size: 1rem;
  line-height: 43px;
  padding: 0 1.25rem;
  border-radius: 2.25rem;
  background: #242424;
  font-weight: 700;
  color: #fff;
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.btn-dark:hover {
  color: #242424;
  border-color: #242424;
  background: #fff;
}

@screen md {
  .btn-green {
    height: 4.375rem;
    font-size: 2rem;
    line-height: 66px;
  }

  .btn-green--lg {
    height: 124px;
    font-size: 48px;
    line-height: 122px;
  }

  .btn-dark {
    font-size: 2rem;
    height: 4.375rem;
    line-height: 66px;
  }
}

@screen lg {
  .btn-green--lg {
    min-width: 917px;
  }
}

/* ------------------------------------------------------------ *\
	Hero New
\* ------------------------------------------------------------ */

.hero-light {
  padding: 2.313rem 0 2.6rem;
  text-align: center;
}

.hero-light .shell {
  padding: 0 1.25rem;
}

.hero-light .hero__inner {
  max-width: 93.75rem;
  margin: 0 auto;
}

.hero-light h1 {
  font-weight: 900;
  line-height: 1.15;
  color: #353535;
  font-size: 2.25rem;
  margin-bottom: 1.2rem;
}

.hero-light p {
  line-height: 1.54;
  color: #353535;
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

.hero-light a {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #c7d303;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s;
}

.hero-light a:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .section-orders .section__image img,
  .section-picks figure img {
    display: none;
  }

  .hero-light a svg,
  .section-orders a svg {
    width: 9px;
    height: 7px;
  }
}

@screen md {
  .hero-light {
    padding: 3.813rem 0 8.188rem;
  }

  .hero-light .shell {
    padding: 0 3.125rem;
  }

  .hero-light h1 {
    font-size: 7rem;
  }

  .hero-light p {
    margin-bottom: 5rem;
    font-size: 2rem;
  }

  .hero-light a {
    width: 3.25rem;
    height: 3.25rem;
  }
}

@screen lg {
  .hero-light h1 {
    font-size: 4.75rem;
    margin-bottom: 4.813rem;
  }

  .hero-light p {
    margin-bottom: 8.438rem;
    font-size: 2.25rem;
  }
}

/* ------------------------------------------------------------ *\
	Section Orders
\* ------------------------------------------------------------ */

.section-orders {
  padding: 2.5rem 0 2.5rem;
  background: #c7d303;
  box-shadow: inset 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
}

.section-orders .shell {
  padding: 0 1.25rem;
}

.section-orders .section__image {
  margin: 0 -4rem 0;
  text-align: center;
}

.section-orders .section__image img + img {
  display: block;
  margin: 0 auto;
}

.section-orders .section__content {
  max-width: 14.125rem;
  margin: -3rem auto 0;
  text-align: center;
  color: #fff;
}

.section-orders h2 {
  margin-bottom: 1.4rem;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.016em;
  padding-left: 46px;
}

.section-orders p {
  letter-spacing: -0.02em;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.section-orders a {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #242424;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s;
}

.section-orders a:hover {
  opacity: 0.7;
}

@screen md {
  .section-orders {
    padding: 0.063rem 0 16.375rem;
  }

  .section-orders .shell {
    padding: 0 3.125rem;
  }

  .section-orders .section__image {
    margin-top: -6.438rem;
  }

  .section-orders .section__image img + img {
    display: none;
  }

  .section-orders .section__content {
    margin: -250px auto 0;
    max-width: 61%;
  }

  .section-orders h2 {
    margin-bottom: 2.5rem;
    padding-left: 0;
  }

  .section-orders p {
    font-size: 2rem;
  }

  .section-orders a {
    width: 3.25rem;
    height: 3.25rem;
    display: none;
  }
}

@screen lg {
  .section-orders h2 {
    margin-bottom: 2.5rem;
    padding-left: 0;
  }

  .section-orders p {
    font-size: 2.25rem;
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  .section-orders h2 {
    padding-left: 0;
    font-size: 4.75rem;
    margin-bottom: 4.5rem;
  }
}

@screen desktop-lg {
  .section-orders {
    padding: 0.063rem 0 13.66vw;
  }

  .section-orders .section__image {
    margin-top: -5.6vw;
  }

  .section-orders .section__content {
    margin: -29.9vw auto 0;
  }
}

/* ------------------------------------------------------------ *\
	Section Picks
\* ------------------------------------------------------------ */

.section-picks {
  padding: 2.5rem 0 4rem;
  background: #242424;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
}

.section-picks .shell {
  padding: 0 1.25rem;
}

.section-picks figure {
  margin-bottom: 0.438rem;
}

.section-picks figure img {
  margin: 0 auto;
}

.section-picks figure img + img {
  display: block;
}

.section-picks p {
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

@screen md {
  .section-picks {
    padding: 4.875rem 0 11.75rem;
  }

  .section-picks .shell {
    padding: 0 3.125rem;
  }

  .section-picks figure {
    margin-bottom: 2rem;
  }

  .section-picks figure img + img {
    display: none;
  }

  .section-picks p {
    margin-bottom: 4.625rem;
    font-size: 2.25rem;
    line-height: 1;
  }
}

@screen lg {
  .section-picks {
    padding: 4.875rem 0 15.75rem;
  }
}

/* ------------------------------------------------------------ *\
	Section range
\* ------------------------------------------------------------ */

.section-range {
  position: relative;
  z-index: 1;
  padding: 0.063rem 0 0;
}

.section-range .shell {
  padding: 0 1.25rem;
}

.section-range .section__image {
  margin: -1.3rem -9.5rem -1.5rem;
  text-align: center;
}

.section-range .section__content {
  top: 46.5%;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  max-width: 59.063rem;
  text-align: center;
}

.section-range h2 {
  margin-bottom: 1.4rem;
  font-size: 2.588rem;
  line-height: 1;
  letter-spacing: -0.08em;
  font-weight: 900;
  color: #242424;
}

.section-range p {
  margin-bottom: 2.3rem;
  font-size: 1.125rem;
  line-height: 1;
  color: #242424;
  letter-spacing: -0.02em;
}

@screen md {
  .section-range .shell {
    padding: 0 3.125rem;
  }

  .section-range .section__image {
    margin: -4.813rem -8rem -6.06rem;
  }

  .section-range.section__content {
    top: 44.9%;
  }

  .section-range h2 {
    margin-bottom: 2.375rem;
  }

  .section-range p {
    margin-bottom: 2.5rem;
  }
}

@screen lg {
  .section-range p {
    margin-bottom: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .section-range .section__image {
    margin-top: -4.813rem;
    margin-bottom: -6.06rem;
  }

  .section-range.section__content {
    max-width: 100%;
    top: 48.9%;
  }

  .section-range h2 {
    letter-spacing: -0.02em;
    margin-bottom: 4.375rem;
    font-size: 4.75rem;
    line-height: 1.06;
  }

  .section-range p {
    margin-bottom: 3.75rem;
    font-size: 2.25rem;
  }
}

/* ------------------------------------------------------------ *\
	Section Delivery
\* ------------------------------------------------------------ */

.section-delivery {
  padding: 3rem 0 3.2rem;
  position: relative;
  background: #242424;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.section-delivery .shell {
  padding: 0 1.25rem;
}

.section-delivery .section__image {
  width: 75%;
  margin: -3rem -6rem -7rem -4rem;
  order: 1;
}

.section-delivery .section__outer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.section-delivery .section__content {
  margin: inherit;
  max-width: 82%;
}

.section-delivery .section__inner {
  max-width: 100%;
}

.section-delivery .section__actions {
  text-align: left;
}

.section-delivery .section__image img {
  width: 100%;
}

.section-delivery h2 {
  margin-bottom: 1.1rem;
  padding-right: 10px;
  font-size: 2.25rem;
  line-height: 1.15;
  font-weight: 900;
  letter-spacing: -0.02em;
}

.section-delivery p {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  line-height: 1.7;
  letter-spacing: -0.02em;
}

@screen md {
  .section-delivery {
    padding: 16.25rem 0 8.19rem;
  }

  .section-delivery .shell {
    padding: 0 3.125rem;
  }

  .section-delivery .section__image {
    margin: -16.25rem -3.125rem -17rem 0;
  }

  .section-delivery .section__content {
    max-width: 1800px;
    margin: 0 auto;
  }

  .section-delivery .section__inner {
    padding-left: 0;
  }

  .section-delivery .section__actions {
    text-align: right;
  }

  .section-delivery h2 {
    font-size: 7rem;
  }

  .section-delivery p {
    font-size: 2rem;
  }
}

@screen lg {
  .section-delivery {
    padding: 16.12rem 0 6.5rem;
  }

  .section-delivery .section__image {
    margin: -16.12rem -3.125rem -17rem 0;
  }

  .section-delivery h2 {
    margin-bottom: 4.2rem;
    font-size: 4.75rem;
    line-height: 1.06;
  }

  .section-delivery p {
    margin-bottom: 3.25rem;
    font-size: 2.25rem;
    line-height: 1;
  }
}

@screen xxl {
  .section-delivery .section__inner {
    max-width: 1013px;
    padding-left: 5.31rem;
  }
}

/* ------------------------------------------------------------ *\
	Section Spark
\* ------------------------------------------------------------ */

.section-spark {
  padding: 2.3rem 0 2.6rem;
  padding: 4.25rem 0 5rem;
  background: #c7d303;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
}

.section-spark .shell {
  padding: 0 1.25rem;
}

.section-spark h2 {
  font-size: 2.25rem;
  margin-bottom: 0;
  line-height: 1.06;
  font-weight: 900;
  letter-spacing: -0.02em;
}

.section-spark h2 + img {
  margin: 0 auto;
  margin-bottom: -0.5rem;
  margin-top: -0.7rem;
}

.section-spark img + h2 {
  margin-bottom: 1.5rem;
}

.section-spark p {
  margin-bottom: 2.3rem;
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: -0.02em;
}

@screen md {
  .section-spark {
    padding: 4.25rem 0 5rem;
  }

  .section-spark .shell {
    padding: 0 3.125rem;
  }

  .section-spark h2 {
    font-size: 9rem;
  }

  .section-spark h2 + img {
    margin-top: -3.125rem;
    margin-bottom: -1.5rem;
  }

  .section-spark img + h2 {
    margin-bottom: 2.688rem;
  }

  .section-spark p {
    font-size: 2rem;
  }
}

@screen lg {
  .section-spark h2 {
    font-size: 4.75rem;
  }

  .section-spark p {
    margin-bottom: 3.4rem;
    font-size: 2.25rem;
    line-height: 1;
  }
}

/* ------------------------------------------------------------ *\
	Section Support
\* ------------------------------------------------------------ */

.section-support {
  padding: 2.5rem 0 5.2rem;
  background: #242424;
  color: #fff;
  text-align: center;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
}

.section-support .shell {
  padding: 0 1.25rem;
}

.section-support img {
  margin: 0 auto;
  margin-bottom: 1.2rem;
}

.section-support p {
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

@screen md {
  .section-support {
    padding: 5.31rem 0 9.13rem;
  }

  .section-support .shell {
    padding: 0 3.125rem;
  }

  .section-support img {
    margin-bottom: 71px;
  }

  .section-support p {
    font-size: 2rem;
  }
}

@screen lg {
  .section-support {
    padding: 9.313rem 0 12.125rem;
  }

  .section-support p {
    margin-bottom: 4.7rem;
    font-size: 2.25rem;
    line-height: 1;
  }
}

/* ------------------------------------------------------------ *\
	Section reviews
\* ------------------------------------------------------------ */

.section-reviews {
  padding: 0.063rem 0 3.7rem;
  text-align: center;
}

.section-reviews .shell {
  padding: 0 1.25rem;
}

.section-reviews a {
  margin-top: -1.3rem;
  margin-bottom: 3.76rem;
}

.section-reviews img {
  margin-bottom: 1rem;
  max-width: 8.75rem;
  display: block;
}

.section-reviews p {
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.55;
  padding-left: 51px;
  letter-spacing: -0.02em;
}

@screen md {
  .section-reviews {
    padding: 0.063rem 0 4.81rem;
  }

  .section-reviews .shell {
    padding: 0 3.125rem;
  }

  .section-reviews a {
    margin-bottom: 7.313rem;
    margin-top: -3.438rem;
  }

  .section-reviews img {
    margin: 0 auto 4.375rem;
  }

  .section-reviews p {
    font-size: 2rem;
  }
}

@screen lg {
  .section-reviews {
    padding: 0.063rem 0 7.813rem;
  }

  .section-reviews p {
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .section-reviews p {
    font-size: 2.25rem;
    line-height: 1;
    margin-bottom: 4.7rem;
  }
}
