.home-callout {
  padding: 1.875rem 0;
  @apply relative overflow-hidden text-white z-1 bg-blackBG;
}

.home-callout::before {
  content: "";
  background: linear-gradient(45deg,#1d1d1d,rgba(29,29,29,.99) 20%,rgba(29,29,29,.52));
  @apply absolute top-0 left-0 w-full h-full pointer-events-none;
}

.home-callout .home-callout__background {
  transform: translateY(-50%);
  background-position: 50% 50%;
  @apply absolute -z-1 top-1/2 right-0 w-full h-full bg-cover bg-no-repeat;
}

.home-callout .home-callout__inner .home-callout__content {
  z-index: 3;
  @apply relative max-w-none;
}

.home-callout .home-callout__inner .home-callout__content .home-callout__content-inner p {
  @apply text-16px leading-5 mb-2.5;
}

.theme-id .home-callout .home-callout__inner .home-callout__content .home-callout__content-inner h2 span {
  @apply text-orange;
}

.theme-ed .home-callout .home-callout__inner .home-callout__content .home-callout__content-inner h2 span {
  @apply text-green;
}

.home-callout .home-callout__inner .home-callout__content .home-callout__actions {
  @apply mt-5;
}

@screen sm {
  .home-callout {
    padding: 2.75rem 0 2.8125rem;
  }

  .home-callout::before {
    background: linear-gradient(90deg,#222323 45%,rgba(31,31,31,0));
  }

  .home-callout .home-callout__background {
    width: 47.9375rem;
  }

  .home-callout .home-callout__inner .home-callout__content .home-callout__actions {
    margin-top: 1.625rem;
  }
}

@screen md {
  .home-callout {
    padding: 2.4375rem 0 2.5rem;
  }

  .home-callout .home-callout__background {
    width: 90%;
  }

  .home-callout .home-callout__inner .home-callout__content {
    max-width: 56%
  }

  .home-callout .home-callout__inner .home-callout__content .home-callout__content-inner p {
    @apply mb-3.5;
  }

  .home-callout .home-callout__inner .home-callout__content .home-callout__actions {
    @apply mt-6;
  }
}

@screen lg {
  .home-callout .home-callout__background {
    width: 60%;
  }
}

@screen xl {
  .home-callout {
    padding: 4.8125rem 0 4.6875rem;
  }

  .home-callout .home-callout__background {
    width: 70%;
  }

  .home-callout .home-callout__inner .home-callout__content .home-callout__actions {
    @apply mt-7/50;
  }
}

@screen xxl {
  .home-callout .home-callout__background {
    width: 58%;
  }
}