.product-badge {
  opacity: 0.9;
  z-index: 11;
  border-radius: 10px 0 10px 0;
  @apply flex items-center justify-center font-bold py-2 px-4 text-14px;
}

.product-badge--recommended {
  @apply text-black-lighter font-bold;
}

.product-badge--recommended-id {
  background-color: #f3c586;
}

.product-badge--recommended-ed {
  background-color: #ebf286;
}

.product-badge__text--recommended {
  margin-left: 3px;
}

.product-badge-bold {
  @apply font-semibold;
}

.upper-badge-container--single .product-badge {
  border-radius: 10px 0 10px 0;
}

.upper-badge-container--multiple .product-badge:first-child {
  border-radius: 10px 0 0 0;
}

.upper-badge-container--multiple .product-badge:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.upper-badge-container--multiple .product-badge:last-child {
  border-radius: 0 0 10px 0;
}

@screen md {
  .product-badge {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  .product-badge {
    @apply text-16px;
  }
}

.irm-1-7-recommended__item .irm-1-7__hide {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}
.irm-1-7-recommended__item .product-badge--recommended {
  cursor: pointer;
}
.irm-1-7
  .irm-1-7-recommended__item
  .rounded-br-xl
  .irm-1-7-recommended__item-badge
  + .font-bold.px-3.py-1 {
  border-bottom-right-radius: 0.75rem !important;
}
.irm-1-7-recommended__item-badge {
  position: relative;
  border-top-left-radius: 0.4rem !important;
  border-bottom-right-radius: 0.75rem !important;
}
.irm-1-7-recommended__item-badge.radius-none {
  border-bottom-right-radius: 0 !important;
}
.irm-1-7-recommended__item-badge__info {
  position: absolute;
  top: calc(100% + 18px);
  left: 20px;
  width: 375px;
  padding: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  line-height: 24px;
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.irm-1-7-recommended__item-badge__info > svg {
  position: absolute;
  top: -18px;
  left: 25px;
  width: 24px;
  height: 24px;
}
.irm-1-7-recommended__item-badge__info::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background: transparent;
}
.irm-1-7-recommended__item-badge.right-sided .irm-1-7-recommended__item-badge__info {
  left: inherit;
  right: 20px;
}
.irm-1-7-recommended__item-badge.right-sided .irm-1-7-recommended__item-badge__info svg {
  left: inherit;
  right: 25px;
}
.irm-1-7-recommended__item-badge:hover .irm-1-7-recommended__item-badge__info {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
@media only screen and (max-width: 1280px) {
  .irm-1-7-recommended__item-badge__info {
    display: none !important;
  }
}
.irm-1-7-recommended__item a[href^='/product/'] img {
  width: calc(90% - 8px);
  margin: 0 auto;
}
.irm-1-7-recommended__item .irm-1-7__hide {
  opacity: 0;
  visibility: hidden;
}
