.info-del-banner {
  background-color: #262827;
  @apply mb-6 relative text-center rounded-5px p-20px w-full;
}

.info-del-banner .banner__container {
  @apply w-full px-15px mx-auto;
}

.info-del-banner .banner__container .banner__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.info-del-banner .banner__container .banner__inner .banner-icon__wrapper {
  @apply relative w-full px-15px flex-full max-w-full;
}

.info-del-banner .banner__container .banner__inner .banner-icon__wrapper svg {
  @apply fill-current text-white w-full inline-block h-20;
}

.info-del-banner .banner__container .banner__inner .banner-head__wrapper {
  @apply flex-full max-w-full;
}

.info-del-banner .banner__container .banner__inner .banner-head__wrapper h2 {
  @apply font-bold mb-10px uppercase text-white text-14px text-center;
}

.info-del-table {
  @apply overflow-hidden rounded-5px mb-12;
}

.info-del-table .table__head {
  @apply h-20 bg-blackBG text-center flex items-center justify-center text-12px font-normal text-white;
}

.info-del-table .table__head .head__wrapper {
  @apply w-full px-15px mx-auto;
}

.info-del-table .table__head .head__wrapper .head__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.info-del-table .table__head .head__wrapper .head__inner .head__column {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  @apply relative w-full px-15px;
}

.info-del-table .table__head .head__wrapper .head__inner .head__column strong {
  @apply block text-14px text-white font-bold;
}

.info-del-table .table__body {
  @apply text-center bg-concrete py-10px;
}

.info-del-table .table__body .body__wrapper {
  @apply w-full px-15px mx-auto;
}

.info-del-table .table__body .body__wrapper .body__inner {
  @apply flex items-center flex-wrap -mx-15px;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main {
  @apply flex-full max-w-full relative w-full px-0;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main table {
  border-spacing: 0;
  @apply w-full border-collapse;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr {
  @apply h-20;
}

.info-del-table
  .table__body
  .body__wrapper
  .body__inner
  .body__main
  table
  tbody
  tr:not(:last-child) {
  border-bottom: 1px solid #d3d3d7;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
  @apply text-center text-10px w-third;
}

.info-del-table
  .table__body
  .body__wrapper
  .body__inner
  .body__main
  table
  tbody
  tr
  td:not(:last-child) {
  border-right: 1px solid #d3d3d7;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td strong {
  @apply block font-extrabold text-10px;
}

.info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
  @apply text-10px block leading-none mb-1;
}

.info-del-content {
  @apply mb-12;
}

.info-del-content p {
  @apply font-normal text-14px mb-20px leading-5;
}

.info-del-content p a {
  @apply text-blue-link font-bold underline;
}

.info-del-content p a:hover {
  @apply text-blue-darker;
}

.info-del-content h4 {
  @apply mx-0 mt-40px mb-10px text-18px leading-6;
}

@screen md {
  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
    @apply text-12px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td strong {
    @apply text-12px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
    @apply text-12px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main {
    @apply px-15;
  }
}

@screen lg {
  .info-del-banner {
    @apply py-40px px-20px;
  }

  .info-del-banner .banner__container .banner__inner .banner-head__wrapper h2 {
    @apply text-46px text-left;
  }

  .info-del-banner .banner__container .banner__inner .banner-icon__wrapper {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .info-del-banner .banner__container .banner__inner .banner-head__wrapper {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .info-del-table .table__head .head__wrapper .head__inner .head__column strong {
    @apply text-18px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td {
    @apply text-14px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td strong {
    @apply text-14px;
  }

  .info-del-table .table__body .body__wrapper .body__inner .body__main table tbody tr td span {
    @apply text-14px;
  }
}
