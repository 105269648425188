.m--breadcrumbs {
  @apply pt-8 w-full flex items-center mb-20px;
}

.m--breadcrumb-item {
  @apply inline-flex items-center m-0 text-16px text-black-lighter capitalize;
}

.m--breadcrumb-item:hover {
  @apply no-underline;
}

.m--breadcrumb-item--pdp {
  @apply text-black-lighter hidden;
}

.m--breadcrumb-item--pdp:hover {
  @apply text-black-lighter;
}

.m--breadcrumb-item--pdp:last-child {
  @apply flex font-bold;
}

.m--breadcrumb-item--pdp:last-child::before {
  @apply border-0 border-none border-transparent h-4.5 w-3 mx-15px;
}

.m--breadcrumb-item--pdp:not(.m--breadcrumb-last):not(.m--breadcrumb-last-pdp):not(:nth-last-child(2)):after {
  content: url('/svgs/ico-arrow-left.svg');
  @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px flex items-center;
}

.m--breadcrumb-item--firerated::after {
  content: url('/svgs/ico-arrow-left-white.svg') !important;
  @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px flex items-center;
}

.m--breadcrumb-item--pdp--id:last-child::before {
  content: url('/svgs/ico-arrow-left.svg');
}

.m--breadcrumb-item--pdp--id:nth-last-child(2)::after {
  content: url('/svgs/ico-arrow-left-id.svg');
}

.m--breadcrumb-item--pdp--ed:last-child::before {
  content: url('/svgs/ico-arrow-left.svg');
}

.m--breadcrumb-item--pdp--ed:nth-last-child(2)::after {
  content: url('/svgs/ico-arrow-left-ed.svg');
}

.m--breadcrumb-item--plp {
  @apply text-white hidden;
}

.m--breadcrumb-item--plp:hover {
  @apply text-white;
}

.m--breadcrumb-item--plp:last-child {
  @apply flex font-bold;
}

.m--breadcrumb-item--plp:last-child::before {
  @apply border-none border-0 border-transparent h-0 w-0 m-0;
}

.m--breadcrumb-item--plp:not(.m--breadcrumb-last):not(.m--breadcrumb-last-plp):not(:nth-last-child(2)):after {
  content: url('https://res.cloudinary.com/manutantraders/image/upload/v1625647005/Icons/ico-arrow-left_white.svg');
  @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px;
  /* @apply border-none border-0 border-transparent h-0 w-0 m-0; */
}

.m--breadcrumb-item--plp--id:last-child::before {
  content: url('https://res.cloudinary.com/manutantraders/image/upload/v1625647005/Icons/ico-arrow-left_white.svg');
  @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px;
}

.m--breadcrumb-item--plp--id:nth-last-child(2)::after {
  content: url('/svgs/ico-arrow-left-id.svg');
}

.m--breadcrumb-item--plp--ed:last-child::before {
  content: url('https://res.cloudinary.com/manutantraders/image/upload/v1625647005/Icons/ico-arrow-left_white.svg');
}

.m--breadcrumb-item--plp--ed:nth-last-child(2)::after {
  content: url('/svgs/ico-arrow-left-ed.svg');
}

.m--breadcrumb-item.m--breadcrumb-last {
  @apply text-black-lighter font-bold;
}

.m--breadcrumb-item.m--breadcrumb-last-plp {
  @apply text-white pointer-events-none;
}

.m--mobile-breadcrumb-item {
  color: #222222;
  @apply inline-flex items-center m-0 text-16px capitalize;
}

.m--mobile-breadcrumb-item:hover {
  color: #222222;
  @apply no-underline;
}

.m--mobile-breadcrumb-item::before {
  content: '';
  border-width: 3.5px 5px 3.5px 0px;
  margin: 3px 10px 0 0;
  @apply h-0 w-0 border-solid hidden;
}

.m--mobile-breadcrumb-item--plp {
  @apply text-white;
}

@screen md {
  .m--breadcrumb-item--plp:last-child,
  .m--breadcrumb-item--plp:nth-last-child(2) {
    @apply flex;
  }

  .m--breadcrumb-item--plp:last-child::before,
  .m--breadcrumb-item--plp:nth-last-child(2)::before {
    @apply border-none border-0 border-transparent h-0 w-0 m-0;
  }

  .m--breadcrumb-item--plp:nth-last-child(2)::after {
    @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px;
  }

  .m--breadcrumb-item--plp--id:last-child::before {
    content: url('/svgs/ico-arrow-left-id.svg');
  }

  .m--breadcrumb-item--plp--ed:last-child::before {
    content: url('/svgs/ico-arrow-left-ed.svg');
  }

  .theme-id .m--breadcrumb-item.m--breadcrumb-last {
    @apply text-orange;
  }

  .theme-ed .m--breadcrumb-item.m--breadcrumb-last {
    @apply text-green;
  }

  .m--breadcrumb-item--pdp:last-child,
  .m--breadcrumb-item--pdp:nth-last-child(2) {
    @apply flex;
  }

  .m--breadcrumb-item--pdp:last-child::before,
  .m--breadcrumb-item--pdp:nth-last-child(2)::before {
    @apply border-none border-0 border-transparent h-0 w-0 m-0;
  }

  .m--breadcrumb-item--pdp:nth-last-child(2)::after {
    @apply border-none border-0 border-transparent h-4.5 w-3 mx-15px flex items-center;
  }

  .m--breadcrumb-item--pdp--id:last-child::before {
    content: '';
  }

  .m--breadcrumb-item--pdp--ed:last-child::before {
    content: '';
  }

  .m--breadcrumb-item--plp--id:last-child::before {
    content: '';
  }

  .m--breadcrumb-item--plp--ed:last-child::before {
    content: '';
  }
}

@screen lg {
  .m--breadcrumbs {
    @apply mb-30px pt-16;
  }

  .m--mobile-breadcrumb-item::before {
    @apply inline-flex;
  }
}

@screen xl {
  .m--breadcrumb-item--pdp {
    @apply flex;
  }

  .m--breadcrumb-item--plp {
    @apply flex;
  }
}
