.toast-custom__paragraph {
  @apply text-14px text-black-lighter mb-0;
}

.toast-custom--600 {
  @apply font-semibold;
}

.toast-custom--white {
  @apply text-white;
}

@screen lg {
  .toast-custom {
    @apply text-16px;
  }
}

.toast-custom--success {
  @apply text-black-lighter;
}

.toast-custom--error,
.toast-custom--info,
.toast-custom--warning {
  @apply text-white;
}

.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .Toastify__toast {
    @apply rounded-none;
  }
}

.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  @apply bg-black-lighter;
}
.theme-id .Toastify__toast--success {
    background: #eea137;
}
.theme-ed .Toastify__toast--success {
    background: #c7d303;
}
.theme-id .Toastify__toast--warning {
    background: #f3c586;
}
.theme-ed .Toastify__toast--warning {
    background: #ebf286;
}
.Toastify__toast--error {
  background: #cc4646;
}
.Toastify__toast--info {
  background: #242424;
}
.Toastify__toast-body {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

.Toastify__close-button--success {
  opacity: 1;
  color: #242424;
}

.Toastify__close-button--error,
.Toastify__close-button--info,
.Toastify__close-button--warning {
  opacity: 1;
  color: #ffffff;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}

.Toastify__toast-container {
}

.Toastify__progress-bar--success,
.Toastify__progress-bar--warning {
  background-color: #242424;
}

.Toastify__progress-bar--error {
  background-color: #ffffff;
}

.theme-id .Toastify__progress-bar--info {
  background: #eea137;
}
.theme-ed .Toastify__progress-bar--info {
    background: #c7d303;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
@media screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-center {
    bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .Toastify__toast-container--bottom-center {
    bottom: 2em;
  }
}